import cx from 'classnames';
import RBModal from 'react-bootstrap/Modal';
import bn from 'utils/bemNames';

const bem = bn('modal');

const Modal = ({
  custemIcon = '',
  showDialog,
  desc = '',
  children,
  showHeader = true,
  className,
  icon,
  status = '',
  title = '',
  activeID = false,
  useHTMLString = false,
  ...props
}) => {
  const getStatus = (status) => {
    switch (status) {
      case 'denger':
        return 'red';
      case 'warning':
        return 'yellow';
      default:
        return 'blue';
    }
  };

  const createMarkup = () => {
    return { __html: title }
  }

  return (
    <RBModal show={showDialog} className={cx(bem.b(), bem.e(className))} centered {...props}>
      {showHeader && (
        <RBModal.Header>
          <span className="header-icon">{icon && icon}</span>
          {useHTMLString ? (
            <span className="header-title" dangerouslySetInnerHTML={createMarkup()}></span>
          ) : (
            title
          )}
          {/* <RBModal.Title className='title-header'><span className='sub-title' style={{color: getStatus(status)}}>Approve</span> this request with ID <span className='sub-title' style={{color: activeID && getStatus(status)}}>PPM-AGS-322-420?</span> </RBModal.Title> */}
        </RBModal.Header>
      )}
      <RBModal.Body>{children}</RBModal.Body>
    </RBModal>
  );
};
export default Modal;
