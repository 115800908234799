import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { getUsername, sortActivity } from 'utils/helper';
import { post, get, deleteRequest, apiUrls, put } from 'utils/request';

// initial state
export const initialState = {
  tripList: {
    records: [],
    loading: false,
  },
  jettyList: {
    loading: false,
    records: [],
    recordsTotal: 0,
  },
  jettyDetail: {
    id: null,
    date: "",
    shift: "",
    activityList: [],
    tug: "",
    barge: "",
    tripId: "",
  },
  attendances: {
    loading: false,
    records: [],
    error: "",
  },
  listAttendances: {
    loading: false,
    records: [],
    error: "",
  },
};

// fetch functions
export const getTripList = createAsyncThunk(
  'actual-port-jetty/trip-list',
  async () => {
    const response = await get(`${apiUrls.actualPort}trip/current-activity/ready-for-loading`);

    return response?.data;
  }
);

export const fetchActualPortJetty = createAsyncThunk(
  'actual-port-jetty/filter',
  async (filter) => {

    const options = {
      columns: [
        {
          data: 'id',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: filter.id,
          searchable: true,
        },
        {
          data: 'date',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: filter.filterDate,
          searchable: true,
        },
        {
          data: 'shift',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: filter.filterShift,
          searchable: true,
        },
        {
          data: 'loadingPosition',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: 'tug',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: 'barge',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: 'type',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: "",
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: filter.query || "",
      },
      draw: 1,
      length: -1,
      order: [],
    }

    const response = await post(`${apiUrls.actualPort}admin/loading-jetty/filter`, options)
    return response.data;
  }
);

export const updateAdminJetty = createAsyncThunk(
  'actual-port-jetty/update-jetty',
  async (params) => {
    try {
      const response = await post(`${apiUrls.actualPort}admin/loading-jetty?userName=${getUsername()}`, params);

      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const deleteAdminJetty = createAsyncThunk(
  'actual-port-jetty/delete-jetty',
  async (params) => {
    const response = await deleteRequest(`${apiUrls.actualPort}admin/loading-jetty/${params.id}?userName=${params.userName}`);

    return response?.data;
  }
);

export const fetchDetailOperatorJetty = createAsyncThunk(
  'actual-port-jetty/attendance/detail-operator',
  async () => {
    const response = await get(`${apiUrls.attendances}/detail-operator/LoadingJetty`);

    return response?.data;
  }
);

export const saveAttendance = createAsyncThunk(
  'actual-port-jetty/attendance/list-attendance',
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.attendances}/attendance/save?userName=${params.userName}`, params.options);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const getJettyById = createAsyncThunk(
  'actual-port-jetty/get-by-id',
  async (params) => {
    const { id } = params

    const response = await get(`${apiUrls.actualPort}admin/loading-jetty/${id}`);

    return response?.data;
  }
);

export const addJettyActivity = createAsyncThunk(
  'actual-port-jetty/activity/add',
  async ({ data, username, id }) => {
    let response

    try {
      response = await post(`${apiUrls.actualPort}/admin/loading-jetty/${id}/activity?userName=${username}`, data);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const saveJettyActivity = createAsyncThunk(
  'actual-port-jetty/activity/save',
  async ({ data, username, id }) => {
    let response

    try {
      response = await put(`${apiUrls.actualPort}/admin/loading-jetty/activity/${id}?userName=${username}`, data);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const submitJetty = createAsyncThunk(
  'actual-port-jetty/activity/submit',
  async ({ id, username, submitTime }) => {
    let response

    try {
      response = await post(`${apiUrls.actualPort}/admin/loading-jetty/${id}/submit?userName=${username}&submitTime=${submitTime}`);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const deleteImageJettyActivity = createAsyncThunk(
  'actual-port-jetty/activity/delete-image',
  async ({ id, username, fileName }) => {
    let response

    try {
      response = await deleteRequest(`${apiUrls.actualPort}/admin/loading-jetty/activity/${id}/files/${fileName}?userName=${username}`);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
)

export const fetchListAttendance = createAsyncThunk(
  'actual-port-jetty/attendance/list-attendance',
  async (option) => {
    const response = await post(`${apiUrls.attendances}/list-attendance`, option);

    return response?.data;
  }
);

// slicing
const actualAdminJetty = createSlice({
  name: 'ACTUAL_ADMIN_JETTY',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getTripList.pending, (state, action) => {
      state.tripList.loading = true;
    });
    builder.addCase(getTripList.fulfilled, (state, action) => {
      state.tripList.loading = false;
      state.tripList.records = action.payload;
    });
    builder.addCase(getTripList.rejected, (state, action) => {
      state.tripList.loading = false;
    });

    builder.addCase(fetchActualPortJetty.rejected, (state) => {
      state.jettyList.loading = false;
    });
    builder.addCase(fetchActualPortJetty.fulfilled, (state, action) => {
      state.jettyList.loading = false;
      state.jettyList.records = action.payload?.list;
      state.jettyList.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(fetchActualPortJetty.pending, (state) => {
      state.jettyList.loading = true;
    });

    builder.addCase(fetchDetailOperatorJetty.pending, (state, action) => {
      state.attendances.loading = true;
    });
    builder.addCase(fetchDetailOperatorJetty.fulfilled, (state, action) => {
      const payload = action.payload.filter(e => e.entityStatus === 1)
      const payloadFilter = payload.sort((a, b) => a['name'].toLowerCase() < b['name'].toLowerCase() ? -1 : 1)

      state.attendances.records = payloadFilter;
      state.attendances.loading = false;
    });
    builder.addCase(fetchDetailOperatorJetty.rejected, (state, action) => {
      state.attendances.loading = false;
      state.attendances.error = 'Invalid get data';
    });

    builder.addCase(getJettyById.fulfilled, (state, action) => {
      let activityList = sortActivity(action.payload, true);
      for (var i = 0; i < activityList.length; i++) {
        if (i + 1 != activityList.length) {
          activityList[i].endTime =
            activityList[i + 1].startTime;
        } else {
          activityList[i].endTime = moment().format("HH:mm")
        }
      }

      state.jettyDetail = {
        ...action.payload,
        activityList: activityList,
      };
    });
  },
});

export const tripSelector = (state) => state.actualAdminJetty.tripList;
export const jettyDetailSelector = (state) => state.actualAdminJetty.jettyDetail;
export const jettyListSelector = (state) => state.actualAdminJetty.jettyList;
export const jettyAttendanceSelector = (state) => state.actualAdminJetty.attendances;

export default actualAdminJetty.reducer;
