import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { get, post, apiUrls } from 'utils/request';
import { sum, cloneDeep, sumBy, round } from 'lodash';
import { DATASOURCE_VALUE } from 'utils/helper';

export const initialState = {
  midTermPlanning: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  midTermPlanningDetail: {
    loading: false,
    records: [],
    error: '',
    version: 1,
    data: {
      dataInpit: [
        { wasteInpit: 'Soil', unit: 'bcm' },
        { wasteInpit: 'Rehandle', unit: 'bcm' },
        { wasteInpit: 'OB Removal', unit: 'bcm' },
        { wasteInpit: 'OB Haul Distance', unit: 'm' },
      ],
      dataOutpit: [
        { wasteOutpit: 'Soil', unit: 'bcm' },
        { wasteOutpit: 'Rehandle', unit: 'bcm' },
        { wasteOutpit: 'OB Removal', unit: 'bcm' },
        { wasteOutpit: 'OB Haul Distance', unit: 'm' },
      ],
      dataWasteCoal: [{ wasteCoal: 'Coal Getting', unit: '' }],
      dataWasteCoalExpose: [{ wasteCoalExpose: 'Coal Expose', unit: '' }],
      dataWasteCoalHaul: [
        { wasteCoalHaul: 'Coal Haul Distance (PIT to ROM)', unit: 'm' },
      ],
      dataWasteQuality: [
        { wasteQuality: 'ASH', unit: '%' },
        { wasteQuality: 'CV_ADB', unit: 'kcal/kg' },
        { wasteQuality: 'CV_GAR', unit: 'kcal/kg' },
        { wasteQuality: 'FC', unit: '%' },
        { wasteQuality: 'IM', unit: '%' },
        { wasteQuality: 'RD_IS', unit: 'ton/bcm' },
        { wasteQuality: 'TM', unit: '%' },
        { wasteQuality: 'TS', unit: '%' },
        { wasteQuality: 'VM', unit: '%' },
      ],
      longTermPlanning: {
        dataInpit: [
          { wasteInpit: 'Soil', unit: 'bcm' },
          { wasteInpit: 'Rehandle', unit: 'bcm' },
          { wasteInpit: 'OB Removal', unit: 'bcm' },
          { wasteInpit: 'OB Haul Distance', unit: 'm' },
        ],
        dataOutpit: [
          { wasteOutpit: 'Soil', unit: 'bcm' },
          { wasteOutpit: 'Rehandle', unit: 'bcm' },
          { wasteOutpit: 'OB Removal', unit: 'bcm' },
          { wasteOutpit: 'OB Haul Distance', unit: 'm' },
        ],
        dataWasteCoal: [{ wasteCoal: 'Coal Getting', unit: '' }],
        dataWasteCoalExpose: [{ wasteCoalExpose: 'Coal Expose', unit: '' }],
        dataWasteCoalHaul: [
          { wasteCoalHaul: 'Coal Haul Distance (PIT to ROM)', unit: 'm' },
        ],
        dataWasteQuality: [
          { wasteQuality: 'ASH', unit: '%' },
          { wasteQuality: 'CV_ADB', unit: 'kcal/kg' },
          { wasteQuality: 'CV_GAR', unit: 'kcal/kg' },
          { wasteQuality: 'FC', unit: '%' },
          { wasteQuality: 'IM', unit: '%' },
          { wasteQuality: 'RD_IS', unit: 'ton/bcm' },
          { wasteQuality: 'TM', unit: '%' },
          { wasteQuality: 'TS', unit: '%' },
          { wasteQuality: 'VM', unit: '%' },
        ],
      },
    },
  },
  midTermPlanningDetailColumn: {
    dataInpit: [],
    dataOutpit: [],
    dataWasteCoal: [],
    dataWasteCoalExpose: [],
    dataWasteCoalHaul: [],
    dataWasteQuality: [],
  },
  versionList: {
    loading: false,
    versionList: [1],
    error: '',
  },
  currentObject: {},
};

export const getmidTermPlanning = createAsyncThunk(
  'midTermPlanning/get',
  async (params) => {
    const response = await post(`${apiUrls.midTermPlannings}/filter`, {
      columns: [
        {
          data: 'reportId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.reportId || '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.date || '',
          searchable: true,
        },
        {
          data: 'year',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.year || '',
          searchable: true,
        },
        {
          data: 'version',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.version || '',
          searchable: true,
        },
        {
          data: 'contractor',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.contractor || '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params.status === DATASOURCE_VALUE.waitingApproval || false,
            value: '',
          },
          searchValue: params.status || '',
          searchable: true,
        },
      ],
      order: [],
      search: {
        regex: true,
        value: params.query || '',
      },
      draw: params?.pageNumber ? params.pageNumber : 1,
      length: params?.pageSize ? params.pageSize : 50,
    });
    return response?.data;
  }
);

export const getmidTermPlanningDetail = createAsyncThunk(
  'midTermPlanning/detail',
  async (params) => {
    const response = await get(`${apiUrls.midTermPlannings}/${params}`);
    return response?.data;
  }
);

export const getVersionList = createAsyncThunk(
  'midTermPlanning/versionlist',
  async (params) => {
    const response = await get(
      `${apiUrls.midTermPlannings}/${params}/versions`
    );
    return response?.data;
  }
);

export const getByVersion = createAsyncThunk(
  'midTermPlanning/getByVersion',
  async (params) => {
    const response = await get(
      `${apiUrls.midTermPlannings}/${params.reportId}/versions/${params.version}`
    );
    return response?.data;
  }
);

export const intervensiMidTermPlanning = createAsyncThunk(
  'midTermPlanning/intervensi',
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.intervensiMinePlan}/user-role?moduleName=MidTermPlan`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const MID = 'MID';

const midTermPlanning = createSlice({
  name: MID,
  initialState,
  reducers: {
    clearmidTermPlanningDetail: (state, action) => {
      state.midTermPlanningDetail = {
        loading: false,
        records: [],
        error: '',
        data: {
          dataInpit: [
            { wasteInpit: 'Soil', unit: 'bcm' },
            { wasteInpit: 'Rehandle', unit: 'bcm' },
            { wasteInpit: 'OB Removal', unit: 'bcm' },
            { wasteInpit: 'OB Haul Distance', unit: 'm' },
          ],
          dataOutpit: [
            { wasteOutpit: 'Soil', unit: 'bcm' },
            { wasteOutpit: 'Rehandle', unit: 'bcm' },
            { wasteOutpit: 'OB Removal', unit: 'bcm' },
            { wasteOutpit: 'OB Haul Distance', unit: 'm' },
          ],
          dataWasteCoal: [{ wasteCoal: 'Coal Getting', unit: '' }],
          dataWasteCoalExpose: [{ wasteCoalExpose: 'Coal Expose', unit: '' }],
          dataWasteCoalHaul: [
            { wasteCoalHaul: 'Coal Haul Distance (PIT to ROM)', unit: 'm' },
          ],
          dataWasteQuality: [
            { wasteQuality: 'ASH', unit: '%' },
            { wasteQuality: 'CV_ADB', unit: 'kcal/kg' },
            { wasteQuality: 'CV_GAR', unit: 'kcal/kg' },
            { wasteQuality: 'FC', unit: '%' },
            { wasteQuality: 'IM', unit: '%' },
            { wasteQuality: 'RD_IS', unit: 'ton/bcm' },
            { wasteQuality: 'TM', unit: '%' },
            { wasteQuality: 'TS', unit: '%' },
            { wasteQuality: 'VM', unit: '%' },
          ],
          longTermPlanning: {
            dataInpit: [
              { wasteInpit: 'Soil', unit: 'bcm' },
              { wasteInpit: 'Rehandle', unit: 'bcm' },
              { wasteInpit: 'OB Removal', unit: 'bcm' },
              { wasteInpit: 'OB Haul Distance', unit: 'm' },
            ],
            dataOutpit: [
              { wasteOutpit: 'Soil', unit: 'bcm' },
              { wasteOutpit: 'Rehandle', unit: 'bcm' },
              { wasteOutpit: 'OB Removal', unit: 'bcm' },
              { wasteOutpit: 'OB Haul Distance', unit: 'm' },
            ],
            dataWasteCoal: [{ wasteCoal: 'Coal Getting', unit: '' }],
            dataWasteCoalExpose: [{ wasteCoalExpose: 'Coal Expose', unit: '' }],
            dataWasteCoalHaul: [
              { wasteCoalHaul: 'Coal Haul Distance (PIT to ROM)', unit: 'm' },
            ],
            dataWasteQuality: [
              { wasteQuality: 'ASH', unit: '%' },
              { wasteQuality: 'CV_ADB', unit: 'kcal/kg' },
              { wasteQuality: 'CV_GAR', unit: 'kcal/kg' },
              { wasteQuality: 'FC', unit: '%' },
              { wasteQuality: 'IM', unit: '%' },
              { wasteQuality: 'RD_IS', unit: 'ton/bcm' },
              { wasteQuality: 'TM', unit: '%' },
              { wasteQuality: 'TS', unit: '%' },
              { wasteQuality: 'VM', unit: '%' },
            ],
          },
        },
      };
    },
    onChangeTableWasteInpit: (state, action) => {
      const payload = action.payload;
      let val = parseInt(payload.value, 10);
      // allow to sent decimal
      if (payload.value?.includes('.'))
        val = payload.value.split('.').join(',');
      state.midTermPlanningDetail.data[payload.tableType][
        payload.tableIndexRow
      ][payload.tableRowName] = val;
    },
    createColumnmidPlanningDetail: (state, action) => {
      const payload = action.payload;
      state.midTermPlanningDetailColumn[payload.tableName] = payload.data;
    },
    onChangeCounterTableHeader: (state, action) => {
      const payload = action.payload;

      let counterHeader = 0,
        year1 = 0,
        year2 = 0,
        year3 = 0,
        year4 = 0;

      const dataTableDetail = current(
        state.midTermPlanningDetail.data[payload.tableType]
      );
      /**
       * Todo: mapping data detail for create counter   header
       */
      dataTableDetail.map((data, index) => {
        if (data[payload.tableRowName]) {
          counterHeader +=
            data.unit !== 'm' ? parseInt(data[payload.tableRowName]) : 0;
        }

        // blocks of possible dead code
        if (index === 0) {
          const cloneData = cloneDeep(data);
          delete cloneData['year1'];
          delete cloneData['year2'];
          delete cloneData['year3'];
          const getDataSoil = Object.values(cloneData);
          const onlyNumbers = getDataSoil.filter(
            (element) => typeof element === 'number'
          );

          year1 = sum(onlyNumbers);
        }

        if (index === 1) {
          const cloneData = cloneDeep(data);
          delete cloneData['year1'];
          delete cloneData['year2'];
          delete cloneData['year3'];
          const getDataSoil = Object.values(cloneData);
          const onlyNumbers = getDataSoil.filter(
            (element) => typeof element === 'number'
          );
          year2 = sum(onlyNumbers);
        }

        if (index === 2) {
          const cloneData = cloneDeep(data);
          delete cloneData['year1'];
          delete cloneData['year2'];
          delete cloneData['year3'];
          const getDataSoil = Object.values(cloneData);
          const onlyNumbers = getDataSoil.filter(
            (element) => typeof element === 'number'
          );
          year3 = sum(onlyNumbers);
        }

        if (index === 3) {
          const cloneData = cloneDeep(data);
          delete cloneData['year1'];
          delete cloneData['year2'];
          delete cloneData['year3'];
          const getDataSoil = Object.values(cloneData);
          const onlyNumbers = getDataSoil.filter(
            (element) => typeof element === 'number'
          );
          year4 = sum(onlyNumbers);
        }
        // end blocks of possible dead code
      });

      /**
       * Todo: get detail table for column
       */
      const dataTableColumn = current(
        state.midTermPlanningDetailColumn[payload.tableType]
      );

      /**
       * Todo: get index table detail column
       */
      const findIndex = dataTableColumn.findIndex(
        (x) => x.accessor == payload.tableRowName
      );
      const findIndexYear1 = dataTableColumn.findIndex(
        (x) => x.accessor == 'year1'
      );
      /**
       * Todo: parsing counter data header table column
       */
      const isQualityTable = payload.tableType === 'dataWasteQuality';
      const isInputYear = payload.tableRowName.includes('year');
      const isCoalTable = payload.tableType.includes('Coal');

      // possible deadcode
      if (!isQualityTable)
        state.midTermPlanningDetailColumn[payload.tableType][findIndex][
          'Header'
        ] = counterHeader;

      const { data: detailData } = current(state.midTermPlanningDetail);
      const {
        dataInpit: wasteRemovalInpitColumns,
        dataOutpit: wasteRemovalOutpitColumns,
      } = current(state.midTermPlanningDetailColumn);

      const sumWasteRemovalInpit = sum(
        wasteRemovalInpitColumns
          .filter((i) => i.accessor.includes('semester'))
          .reduce((a, b) => a.concat(b.Header ?? 0), [])
      );
      const sumWasteRemovalOutpit = sum(
        wasteRemovalOutpitColumns
          .filter((i) => i.accessor.includes('semester'))
          .reduce((a, b) => a.concat(b.Header ?? 0), [])
      );
      const sumTotalWasteRemoval = sumWasteRemovalInpit + sumWasteRemovalOutpit;

      const [coalHaul] = current(
        state.midTermPlanningDetail.data.dataWasteCoalHaul
      );
      const [coalGetting] = current(
        state.midTermPlanningDetail.data.dataWasteCoal
      );
      const [coalExpose] = current(
        state.midTermPlanningDetail.data.dataWasteCoalExpose
      );

      // sum based column (payload.tableRowName)
      const sumDataInpit =
        state.midTermPlanningDetailColumn.dataInpit[findIndex]['Header'];
      const sumDataOutpit =
        state.midTermPlanningDetailColumn.dataOutpit[findIndex]['Header'];

      if (isQualityTable || payload.tableType === 'dataWasteCoal') {
        const quality = current(
          state.midTermPlanningDetail.data.dataWasteQuality
        );

        quality.map((item, idx) => {
          function toNumber(input) {
            let tempNum = input || 0;
            if (typeof tempNum === 'string') {
              if (tempNum.includes(',')) {
                tempNum = tempNum.replace(',', '.');
              }
            }
            return Number(tempNum);
          }

          state.midTermPlanningDetail.data.dataWasteQuality[idx].year1 = round(
            (toNumber(item?.semester1 || 0) * (coalGetting?.semester1 || 0) +
              toNumber(item?.semester2 || 0) * (coalGetting?.semester2 || 0)) /
            ((coalGetting?.semester1 || 0) + (coalGetting?.semester2 || 0)),
            2
          );
        });
      } else {
        // blocks of possible dead code
        if (!isInputYear) {
          state.midTermPlanningDetail.data[payload.tableType][0]['year1'] =
            year1;

          if (!isCoalTable) {
            state.midTermPlanningDetail.data[payload.tableType][1]['year1'] =
              year2;
            state.midTermPlanningDetail.data[payload.tableType][2]['year1'] =
              year3;
            state.midTermPlanningDetail.data[payload.tableType][3]['year1'] =
              year4;
          }
        }
        // end blocks of possible dead code
      }

      // blocks of possible dead code
      const currentSumYear1 = sumBy(
        current(state.midTermPlanningDetail.data[payload.tableType]),
        'year1'
      );
      if (!isInputYear && !isQualityTable)
        state.midTermPlanningDetailColumn[payload.tableType][findIndexYear1][
          'Header'
        ] = currentSumYear1 || 0;
      // end blocks of possible dead code

      // set formula per column SR Coal Getting
      if (
        payload.tableType === 'dataWasteCoal' ||
        payload.tableType === 'dataInpit' ||
        payload.tableType === 'dataOutpit'
      ) {
        const val =
          payload.tableType === 'dataWasteCoal'
            ? +payload.value
            : +(detailData.dataWasteCoal?.[0]?.[payload.tableRowName] || 0);
        const totalWasteRemoval =
          parseInt(sumDataInpit || 0, 10) + parseInt(sumDataOutpit || 0, 10);

        const calc = totalWasteRemoval / val;
        state.midTermPlanningDetailColumn['dataWasteCoal'][findIndex][
          'Header'
        ] = val === 0 ? 0 : round(calc, 2);
      }

      // set formula per column SR Coal Expose
      if (
        payload.tableType === 'dataWasteCoalExpose' ||
        payload.tableType === 'dataInpit' ||
        payload.tableType === 'dataOutpit'
      ) {
        const val =
          payload.tableType === 'dataWasteCoalExpose'
            ? +payload.value
            : +(
              detailData.dataWasteCoalExpose?.[0]?.[payload.tableRowName] || 0
            );
        const totalWasteRemoval =
          parseInt(sumDataInpit || 0, 10) + parseInt(sumDataOutpit || 0, 10);

        const calc = totalWasteRemoval / val;
        state.midTermPlanningDetailColumn['dataWasteCoalExpose'][findIndex][
          'Header'
        ] = val === 0 ? 0 : round(calc, 2);
      }

      // Waste Removal Inpit Formula
      const haulDistanceInpit = detailData.dataInpit.find(
        (i) => i.wasteInpit === 'OB Haul Distance'
      );
      if (!isInputYear) {
        const target = current(state.midTermPlanningDetail.data.dataInpit);
        const idx = target.findIndex(
          (i) => i.wasteInpit === haulDistanceInpit.wasteInpit
        );

        const array2 =
          current(state.midTermPlanningDetailColumn.dataInpit) ?? [];
        const arr2 = array2
          .filter((i) => i.accessor.includes('semester'))
          .map((i) => i.Header);
        const arr1 = array2
          .filter((i) => i.accessor.includes('semester'))
          .reduce((a, b) => a.concat(target[idx]?.[b.accessor] ?? 0), []);

        // fisrt set sum of Waste Removal Inpit on year1
        // then make it as divider
        const sumTarget = sumBy(target, 'year1');
        const sumYear1 = sumTarget - target[idx].year1;

        const sumProduct = arr1.reduce((r, a, i) => r + a * arr2[i], 0);
        const year1SumProduct = Math.round(sumProduct / sumYear1);

        // set haul distance on year 1 based on calculation
        state.midTermPlanningDetail.data.dataInpit[idx].year1 = year1SumProduct;
      }

      // Waste Removal Outpit Formula
      const haulDistanceOutpit = detailData.dataOutpit.find(
        (i) => i.wasteOutpit === 'OB Haul Distance'
      );
      if (!isInputYear) {
        const target = current(state.midTermPlanningDetail.data.dataOutpit);
        const idx = target.findIndex(
          (i) => i.wasteOutpit === haulDistanceOutpit.wasteOutpit
        );

        const array2 =
          current(state.midTermPlanningDetailColumn.dataOutpit) ?? [];
        const arr2 = array2
          .filter((i) => i.accessor.includes('semester'))
          .map((i) => i.Header);
        const arr1 = array2
          .filter((i) => i.accessor.includes('semester'))
          .reduce((a, b) => a.concat(target[idx]?.[b.accessor] ?? 0), []);

        // fisrt set sum of Waste Removal Inpit on year1
        // then make it as divider
        const sumTarget = sumBy(target, 'year1');
        const sumYear1 = sumTarget - target[idx].year1;

        const sumProduct = arr1.reduce((r, a, i) => r + a * arr2[i], 0);
        const year1SumProduct = Math.round(sumProduct / sumYear1);

        // set haul distance on year 1 based on calculation
        state.midTermPlanningDetail.data.dataOutpit[idx].year1 =
          year1SumProduct;
      }

      // set Total Waste Removal year 1 sum
      state.midTermPlanningDetailColumn.dataInpit[findIndexYear1]['Header'] =
        sumWasteRemovalInpit;
      state.midTermPlanningDetailColumn.dataOutpit[findIndexYear1]['Header'] =
        sumWasteRemovalOutpit;

      if (!isQualityTable) {
        // Coal Formula
        const columns =
          current(state.midTermPlanningDetailColumn.dataWasteCoalHaul) ?? [];
        const arrCoalGetting = columns
          .filter((i) => i.accessor.includes('semester'))
          .reduce((a, b) => a.concat(coalGetting?.[b.accessor] ?? 0), []);
        const arrCoalHaul = columns
          .filter((i) => i.accessor.includes('semester'))
          .reduce((a, b) => a.concat(coalHaul?.[b.accessor] ?? 0), []);
        const arrCoalExpose = columns
          .filter((i) => i.accessor.includes('semester'))
          .reduce((a, b) => a.concat(coalExpose?.[b.accessor] ?? 0), []);

        const sumProduct = arrCoalHaul.reduce(
          (r, a, i) => r + a * arrCoalGetting[i],
          0
        );
        const year1SumProduct = Math.round(sumProduct / sum(arrCoalGetting));

        // set haul distance on year 1 based on calculation
        state.midTermPlanningDetail.data.dataWasteCoal[0].year1 =
          sum(arrCoalGetting);
        state.midTermPlanningDetail.data.dataWasteCoalExpose[0].year1 =
          sum(arrCoalExpose);
        state.midTermPlanningDetail.data.dataWasteCoalHaul[0].year1 =
          year1SumProduct; // SUMPRODUCT formulan Coal Haul Distance year1

        // SR Coal Expose Year 1
        let coalExposeYear1 =
          (coalExpose?.semester1 || 0) + (coalExpose?.semester2 || 0);
        state.midTermPlanningDetailColumn.dataWasteCoalExpose[findIndexYear1][
          'Header'
        ] =
          coalExposeYear1 !== 0
            ? round(sumTotalWasteRemoval / coalExposeYear1, 2) || 0
            : 0;

        // SR Coal Getting Year 1
        let coalGettingYear1 =
          (coalGetting?.semester1 || 0) + (coalGetting?.semester2 || 0);
        state.midTermPlanningDetailColumn.dataWasteCoal[findIndexYear1][
          'Header'
        ] =
          coalGettingYear1 !== 0
            ? round(sumTotalWasteRemoval / coalGettingYear1, 2) || 0
            : 0;
      }
    },
    onChangeYear: (state, action) => {
      const payload = action.payload;
      state.midTermPlanningDetail.data.longTermPlanning = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getmidTermPlanning.pending, (state, action) => {
      state.midTermPlanning.loading = true;
    });
    builder.addCase(getmidTermPlanning.fulfilled, (state, action) => {
      state.midTermPlanning.loading = false;
      state.midTermPlanning.records = action.payload.list;
      state.midTermPlanning.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getmidTermPlanning.rejected, (state, action) => {
      state.midTermPlanning.loading = false;
      state.midTermPlanning.error = 'Invalid get data';
    });

    builder.addCase(getmidTermPlanningDetail.pending, (state, action) => {
      state.midTermPlanningDetail.loading = true;
    });
    builder.addCase(getmidTermPlanningDetail.fulfilled, (state, action) => {
      state.midTermPlanningDetail = action.payload;
      state.midTermPlanningDetailColumn = action.payload?.data?.columnHeader;
    });
    builder.addCase(getmidTermPlanningDetail.rejected, (state, action) => {
      state.midTermPlanningDetail.loading = false;
      state.midTermPlanningDetail.error = 'Invalid get data';
    });

    builder.addCase(getVersionList.pending, (state, action) => {
      state.versionList.loading = true;
    });
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList.versionList = action.payload;
    });
    builder.addCase(getVersionList.rejected, (state, action) => {
      state.versionList.loading = false;
      state.versionList.error = 'Invalid get data';
    });

    builder.addCase(getByVersion.pending, (state, action) => {
      state.midTermPlanningDetail.loading = true;
    });
    builder.addCase(getByVersion.fulfilled, (state, action) => {
      state.midTermPlanningDetail.versionList = action.payload;
    });
    builder.addCase(getByVersion.rejected, (state, action) => {
      state.midTermPlanningDetail.loading = false;
      state.midTermPlanningDetail.error = 'Invalid get data';
    });
  },
});

export const {
  clearmidTermPlanningDetail,
  onChangeTableWasteInpit,
  createColumnmidPlanningDetail,
  onChangeCounterTableHeader,
  onChangeYear,
} = midTermPlanning.actions;

export const midTermPlanningSelector = (state) =>
  state.midTermPlanning.midTermPlanning;
export const midTermPlanningDetailSelector = (state) =>
  state.midTermPlanning.midTermPlanningDetail;
export const midTermPlanningDetailColumn = (state) =>
  state.midTermPlanning.midTermPlanningDetailColumn;
export const versionListSelector = (state) => state.midTermPlanning.versionList;

export default midTermPlanning.reducer;
