import moment from "moment";

export const numberExceptSymbol = ["e", "E", "+", "-", "."];

export const backendUrl =
  process.env.REACT_APP_API_URL ||
  process.env.REACT_APP_BASE_URL + '/services' ||
  'http://ccmdev.borneo-indobara.com/services';
export const backendUrlPTM =
  process.env.REACT_APP_API_URL_PTM ||
  process.env.REACT_APP_BASE_PTM + '/services/ptm' ||
  'http://ccmdev.borneo-indobara.com/services/ptm';
export const romInventoryBaseUrl = `http://${process.env.REACT_APP_ROM_INVENTORY_BASE_URL}/rom-inventory`;
export const apiUrlWaste = `http://${process.env.REACT_APP_WASTE_BASE_URL}/waste-removal`;
export const apiUrlCoal = `http://${process.env.REACT_APP_COAL_BASE_URL}/coal-getting`;
export const apiUrlConfiguration = `http://${process.env.REACT_APP_API_CONFIGURATION_BASE_URL}/default-payload`;
export const slipperyDurationBaseURL = `http://${process.env.REACT_APP_SLIPPERY_DURATION_BASE_URL}/slippery-duration`;
export const pitProgressBaseURL = `http://${process.env.REACT_APP_API_SURVEY_BASE_URL}/survey`;
export const apiUrlMasterData = `http://${process.env.REACT_APP_MASTER_DATA_BASE_URL}/master-data`;
export const apiUrlMinePlan = `http://${process.env.REACT_APP_API_MINE_PLAN_BASE_URL}/mine-plan`;
export const apiUrlDistanceHauling = `http://${process.env.REACT_APP_DISTANCE_HAULING_BASE_URL}/distance-hauling`;
export const apiUrlFleetPlanHaul = `http://${process.env.REACT_APP_FLEET_PLAN_HAUL_BASE_URL}/fleet-plan-haul`;
export const apiUrlMDPort = `http://${process.env.REACT_APP_MD_PORT_BASE_URL}/md-port`;
export const apiUrlFleetPlanRom = `http://${process.env.REACT_APP_FLEET_PLAN_ROM_BASE_URL}/fleet-plan-rom/v1/fleet-plan-rom`;
export const apiUrlConfigurationPort = `http://${process.env.REACT_APP_CONFIGURATION_PORT_BASE_URL}/config-port`;
export const apiUrlMasterDataRequest = `http://${process.env.REACT_APP_MASTER_DATA_REQUEST_URL}/master-data-request`;
export const apiUrlPlanningPort = `http://${process.env.REACT_APP_PLANNING_PORT_BASE_URL}/planning-port`;
export const apiUrlUser = `http://${process.env.REACT_APP_USER_BASE_URL}/user`;
export const isDevAppStatus =
  !process.env.REACT_APP_STATUS ||
  process.env.REACT_APP_STATUS === "development";
export const basePTM = process.env.REACT_APP_BASE_PTM;
export const appVersion = process.env.REACT_APP_APP_VERSION;

// generalAPIUrlFunction
export const getAPIEndpointURL = (module, version, path) =>
  `${backendUrl}/${module}/${version}${path.length ? "/" + (path.startsWith("/") ? path.slice(1) : path) : ""
  }`;

export const getV1EndpointWaste = () => `${backendUrl}/waste-removal/v1`;

export const getV1EndpointCoal = () => `${backendUrl}/coal-getting/v1`;

export const getV1PitProgressEndpoint = () => `${backendUrl}/survey/v1`;

export const getV1ROMInventoryEndpoint = () => `${backendUrl}/rom-inventory/v1`;

export const getV1MasterDataEndpoint = () => `${backendUrl}/master-data/v1`;

export const getSlipperyDurationEndpoint = (path) =>
  `${backendUrl}/slippery-duration/v1/${path}`;

export const getV1EndpointMinePlan = (path) =>
  `${backendUrl}/mine-plan/v1/${path}`;

export const getV1EndpointConfiguration = (path) =>
  `${backendUrl}/default-payload/v1/${path}`;

export const getV1EndpointBaDistance = (path) =>
  `${backendUrl}/master-data/v1/${path}`;

export const getV1EndpointDistanceHauling = (path) =>
  `${backendUrl}/haul-distance/v1/${path}`;

export const getV1EndpointFleetPlanHaul = () =>
  `${backendUrl}/fleet-plan-haul/v1/`;

export const getV1EndpointMDPort = (path) => `${backendUrl}/md-port/v1/${path}`;

export const getV1EndpointConfigPort = (path) =>
  `${backendUrl}/config-port/v1/${path}`;

export const getV1EndpointMasterDataRequest = () =>
  `${backendUrl}/md-request/v1/`;

export const getV1EndpointPlanningPort = (path) =>
  `${backendUrl}/plan-port/v1/${path}`;

export const getV1EndpointUser = (path) => `${backendUrl}/user/v1/${path}`;

export const getV1Dashboard = (path) => `${backendUrl}/dashboard/v1/${path}`;

export const getV1ActualPort = (path) => `${backendUrl}/actual-port/v1/${path}`;

export const getV2FleetActualRom = (path) => `${backendUrl}/fleet-actual-rom/v2/${path}`;

export const getV1PTMTrip = (path) => `${backendUrlPTM}/v1/trip`;

export const getV2MasterDataRom = (path) => `${backendUrl}/master-data-rom/v2/${path}`;

export const getV2ActualRom = (path) => `${backendUrl}/fleet-actual-rom/v2/${path}`;

export const getV2ConfigRom = (path) => `${backendUrl}/config-rom/v2/${path}`;

export const getV2PlanRom = (path) => `${backendUrl}/fleet-plan-rom/v2/${path}`;

export const appApiUrls = {
  ptmTrip: getV1PTMTrip(),
  masterData: getV1MasterDataEndpoint(),
  masterDataRequest: getV1EndpointMasterDataRequest(),
  wasteRemoval: getV1EndpointWaste(),
  coalGetting: getV1EndpointCoal(),
  slipperyWasteRemoval: getSlipperyDurationEndpoint('slippery'),
  slipperyCoalGetting: getSlipperyDurationEndpoint('slippery-coal'),
  intervensiSlippery: getSlipperyDurationEndpoint('intervensi'),
  intervensiMinePlan: getV1EndpointMinePlan('intervensi'),
  longTermPlannings: getV1EndpointMinePlan('long-term-plannings'),
  shortTermPlannings: getV1EndpointMinePlan('short-term-plannings'),
  midTermPlannings: getV1EndpointMinePlan('mid-term-plannings'),
  baDistanceWasteRemoval: getV1EndpointBaDistance(
    "configuration/ba-distance-waste-removal"
  ),
  baDistanceCoalGetting: getV1EndpointBaDistance(
    "configuration/ba-distance-coal-getting"
  ),
  romInventory: getV1ROMInventoryEndpoint(),
  pitProgress: getV1PitProgressEndpoint(),
  masterDataV2: getV1MasterDataEndpoint(),
  payload: getV1EndpointConfiguration("default-payload"),
  payloadWasteRemoval: getV1EndpointConfiguration("default-payload"),
  payloadCoalGetting: getV1EndpointConfiguration("default-payload"),
  intervensiPayload: getV1EndpointConfiguration('intervensi'),
  distanceHauling: getV1EndpointDistanceHauling("distance-hauling"),
  fleetPlanHaul: getV1EndpointFleetPlanHaul(),
  mdPortJetty: getV1EndpointMDPort("jetties"),
  mdPortCrusher: getV1EndpointMDPort("crushers"),
  confCrusher: getV1EndpointConfigPort("configuration-crushers/"),
  confJetty: getV1EndpointConfigPort("configuration-jetties/"),
  confEwh: getV1EndpointConfigPort("configuration-ewh/"),
  intervensiConfigPort: getV1EndpointConfigPort("intervensi"),
  portCrusherPlan: getV1EndpointPlanningPort("crusher-plans"),
  portJettyLoadingPlans: getV1EndpointPlanningPort("jetty-loading-plans"),
  portPlanIntervensi: getV1EndpointPlanningPort("intervensi"),
  fleetPlanRom: getAPIEndpointURL("fleet-plan-rom", "v1", ""),
  fleetActualRom: getAPIEndpointURL("fleet-actual-rom", "v1", ""),
  userMember: getV1EndpointUser("", "v1", ""),
  userConfig: getV1EndpointUser("users", "v1", ""),
  approvalConfig: getV1EndpointUser("approval-configurations", "v1", ""),
  modulConfig: getV1EndpointUser("moduls", "v1", ""),
  roleConfig: getV1EndpointUser("roles", "v1", ""),
  dashboardData: getV1Dashboard("data"),
  actualPort: getV1ActualPort(""),
  actualPortCrushing: getV1ActualPort("crushing"),
  actualPortJetty: getV1ActualPort("jetty"),
  actualPortBlc: getV1ActualPort("loading-blc"),
  attendances: getV1ActualPort("attendances"),
  directBarge: getV1ActualPort("direct-barges"),
  masterCaptain: getAPIEndpointURL("ptm", "v1", "captain", true),
  actualPortCategory: getV1ActualPort("category"),
  actualPortAnswer: getV1ActualPort("answer"),
  crushers: getV1EndpointMDPort("crushers"),
  mdRom: getV2MasterDataRom("rom"),
  actualRom: getV2ActualRom("rom"),
  activityFileRom: getV2ActualRom("activity"),
  configRom: getV2ConfigRom("configuration-rom"),
  intervensiConfRom: getV2ConfigRom("intervensi"),
  planRom: getV2PlanRom("rom-plans"),
  attendanceRom: getV2ActualRom("attendances"),
  romActualPortCategory: getV2FleetActualRom("category"),
  romActualPortAnswer: getV2FleetActualRom("answer"),
};

export const STATUS = {
  accepted: "APPROVED",
  waiting_approval: "WAITING_APPROVAL",
  rejected: "REJECTED",
  deleted: "DELETED",
  draft: "DRAFT",
};

export const shortMonthList = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Agu",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];

export const shortMonthListEN = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const monthList = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  },
];

export const getYearList = (length) => {
  const years = [];
  const dateStart = moment();
  const dateEnd = moment().add(length, "y");
  while (dateEnd.diff(dateStart, "years") >= 0) {
    years.push(dateStart.format("YYYY"));
    dateStart.add(1, "year");
  }

  return years;
};

export const activityOptions = [
  {
    label: "Work",
    value: "Work",
  },
  {
    label: "Maintenance",
    value: "Maintenance",
  },
  {
    label: "Delay",
    value: "Delay",
  },
  {
    label: "End of Shift",
    value: "End of Shift",
  },
];

export const detailOptions = [
  {
    label: "Schedule",
    value: "Schedule",
  },
  {
    label: "Non Schedule",
    value: "Non Schedule",
  },
  {
    label: "Within Cargo",
    value: "Within Cargo",
  },
  {
    label: "Rain (Bad Weather)",
    value: "Rain (Bad Weather)",
  },
];

export const workDropdown = [
  {
    label: "Within Cargo",
    value: "Within Cargo",
  },
  {
    label: "Without Cargo",
    value: "Without Cargo",
  },
  {
    label: "ROM: Dengan Batubara",
    value: "ROM: Dengan Batubara",
  },
];

export const maintenanceDropdown = [
  {
    label: "Schedule",
    value: "Schedule",
  },
  {
    label: "Non Schedule",
    value: "Non Schedule",
  },
  {
    label: "ROM: Electric Schedule",
    value: "ROM: Electric Schedule",
  },
  {
    label: "ROM: Mechanical Schedule",
    value: "ROM: Mechanical Schedule",
  },
  {
    label: "ROM: Electric Unschedule",
    value: "ROM: Electric Unschedule",
  },
  {
    label: "ROM: Mechanical Unschedule",
    value: "ROM: Mechanical Unschedule",
  },
];

export const delayDropdown = [
  {
    label: "Cargo Unavailable",
    value: "Cargo Unavailable",
  },
  {
    label: "Bolder (COS)",
    value: "Bolder (COS)",
  },
  {
    label: "Rain (Bad weather)",
    value: "Rain (Bad weather)",
  },
  {
    label: "HE/ Equipment Support Problem",
    value: "HE/ Equipment Support Problem",
  },
  {
    label: "Dust Suppression Problem",
    value: "Dust Suppression Problem",
  },
  {
    label: 'Operator Crusher Unavailable',
    value: 'Operator Crusher Unavailable',
  },
  {
    label: "System Fault",
    value: "System Fault",
  },
  {
    label: "Safety Talk",
    value: "Safety Talk",
  },
  {
    label: "Rest/Pray/Meal",
    value: "Rest/Pray/Meal",
  },
  {
    label: "Overshift",
    value: "Overshift",
  },
  {
    label: "Crusher Standby",
    value: "Crusher Standby",
  },
  {
    label: "Coal spillage/blocking/cleaning",
    value: "Coal spillage/blocking/cleaning",
  },
  {
    label: "Operation's Incident",
    value: "Operation's Incident",
  },
  {
    label: "ROM: Running Tanpa Batubara",
    value: "ROM: Running Tanpa Batubara",
  },
  {
    label: "ROM: No Operator",
    value: "ROM: No Operator",
  },
  {
    label: "ROM: Waiting Cargo",
    value: "ROM: Waiting Cargo",
  },
  {
    label: "ROM: ISOMA",
    value: "ROM: ISOMA",
  },
  {
    label: "ROM: Kondisi Debu",
    value: "ROM: Kondisi Debu",
  },
  {
    label: "ROM: Menunggu Pengawas/Waiting Info",
    value: "ROM: Menunggu Pengawas/Waiting Info",
  },
  {
    label: "ROM: Perbaikan Jalan ROM",
    value: "ROM: Perbaikan Jalan ROM",
  },
  {
    label: "ROM: Waiting DT Hauling",
    value: "ROM: Waiting DT Hauling",
  },
  {
    label: "ROM: Waiting BW",
    value: "ROM: Waiting BW",
  },
  {
    label: "ROM: Cleaning Area TLS",
    value: "ROM: Cleaning Area TLS",
  },
  {
    label: "ROM: No settingan/full space",
    value: "ROM: No settingan/full space",
  },
  {
    label: "ROM: Operation's Incident",
    value: "ROM: Operation's Incident",
  },
];

export const delayLoadingDropdown = [
  {
    value: "Waiting Cargo", label: "Waiting Cargo"
  },
  {
    value: "Heavy Rain", label: "Heavy Rain"
  },
  {
    value: "Overflow", label: "Overflow"
  },
  {
    value: "System Fault (Non-overflow)", label: "System Fault (Non-overflow)"
  },
  {
    value: "Cleaning Area", label: "Cleaning Area"
  },
  {
    value: "Rest Time", label: "Rest Time"
  },
  {
    value: "Waiting Information BIB", label: "Waiting Information BIB"
  },
  {
    value: "Over Shift", label: "Over Shift"
  },
  {
    value: "Safety Talk", label: "Safety Talk"
  },
  {
    value: "BLC Standby (Delay Jetty)", label: "BLC Standby (Delay Jetty)"
  },
  {
    value: "BLC Standby (Delay Port)", label: "BLC Standby (Delay Port)"
  },
]

export const workRomDropdown = [
  {
    label: "Within Cargo",
    value: "Within Cargo",
  },
];


export const maintenanceRomDropdown = [
  {
    label: "Electric Schedule",
    value: "Electric Schedule",
  },
  {
    label: "Mechanical Schedule",
    value: "Mechanical Schedule",
  },
  {
    label: "Electric Unschedule",
    value: "Electric Unschedule",
  },
  {
    label: "Mechanical Unschedule",
    value: "Mechanical Unschedule",
  }
];

export const delayRomDropdown = [
  {
    label: "Coal Oversize",
    value: "Coal Oversize",
  },
  {
    label: "Conveyor Slip",
    value: "Conveyor Slip",
  },
  {
    label: "Daily Shift Change",
    value: "Daily Shift Change",
  },
  {
    label: "Safety Talk",
    value: "Safety Talk",
  },
  {
    label: "P2H Unit",
    value: "P2H Unit",
  },
  {
    label: "Isoma",
    value: "Isoma",
  },
  {
    label: "No Operator",
    value: "No Operator",
  },
  {
    label: "Waiting Cargo",
    value: "Waiting Cargo",
  },
  {
    label: "Reueling",
    value: "Reueling",
  },
  {
    label: "Kondisi Baru",
    value: "Kondisi Baru",
  },
  {
    label: "Menunggu Pengawas / Waiting Info",
    value: "Menunggu Pengawas / Waiting Info",
  },
  {
    label: "Perbaikan Jalan ROM",
    value: "Perbaikan Jalan ROM",
  },
  {
    label: "Waiting DT Hauling",
    value: "Waiting DT Hauling",
  },
  {
    label: "Waiting BW",
    value: "Waiting BW",
  },
  {
    label: "Cleasing Area TLS",
    value: "Cleasing Area TLS",
  },
  {
    label: "No Settingan/Full Space",
    value: "No Settingan/Full Space",
  },
  {
    label: "Operation's Incident",
    value: "Operation's Incident",
  },
];

export const notificationLink = (moduleCode, id) => {
  const moduleLinks = {
    MDR_SUBLOCATION: "/request-master-data/sub-location/{ID}",
    MDR_EQUIPMENT_PIT: "/request-master-data/equipment-detail/{ID}",
    MDR_EQUIPMENT_TYPE: "/request-master-data/equipment-type/{ID}",
    MDR_MATERIAL: "/request-master-data/material/{ID}",
    MDR_EQUIPMENT_HAUL: "/request-master-data/equipment-haul/{ID}",
    MDR_EQUIPMENT_ROM: "/request-master-data/equipment-rom/{ID}",
    CONF_EWH: "/configuration/ewh/{ID}",
    CONF_PAYLOAD_COAL: "/configuration/payload/coal-getting/{ID}",
    CONF_PAYLOAD_WASTE: "/configuration/payload/waste-removal/{ID}",
    CONF_SLIP_COAL: "/configuration/slipperly/coal-getting/{ID}",
    CONF_SLIP_WASTE: "/configuration/slipperly/waste-removal/{ID}",
    CONF_CRUSHER: "/configuration/conf-crusher/{ID}",
    CONF_JETTY: "/configuration/jety-loading/{ID}",
    HAUL_DIST_HAULING: "/haul-road/ba-distance-hauling/detail/{ID}",
    ACTUAL_FL_HAUL: "/haul-road/fleet-actual-hauling/detail/{ID}",
    PLAN_FL_HAUL: "/haul-road/fleet-plan-hauling/detail/{ID}",
    COAL_HAUL_DIST: "/pit-production/coal-haul-distance/{ID}",
    WASTE_HAUL_DIST: "/pit-production/waste-haul-distance/{ID}",
    SURVEY_PIT_PROGRESS: "/pit-production/survey/{ID}",
    SURVEY_ROM_INVENTORY: "/pit-production/rom-inventory/{ID}",
    COAL_GETTING: "/pit-production/coal-getting/rain-slipperly/{ID}",
    WASTE_REMOVAL: "/pit-production/waste-removal/rain-slipperly/{ID}",
    LONG_TERM_PLANNING: "/pit-production/long-term-planning/{ID}",
    MID_TERM_PLANNING: "/pit-production/mid-term-planning/{ID}",
    SHORT_TERM_PLANNING: "/pit-production/short-term-planning/{ID}",
    PLAN_CRUSHER: "/port/crushing-plant-plan/{ID}",
    PLAN_JETTY: "/port/jetty-loading-plan/{ID}",
    ACTUAL_FL_ROM: "/rom/rom-fleet-actual/detail/{ID}",
    PLAN_FL_ROM: "/rom/rom-feet-plan/detail/{ID}",
  };

  const selectedLink = moduleLinks[moduleCode];
  if (selectedLink) {
    return selectedLink.replace("{ID}", id);
  }
  return "";
};
