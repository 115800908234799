import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrls, get, getAPIEndpoint, post } from "utils/request";
import moment from "moment-timezone";
import axios from 'axios';

// initial state
export const initialState = {
  portBlc: {
    loading: false,
    records: [],
    recordsTotal: 0,
    error: "",
  },
  portJetty: {
    loading: false,
    records: [],
    error: "",
  },
  activityBarge: {
    records: [],
  },
  directBarge: {
    activity: {
      castOfJetty: null,
      commenceLoading: null,
      completeLoading: null,
      portArrival: null,
      sideJetty: null,
      startSideJetty: null,
    },
    barge: null,
    loadingPosition: null,
    port: null,
    totalCargo: null,
    returnCargo: null,
    tugBoat: null,
    destination: null,
    totalLoading: null,
  },
  transhipment: {
    returnCargo: 0,
    totalDelay: 0,
    remark: null,
  },
};

const sortedData = (payload) => {
  const sortData = payload.map((item) => {
    const data = item.data.sort((a, b) => {
      const aDate = moment(a?.trip_data?.completeLoading?.datetime, 'MM/DD/YYYY HH:mm:ss');
      const bDate = moment(b?.trip_data?.completeLoading?.datetime, 'MM/DD/YYYY HH:mm:ss');
      const aTime = moment(a?.trip_data?.completeLoading?.time, 'HH:mm');
      const bTime = moment(b?.trip_data?.completeLoading?.time, 'HH:mm');
      if (aDate.isBefore(bDate)) {
        return -1;
      } else if (aDate.isAfter(bDate)) {
        return 1;
      } else {
        if (aTime.isBefore(bTime)) {
          return -1;
        } else if (aTime.isAfter(bTime)) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return {
      ...item,
      data
    };
  });

  return sortData.sort((a, b) => {
    const aDate = moment(a?.data[0]?.trip_data?.completeLoading?.datetime, 'MM/DD/YYYY HH:mm:ss');
    const bDate = moment(b?.data[0]?.trip_data?.completeLoading?.datetime, 'MM/DD/YYYY HH:mm:ss');
    const aTime = moment(a?.data[0]?.trip_data?.completeLoading?.time, 'HH:mm');
    const bTime = moment(b?.data[0]?.trip_data?.completeLoading?.time, 'HH:mm');
    if (aDate.isBefore(bDate)) {
      return -1;
    } else if (aDate.isAfter(bDate)) {
      return 1;
    } else {
      if (aTime.isBefore(bTime)) {
        return -1;
      } else if (aTime.isAfter(bTime)) {
        return 1;
      } else {
        return 0;
      }
    }
  });
}


export const updateTranshipment = async (params) => {
  const response = await axios.put(
    `${apiUrls.directBarge}/${params?.idDirectBarge}/${params?.id}/save?userName=${params?.userName}`,
    params?.object
  );
  return response.data;
}

// fetch functions
export const fetchHistoryBarging = createAsyncThunk(
  "actual-port-jetty/get-history-barging",
  async (params) => {
    // const response = await get(`${apiUrls.ptmTrip}/summary-complete-loading?date_start=${params.start}&date_end=${params.end}`);
    const response = await get(
      `${apiUrls.directBarge}/summary-complete-loading?dateStart=${params.start}&dateEnd=${params.end}`
    );
    return response.data;
  }
);

export const fetchActualPortBlc = createAsyncThunk(
  "actual-port-jetty/filter",
  async (filter) => {
    const response = await post(
      getAPIEndpoint("actual-port", "v1", "/loading-blc/filter"),
      {
        columns: [
          {
            data: "date",
            orderable: true,
            search: {
              regex: true,
              value: "",
            },
            searchValue: filter.date || "",
            searchable: true,
          },
          {
            data: "shift",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
        ],
        draw: 1,
        length: -1,
        order: [],
      }
    );
    return response.data;
  }
);

export const exportActualToExcel = createAsyncThunk(
  "actual-port-jetty/exportExcel",
  async (params) => {
    const object = {
      crushingPlant: params.crushingPlant?.label,
      endDate: params.endDate,
      blc: params.crushingPlant?.label,
      shift: params.shift?.value,
      startDate: params.startDate,
    };

    const response = await post(
      getAPIEndpoint("actual-port", "v1", "/loading-blc/export-excel-file"),
      object
    );
    return response?.data;
  }
);

export const handleCreateDirectBarge = createAsyncThunk(
  "actual-port-jetty/create-direct-barge",
  async (param) => {
    const { object, userName } = param;
    const response = await post(
      `${apiUrls.directBarge}/save?userName=${userName}`,
      object
    );
    return response.data;
  }
);

export const getDirectBarge = createAsyncThunk(
  "actual-port-jetty/get-direct-barge",
  async (params) => {
    const response = await post(`${apiUrls.directBarge}/${params}`);
    return response.data;
  }
);
export const getTranshipment = createAsyncThunk(
  "actual-port-jetty/get-transhipment",

  async (params) => {
    const response = await get(`${apiUrls.directBarge}/${params}/transhipment`);

    return response.data;
  }
);

export const getActualJetty = createAsyncThunk(
  "actual-port-jetty/get-actual-jetty",

  async (params) => {
    const { dateEnd, dateStart } = params

    const response = await get(`${apiUrls.actualPort}/admin/loading-jetty/dashboard?dateEnd=${dateEnd}&dateStart=${dateStart}`);

    return response.data;
  }
);

// slicing
const actualJetty = createSlice({
  name: "ACTUAL_JETTY",
  initialState,
  reducers: {
    clearDirectBarge: (state, action) => {
      state.directBarge = {
        activity: {
          castOfJetty: null,
          commenceLoading: null,
          completeLoading: null,
          portArrival: null,
          sideJetty: null,
          startSideJetty: null,
        },
        barge: null,
        loadingPosition: null,
        port: null,
        totalCargo: null,
        tugBoat: null,
        destination: null,
      };
    },

    onChangeActivityBarge: (state, action) => {
      const payload = action.payload;
      state.activityBarge.records.map((record) => {
        record.data.map((item) => {
          if (item.id === payload.key) {
            item[payload.column] = payload.value;
          }
        });
      });
    },

    onChangeDirectBarge: (state, action) => {
      const payload = action.payload;
      if (!payload.isActivity) {
        if (payload.key === "totalCargo") {
          let currTotalCargoDec =
            state.directBarge["totalCargo"]?.toString().split(".")?.[1] ||
            "000";
          state.directBarge[payload.key] = payload.value.concat(
            "." + currTotalCargoDec
          );
        } else if (payload.key === "totalCargoDec") {
          let currTotalCargo =
            state.directBarge["totalCargo"]?.toString().split(".")?.[0] || "0";
          state.directBarge["totalCargo"] = currTotalCargo.concat(
            "." + payload.value
          );
        } else {
          state.directBarge[payload.key] = payload.value;
        }
      } else {
        state.directBarge.activity[payload.key] = moment(payload.value)
          .tz(moment.tz.guess())
          .format();
        // state.directBarge.activity[payload.key] = payload.value;
        // state.directBarge.activity[payload.key] = new Date(payload.value);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActualPortBlc.fulfilled, (state, action) => {
      state.portBlc.loading = false;
      state.portBlc.records = action.payload?.list;
      state.portBlc.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(fetchActualPortBlc.pending, (state) => {
      state.portBlc.loading = true;
    });
    builder.addCase(fetchHistoryBarging.fulfilled, (state, action) => {
      state.activityBarge.records = sortedData(action?.payload);
      state.activityBarge.records.map((record) => {
        record.data.map((item) => {
          item["originalData"] = {
            returnCargo: item.returnCargo,
            totalDelay: item.totalDelay,
            remark: item.remark,
          };
        });
      });
    });

    builder.addCase(getDirectBarge.fulfilled, (state, action) => {
      state.directBarge = action.payload;
    });
    builder.addCase(getTranshipment.fulfilled, (state, action) => {
      state.transhipment = action.payload;
    });

    builder.addCase(getActualJetty.fulfilled, (state, action) => {
      state.portJetty.loading = false;
      state.portJetty.records = action.payload;
    });
    builder.addCase(getActualJetty.pending, (state) => {
      state.portJetty.loading = true;
    });
  },
});

export const portActualBlc = (state) => state.actualJetty.portBlc;
export const activityBargeSelector = (state) => state.actualJetty.activityBarge;
export const directBargeSelector = (state) => state.actualJetty.directBarge;
export const portActualJettySelector = (state) => state.actualJetty.portJetty;

export const { clearDirectBarge, onChangeDirectBarge, onChangeActivityBarge } =
  actualJetty.actions;

export default actualJetty.reducer;
