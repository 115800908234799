import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, patch, post } from 'utils/request';

export const initialState = {
  distanceCoalGettingList: {
    baDistanceResponseList: [],
    recordsTotal: 0,
    loading: false,
    error: null,
  },
  distanceCoalGettingDetail: {
    data: {},
    loading: false,
    error: null,
  },
};
export const DISTANCE_COAL_GETTINGL_REDUCER = 'DISTANCE_COAL_GETTINGL_REDUCER';
export const fetchDistanceCoalGettingList = createAsyncThunk(
  'distance-coal-getting/list',
  async (params) => {
    const { filter } = params;
    const response = await post(
      `${apiUrls.baDistanceCoalGetting}/filter`,
      filter
    );
    return response?.data;
  }
);

export const fetchDistanceCoalGettingDetail = createAsyncThunk(
  'Distance-coal-getting/detail',
  async (param) => {
    const { id } = param;
    const response = await get(`${apiUrls.baDistanceCoalGetting}/${id}`);
    return response.data;
  }
);
export const addDistanceCoalGetting = createAsyncThunk(
  'Distance-coal-getting/add',
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.baDistanceCoalGetting}/add`, data);
    return response.data;
  }
);
export const updateDistanceCoalGetting = createAsyncThunk(
  'Distance-coal-getting/update',
  async (param) => {
    const { updateData } = param;
    const response = await post(
      `${apiUrls.baDistanceCoalGetting}/update`,
      updateData
    );
    return response.data;
  }
);
export const deleteDistanceCoalGetting = createAsyncThunk(
  'Distance-coal-getting/delete',
  async (param) => {
    const { id } = param;
    const response = await deleteRequest(
      `${apiUrls.baDistanceCoalGetting}/delete/${id}`
    );
    return response.data;
  }
);
export const setDistanceCoalGettingStatus = createAsyncThunk(
  'Distance-coal-getting/setStatus',
  async (param) => {
    const { data } = param;
    const response = await patch(
      `${apiUrls.baDistanceCoalGetting}/set-status`,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const DistanceCoalGettingSubmit = createAsyncThunk(
  'Distance-coal-getting/submit',
  async (param) => {
    const { id } = param;
    const response = await post(`${apiUrls.baDistanceCoalGetting}/submit`, {
      id,
    });
    return response.data;
  }
);

const distanceCoalGettingSlice = createSlice({
  name: DISTANCE_COAL_GETTINGL_REDUCER,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDistanceCoalGettingList.pending, (state, action) => {
      state.distanceCoalGettingList.loading = true;
      state.distanceCoalGettingList.error = null;
    });
    builder.addCase(fetchDistanceCoalGettingList.fulfilled, (state, action) => {
      const { recordsTotal, baDistanceResponseList } = action.payload;
      state.distanceCoalGettingList.loading = false;
      state.distanceCoalGettingList.baDistanceResponseList =
        baDistanceResponseList;
      state.distanceCoalGettingList.recordsTotal = recordsTotal;
    });
    builder.addCase(fetchDistanceCoalGettingList.rejected, (state, action) => {
      state.distanceCoalGettingList.loading = false;
      state.distanceCoalGettingList.error = action.error;
    });
    builder.addCase(fetchDistanceCoalGettingDetail.pending, (state, action) => {
      state.distanceCoalGettingDetail.loading = true;
      state.distanceCoalGettingDetail.error = null;
    });
    builder.addCase(
      fetchDistanceCoalGettingDetail.fulfilled,
      (state, action) => {
        state.distanceCoalGettingDetail.loading = false;
        state.distanceCoalGettingDetail.data = action.data;
      }
    );
    builder.addCase(
      fetchDistanceCoalGettingDetail.rejected,
      (state, action) => {
        state.distanceCoalGettingDetail.loading = false;
        state.distanceCoalGettingDetail.error = action.error;
      }
    );
  },
});

export const distanceCoalGettingSelector = (state) => state.distanceCoalGetting;
export default distanceCoalGettingSlice.reducer;
