import cx from 'classnames';
import React from 'react';
import bn from 'utils/bemNames';

const bem = bn('grid-list');

export const GridGroupItem = ({ title, data, columns }) => {
  const numberOfRows = data?.length || 1;
  const numberOfColumns = columns?.length + 1 || 1;
  return (
    <div
      className="grid-group-item"
      style={{
        gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
        gridTemplateRows: `repeat(${numberOfRows + 1}, 1fr)`,
      }}
    >
      <div className="list-item">{title}</div>

      {data?.slice(1)?.map((_, index) => (
        <div key={`${index}+ ${new Date()}`} className="list-item" />
      ))}

      <div
        className="list-item"
        style={{
          gridColumnStart: '0',
          gridColumnEnd: `span ${numberOfColumns}`,
        }}
      />
      {columns?.map((col) => (
        <React.Fragment key={col?.accessor}>
          {data?.map?.((cell, index) => (
            <div className="list-item" key={`${cell?.id}+${new Date()}`}>
              {cell[col?.accessor]}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

const GridList = ({ data, columns, className }) => {
  return (
    <div className={cx(bem.b(), bem.e(className))}>
      {data?.map((rom) => (
        <GridGroupItem
          key={rom.id + rom?.description}
          title={rom?.description}
          data={rom?.data}
          columns={columns}
        />
      ))}
    </div>
  );
};

export default GridList;
