import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';

export const initialState = {
  slipperyWasteRemovalList: {
    list: [],
    recordsTotal: 0,
    loading: false,
    error: null,
  },
  slipperyWasteRemovalDetail: {
    data: {},
    loading: false,
    error: null,
  },
  slipperyWasteRemovalLatest: {
    data: {},
    loading: false,
    error: null,
  },
  contractorByArea: {
    records: [],
    loading: false,
    error: ""
  }
};

export const SLIPPERY_WASTE_REMOVAL_REDUCER = 'SLIPPERY_WASTREMOVAL_REDUCER';

export const fetchSlipperyWasteRemovalList = createAsyncThunk(
  'slippery/list',
  async (params) => {
    const response = await post(`${apiUrls.slipperyWasteRemoval}/filter`, {
      columns: [
        {
          data: 'slipperyId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params?.reportId || '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params?.date || '',
          searchable: true,
        },
        {
          data: 'year',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params?.year || '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params?.dataStatus === DATASOURCE_VALUE.waitingApproval || params?.dataStatus >= 20 ? true : false,
            value: '',
          },
          searchValue: params?.dataStatus || '',
          searchable: true,
        },
        {
          data: 'contractorName',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params?.contractorName || '',
          searchable: true,
        },
        {
          data: 'contractorCode',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.contractor || '',
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.query || '',
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.dataLength ? params.dataLength : -1,
      order: [],
    });
    return response?.data;
  }
);

export const fetchSlipperyWasteRemovalDetail = createAsyncThunk(
  'slipperyWasteRemoval/detail',
  async (param) => {
    const { id } = param;
    const response = await get(`${apiUrls.slipperyWasteRemoval}/${id}`);
    return response.data;
  }
);

export const addSlipperyWasteRemoval = createAsyncThunk(
  'slipperyWasteRemoval/add',
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.slipperyWasteRemoval}/add`, data);
    return response.data;
  }
);

export const updateSlipperyWasteRemoval = createAsyncThunk(
  'slipperyWasteRemoval/update',
  async (param) => {
    const { updateData } = param;
    const response = await put(
      `${apiUrls.slipperyWasteRemoval}/update`,
      updateData
    );
    return response.data;
  }
);

export const deleteSlipperyWasteRemoval = createAsyncThunk(
  'slipperyWasteRemoval/delete',
  async (param) => {
    const { id } = param;
    const response = await deleteRequest(
      `${apiUrls.slipperyWasteRemoval}/delete/${id}`
    );
    return response.data;
  }
);

export const saveSlipperyWasteRemoval = createAsyncThunk(
  'slipperyWasteRemoval/save',
  async (param) => {
    const { data, userName } = param;
    const response = await put(
      `${apiUrls.slipperyWasteRemoval}/save?userName=${userName}`,
      data
    );
    return response.data;
  }
);

export const submitSlipperyWasteRemoval = createAsyncThunk(
  'slipperyWasteRemoval/submit',
  async (param) => {
    const { data, userName } = param;
    const response = await post(
      `${apiUrls.slipperyWasteRemoval}/submit?userName=${userName}`,
      data
    );
    return response.data;
  }
);

export const setSlipperyWasteRemovalStatus = createAsyncThunk(
  'slipperyWasteRemoval/setStatus',
  async (param) => {
    const { data } = param;
    const response = await patch(
      `${apiUrls.slipperyWasteRemoval}/set-status?userName=admin`,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const slipperyWasteRemovalSubmit = createAsyncThunk(
  'slipperyWasteRemoval/submit',
  async (param) => {
    const { id } = param;
    const response = await post(`${apiUrls.slipperyWasteRemoval}/submit`, {
      id,
    });
    return response.data;
  }
);

export const deleteSlipperyWasteRemovalFile = createAsyncThunk(
  'slipperyWasteRemoval/deleteFile',
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.slipperyWasteRemoval}/${id}/files/${name}`
    );
    return response.data;
  }
);

export const uploadSlipperyWasteRemovalFile = createAsyncThunk(
  'slipperyWasteRemoval/uploadFile',
  async (param) => {
    const { id, file } = param;
    const response = await post(
      `${apiUrls.slipperyWasteRemoval}/${id}/files`,
      file,
      {
        headers: { 'Content-Type': undefined },
      }
    );
    return response.data;
  }
);

export const handleApproveRejectAdmin = createAsyncThunk(
  'slipperyWasteRemoval/admin-approval',
  async (param) => {
    const { id, notes, userName, action } = param;
    const response = await put(
      `${apiUrls.slipperyWasteRemoval}/${id}/${action}?userName=${userName}`,
      { notes: action === 'reject' ? notes : '' }
    );
    return response.data;
  }
);

export const handleApproveReject = createAsyncThunk(
  'slipperyWasteRemoval/approval',
  async (param) => {
    const { id, notes, userName, action } = param;
    const response = await put(
      `${apiUrls.slipperyWasteRemoval}/${id}/${action}?userName=${userName}`,
      { notes: action === 'reject' ? notes : '' }
    );
    return response.data;
  }
);

export const getSlipperyWasteRemovalLatest = createAsyncThunk(
  'slipperyWasteRemoval/latest',
  async(param) => {
    const { contractorCode } = param;
    const response = await get(`${apiUrls.slipperyWasteRemoval}/latest-detail?contractorCode=${contractorCode}`);
    return response.data;
  }
)

export const fetchContractorByArea = createAsyncThunk(
  `master-data/area-by-name`,
  async (params) => {
    const { area } = params;
    const response = await get(`${apiUrls.masterData}/areas/names/${area}/contractors`);
    return response?.data;
  }
);

export const intervensiSlippery = createAsyncThunk(
  `slippery/intervensi`,
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.intervensiSlippery}/user-role?moduleName=SlipperyDuration`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const slipperyWasteRemovalSlice = createSlice({
  name: SLIPPERY_WASTE_REMOVAL_REDUCER,
  initialState,
  reducers: {
    resetSlipperyWasteDetail: (state, action) => {
      state.slipperyWasteRemovalDetail = {
        data: {},
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSlipperyWasteRemovalList.pending, (state, action) => {
      state.slipperyWasteRemovalList.loading = true;
    });
    builder.addCase(
      fetchSlipperyWasteRemovalList.fulfilled,
      (state, action) => {
        const { list, recordsTotal } = action.payload;
        state.slipperyWasteRemovalList.loading = false;
        state.slipperyWasteRemovalList.list = list;
        state.slipperyWasteRemovalList.recordsTotal = recordsTotal;
      }
    );
    builder.addCase(fetchSlipperyWasteRemovalList.rejected, (state, action) => {
      state.slipperyWasteRemovalList.loading = false;
      state.slipperyWasteRemovalList.error = action.error;
    });
    builder.addCase(
      fetchSlipperyWasteRemovalDetail.pending,
      (state, action) => {
        state.slipperyWasteRemovalDetail.loading = true;
      }
    );
    builder.addCase(
      fetchSlipperyWasteRemovalDetail.fulfilled,
      (state, action) => {
        state.slipperyWasteRemovalDetail.loading = false;
        state.slipperyWasteRemovalDetail.data = action.payload;
      }
    );
    builder.addCase(
      fetchSlipperyWasteRemovalDetail.rejected,
      (state, action) => {
        state.slipperyWasteRemovalDetail.loading = false;
        state.slipperyWasteRemovalDetail.error = action.error;
      }
    );

    builder.addCase(
      getSlipperyWasteRemovalLatest.pending,
      (state, action) => {
        state.slipperyWasteRemovalLatest.loading = true;
      }
    );
    builder.addCase(
      getSlipperyWasteRemovalLatest.fulfilled,
      (state, action) => {
        state.slipperyWasteRemovalLatest.loading = false;
        state.slipperyWasteRemovalLatest.data = action.payload;
      }
    );
    builder.addCase(
      getSlipperyWasteRemovalLatest.rejected,
      (state, action) => {
        state.slipperyWasteRemovalLatest.loading = false;
        state.slipperyWasteRemovalLatest.error = action.error;
      }
    );

    builder.addCase(fetchContractorByArea.pending, (state, action) => {
      state.contractorByArea.loading = true;
    });
    builder.addCase(fetchContractorByArea.fulfilled, (state, action) => {
      state.contractorByArea.records = action.payload;
    });
    builder.addCase(fetchContractorByArea.rejected, (state, action) => {
      state.contractorByArea.loading = false;
      state.contractorByArea.error = action.error;
    });
  },
});

export const { resetSlipperyWasteDetail } = slipperyWasteRemovalSlice.actions;

export const slipperyWasteRemovalSelector = (state) =>
  state.slipperyWasteRemoval;
export const contractorByArea = (state) =>
  state.slipperyWasteRemoval.contractorByArea;
export default slipperyWasteRemovalSlice.reducer;
