export const dummyTableData = {
  list: [
    {
      reportId: 'SURV-P-2022-04-005',
      createdAt: '2022-06-06T00:00:00',
      shift: 'Day',
      version: 2,
      dataStatus: 4,
      id: 1,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-06T00:00:00',
      shift: 'Night',
      version: 3,
      dataStatus: 2,
      id: 2,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-06T00:00:00',
      shift: 'Night',
      version: 3,
      dataStatus: 2,
      id: 3,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-06T00:00:00',
      shift: 'Day',
      version: 3,
      dataStatus: 2,
      id: 4,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-06T00:00:00',
      shift: 'Night',
      version: 3,
      dataStatus: 2,
      id: 5,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-06T00:00:00',
      shift: 'Day',
      version: 1,
      dataStatus: 1,
      id: 6,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-04T00:00:00',
      shift: 'Night',
      version: 2,
      dataStatus: 4,
      id: 7,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-08T00:00:00',
      shift: 'Night',
      version: 3,
      dataStatus: 2,
      id: 8,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-08T00:00:00',
      shift: 'Night',
      version: 1,
      dataStatus: 3,
      id: 9,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-07T00:00:00',
      shift: 'Day',
      version: 2,
      dataStatus: 1,
      id: 10,
    },
    {
      reportId: 'SURV-P-2022-04-006',
      createdAt: '2022-06-09T00:00:00',
      shift: 'Night',
      version: 3,
      dataStatus: 1,
      id: 11,
    },
  ],
  recordsTotal: 11,
};

export const dummyVersionList = [
  {
    label: 'Ver 1',
    value: 1,
  },
  {
    label: 'Ver 2',
    value: 2,
  },
];

export const shiftList = [
  {
    value: 'DAY',
    label: 'Day',
  },
  {
    value: 'NIGHT',
    label: 'Night',
  },
];
