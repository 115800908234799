import {
  TabPage,
} from 'components';

const UserDetailWeb = ({
  tabArray = [],
  activeTabIndex = 0,
  setActiveTabIndex = () => { }
}) => {

  return (
    <>
      <div style={{ borderTop: "1px solid #E5E6E6" }}>
        <TabPage tabArray={tabArray} activeTab={activeTabIndex} setActive={setActiveTabIndex} />
      </div>
    </>
  );
};

export default UserDetailWeb;
