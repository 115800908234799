import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, get, post } from 'utils/request';
var _ = require('lodash');

export const initialState = {
  approval: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: '',
    recordsTotal: '',
    statusLogList: [],
    approvalModal: {
      id: null,
      level: 0,
      modul: null,
      userLevelList: [],
    },
    userOptions: [],
  },
};

export const getApprovalConfigurationList = createAsyncThunk(
  'approvalConfigurations',
  async (params) => {
    const response = await post(apiUrls.approvalConfig.concat(`/filter`), {
      columns: [
        {
          data: 'modul.name',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params?.modul ?? '',
          searchable: true,
        },
        {
          data: 'level',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params?.level ?? '',
          searchable: true,
        },
      ],
      order: params?.order ?? [],
      search: {
        regex: true,
        value: params.query || '',
      },
      draw: params?.page ? params.page : 1,
      length: params?.length ? params.length : -1,
    });
    return response?.data;
  }
);

export const getUserByModule = createAsyncThunk(
  'approvalConfigurations/users',
  async (modulAplication) => {
    const response = await get(`${apiUrls.userConfig}/filterByModule?moduleName=${modulAplication}`);
    return response?.data;
  }
);

export const getApprovalConfigurationLogs = createAsyncThunk(
  'approvalConfigurations/logs',
  async (params) => {
    const response = await get(`${apiUrls.approvalConfig}/logs`);
    return response?.data;
  }
);

export const getApprovalConfigurationModalData = createAsyncThunk(
  'approvalConfigurationModalData',
  async (id) => {
    const response = await get(`${apiUrls.approvalConfig}/${id}`);
    return response?.data;
  }
);

const approvalConfig = createSlice({
  name: 'APPROVAL_CONFIG',
  initialState,
  reducers: {
    onChangeTableInputDetail: (state, action) => {
      const payload = action.payload;
      console.log(payload.tableRowName);
      _.set(
        state.approval.records.detail[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },

    /*
      Approval Configuration
    */

    /**
     * * REMOVE DATA TABLE APPROVAL CONFIGURATION *
     * Todo: to remove row on table Approval Configuration page
     * @param action
     */
    onRemoveDataTableApprovalConfig: (state, action) => {
      const payload = action.payload;

      state.approval.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE APPROVAL CONFIGURATION *
     * Todo: to change table on Approval Configuration page
     * @param action
     */
    onChangeTableApprovalConfig: (state, action) => {
      const payload = action.payload;

      state.approval.records[payload.tableIndexRow][payload.tableRowName] =
        payload.value;
    },

    /*
      Approval Configuration Modal
    */
    /**
     * * RESET APPROVAL CONFIGURATION MODAL DATA*
     * Todo: to reset table data on Approval Configuration Modal
     * @param action
     */
    onAddApprovalConfigModal: (state, action) => {
      state.approval.approvalModal.id = null;
      state.approval.approvalModal.level = 1;
      state.approval.approvalModal.modul = null;
      state.approval.approvalModal.userLevelList = [];
    },

    /**
     * * ADD DATA TABLE APPROVAL CONFIGURATION MODAL *
     * Todo: to add table on Approval Configuration page
     * @param action
     */
    addRowApprovalConfigModal: (state, action) => {
      const userLevel = action.payload;

      state.approval.approvalModal?.userLevelList.unshift(userLevel);
    },

    /**
     * * REMOVE DATA TABLE APPROVAL CONFIGURATION MODAL *
     * Todo: to remove data table on Approval Configuration page
     * @param action
     */
    removeRowApprovalConfigModal: (state, action) => {
      const index = action.payload;

      state.approval.approvalModal?.userLevelList.splice(index, 1);
    },

    /**
     * * RESET APPROVAL CONFIGURATION MODAL DATA*
     * Todo: to reset table data on Approval Configuration Modal
     * @param action
     */
    onCloseApprovalConfigModal: (state, action) => {
      state.approval.approvalModal.id = null;
      state.approval.approvalModal.level = 0;
      state.approval.approvalModal.modul = {};
      state.approval.approvalModal.userLevelList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApprovalConfigurationList.pending, (state, action) => {
      state.approval.loading = true;
    });
    builder.addCase(getApprovalConfigurationList.fulfilled, (state, action) => {
      state.approval.records = action.payload.list;
      state.approval.recordsTotal = action.payload.recordsTotal;
      state.approval.loading = false;
    });
    builder.addCase(getApprovalConfigurationList.rejected, (state, action) => {
      state.approval.loading = false;
      state.approval.error = 'Invalid get data';
    });

    builder.addCase(getApprovalConfigurationLogs.pending, (state, action) => {
      state.approval.loading = true;
    });
    builder.addCase(getApprovalConfigurationLogs.fulfilled, (state, action) => {
      state.approval.statusLogList = action.payload;
    });
    builder.addCase(getApprovalConfigurationLogs.rejected, (state, action) => {
      state.approval.loading = false;
      state.approval.error = 'Invalid get data';
    });

    builder.addCase(
      getApprovalConfigurationModalData.pending,
      (state, action) => {
        state.approval.loading = true;
      }
    );
    builder.addCase(
      getApprovalConfigurationModalData.fulfilled,
      (state, action) => {
        const payload = action.payload;

        state.approval.approvalModal.id = payload.id;
        state.approval.approvalModal.level = payload.level;
        state.approval.approvalModal.modul = payload.modul;
        state.approval.approvalModal.userLevelList =
          payload.userLevelList || [];

        state.approval.loading = false;
      }
    );
    builder.addCase(
      getApprovalConfigurationModalData.rejected,
      (state, action) => {
        state.approval.loading = false;
        state.approval.error = 'Invalid get data';
      }
    );
    builder.addCase(getUserByModule.pending, (state, action) => {
      state.approval.loading = true;
    });
    builder.addCase(getUserByModule.fulfilled, (state, action) => {
      state.approval.userOptions = action?.payload?.list;
    });
    builder.addCase(getUserByModule.rejected, (state, action) => {
      state.approval.loading = false;
      state.approval.error = 'Invalid get data';
    });
  },
});
export const {
  onChangeTableInputDetail,
  onRemoveDataTableApprovalConfig,
  onChangeTableApprovalConfig,

  addRowApprovalConfigModal,
  removeRowApprovalConfigModal,
  onAddApprovalConfigModal,
  onCloseApprovalConfigModal,
} = approvalConfig.actions;
export const approvalConfigurationSelector = (state) =>
  state.approvalConfig.approval;
export const approvalConfigurationModalSelector = (state) =>
  state.approvalConfig.approval.approvalModal;

export default approvalConfig.reducer;
