export const roles = {
  ADMIN_BIB: 'Admin BIB',
  GENERAL_CREATOR_CONTRACTOR: 'General Creator Contractor',
  GENERAL_APPROVER_CONTRACTOR: 'General Approver Contractor',
  GENERAL_VIEWER_CONTRACTOR: 'General Viewer Contractor',
  GENERAL_CREATOR_BIB: 'General Creator BIB',
  GENERAL_APPROVER_BIB: 'General Approver BIB',
  GENERAL_VIEWER_BIB: 'General Viewer BIB',
  PIT_CREATOR_CONTRACTOR: 'PIT Creator Contractor',
  PIT_APPROVER_CONTRACTOR: 'PIT Approver Contractor',
  PIT_VIEWER_CONTRACTOR: 'PIT Viewer Contractor',
  PIT_CREATOR_BIB: 'PIT Creator BIB',
  PIT_APPROVER_BIB: 'PIT Approver BIB',
  PIT_VIEWER_BIB: 'PIT Viewer BIB',
  ROM_CREATOR_CONTRACTOR: 'ROM Creator Contractor',
  ROM_APPROVER_CONTRACTOR: 'ROM Approver Contractor',
  ROM_VIEWER_CONTRACTOR: 'ROM Viewer Contractor',
  ROM_CREATOR_BIB: 'ROM Creator BIB',
  ROM_APPROVER_BIB: 'ROM Approver BIB',
  ROM_VIEWER_BIB: 'ROM Viewer BIB',
  HAUL_CREATOR_CONTRACTOR: 'Haul Creator Contractor',
  HAUL_APPROVER_CONTRACTOR: 'Haul Approver Contractor',
  HAUL_VIEWER_CONTRACTOR: 'Haul Viewer Contractor',
  HAUL_CREATOR_BIB: 'Haul Creator BIB',
  HAUL_APPROVER_BIB: 'Haul Approver BIB',
  HAUL_VIEWER_BIB: 'Haul Viewer BIB',
  PORT_CREATOR_CONTRACTOR: 'Port Creator Contractor',
  PORT_APPROVER_CONTRACTOR: 'Port Approver Contractor',
  PORT_VIEWER_CONTRACTOR: 'Port Viewer Contractor',
  PORT_CREATOR_BIB: 'Port Creator BIB',
  PORT_APPROVER_BIB: 'Port Approver BIB',
  PORT_VIEWER_BIB: 'Port Viewer BIB',
  CREATOR_CONTRACTOR: 'Creator Contractor',
  CREATOR_BIB_OFFSHORE: 'Creator BIB Offshore',
  APPROVER_BIB: 'Approver BIB',
  CREATOR_BIB_MARKETING: 'Creator BIB Marketing',
  CAPTAIN_CONTRACTOR: 'Captain Contractor',
  PIT_PROD_CONTRACTOR: 'PIT Production Contractor',
  PIT_PROD_BIB: 'PIT Production BIB',
  PIT_SURVEY_CONTRACTOR: 'PIT Survey Contractor',
  PIT_SURVEY_BIB: 'PIT Survey BIB',
  SUPER_ADMIN: 'Super Admin',
};

export const portBibCreatorApprover = [
  roles.ADMIN_BIB,
  roles.PORT_CREATOR_BIB,
  roles.PORT_APPROVER_BIB,
];

export const haulBibRoles = [
  roles.ADMIN_BIB,
  roles.HAUL_CREATOR_BIB,
  roles.HAUL_APPROVER_BIB,
  roles.HAUL_VIEWER_BIB,
];

export const pitContractorRoles = [
  roles.ADMIN_BIB,
  roles.PIT_CREATOR_CONTRACTOR,
  roles.PIT_APPROVER_CONTRACTOR,
  roles.PIT_APPROVER_BIB,
];

export const generalRoles = [
  roles.ADMIN_BIB,
  roles.GENERAL_CREATOR_CONTRACTOR,
  roles.GENERAL_APPROVER_CONTRACTOR,
  roles.GENERAL_VIEWER_CONTRACTOR,
  roles.GENERAL_CREATOR_BIB,
  roles.GENERAL_APPROVER_BIB,
  roles.GENERAL_VIEWER_BIB,
];

export const generalRolesAdmin = [
  roles.ADMIN_BIB,
  roles.GENERAL_APPROVER_CONTRACTOR,
  roles.GENERAL_CREATOR_BIB,
  roles.GENERAL_APPROVER_BIB,
  roles.GENERAL_VIEWER_BIB,
];

export const generalRolesContractor = [
  roles.ADMIN_BIB,
  roles.GENERAL_CREATOR_CONTRACTOR,
  roles.GENERAL_VIEWER_CONTRACTOR,
];

export const haulRoles = [
  roles.ADMIN_BIB,
  roles.HAUL_CREATOR_CONTRACTOR,
  roles.HAUL_APPROVER_CONTRACTOR,
  roles.HAUL_VIEWER_CONTRACTOR,
  roles.HAUL_CREATOR_BIB,
  roles.HAUL_APPROVER_BIB,
  roles.HAUL_VIEWER_BIB,
];

export const haulRolesAdmin = [
  roles.ADMIN_BIB,
  roles.HAUL_APPROVER_CONTRACTOR,
  roles.HAUL_CREATOR_BIB,
  roles.HAUL_APPROVER_BIB,
  roles.HAUL_VIEWER_BIB,
];

export const haulRolesContractor = [
  roles.ADMIN_BIB,
  roles.HAUL_CREATOR_CONTRACTOR,
  roles.HAUL_VIEWER_CONTRACTOR,
];

export const romRoles = [
  roles.ADMIN_BIB,
  roles.ROM_CREATOR_CONTRACTOR,
  roles.ROM_APPROVER_CONTRACTOR,
  roles.ROM_VIEWER_CONTRACTOR,
  roles.ROM_CREATOR_BIB,
  roles.ROM_APPROVER_BIB,
  roles.ROM_VIEWER_BIB,
];

export const romRolesAdmin = [
  roles.ADMIN_BIB,
  roles.ROM_APPROVER_CONTRACTOR,
  roles.ROM_CREATOR_BIB,
  roles.ROM_APPROVER_BIB,
  roles.ROM_VIEWER_BIB,
];

export const romRolesContractor = [
  roles.ADMIN_BIB,
  roles.ROM_CREATOR_CONTRACTOR,
  roles.ROM_VIEWER_CONTRACTOR,
];

export const pitRoles = [
  roles.ADMIN_BIB,
  roles.PIT_CREATOR_CONTRACTOR,
  roles.PIT_APPROVER_CONTRACTOR,
  roles.PIT_VIEWER_CONTRACTOR,
  roles.PIT_CREATOR_BIB,
  roles.PIT_APPROVER_BIB,
  roles.PIT_VIEWER_BIB,
];

export const pit2Roles = [
  roles.ADMIN_BIB,
  roles.PIT_CREATOR_CONTRACTOR,
  roles.PIT_APPROVER_CONTRACTOR,
  roles.PIT_CREATOR_BIB,
  roles.PIT_APPROVER_BIB,
  roles.PIT_VIEWER_BIB,
  roles.PIT_PROD_CONTRACTOR,
  roles.PIT_PROD_BIB,
  roles.PIT_SURVEY_CONTRACTOR,
  roles.PIT_SURVEY_BIB,
];

export const pitBib2Roles = [
  roles.ADMIN_BIB,
  roles.PIT_CREATOR_BIB,
  roles.APPROVER_BIB,
  roles.PIT_VIEWER_BIB,
];

export const pitBibRoles = [
  roles.ADMIN_BIB,
  roles.PIT_CREATOR_BIB,
  roles.PIT_APPROVER_BIB,
  roles.PIT_VIEWER_BIB,
];

export const rom2Roles = [
  roles.ADMIN_BIB,
  roles.ROM_CREATOR_CONTRACTOR,
  roles.ROM_APPROVER_CONTRACTOR,
  roles.ROM_APPROVER_BIB,
  roles.ROM_VIEWER_BIB,
];

export const portRoles = [
  roles.ADMIN_BIB,
  roles.PORT_CREATOR_BIB,
  roles.PORT_APPROVER_BIB,
  roles.PORT_VIEWER_BIB,
];

export const offshoreMarketingCreatorRoles = [
  roles.ADMIN_BIB,
  roles.CREATOR_BIB_OFFSHORE,
  roles.APPROVER_BIB,
  roles.CREATOR_BIB_MARKETING,
];

export const captainRoles = [roles.ADMIN_BIB, roles.CAPTAIN_CONTRACTOR];

export const creatorContractorApproverBib = [
  roles.ADMIN_BIB,
  roles.CREATOR_CONTRACTOR,
  roles.APPROVER_BIB,
];

export const creatorBibRoles = [
  roles.ADMIN_BIB,
  roles.CREATOR_BIB_OFFSHORE,
  roles.CREATOR_BIB_MARKETING,
];

export const dashboardRoles = [
  roles.ADMIN_BIB,
  roles.GENERAL_CREATOR_BIB,
  roles.GENERAL_APPROVER_BIB,
  roles.GENERAL_VIEWER_BIB,
  roles.PIT_CREATOR_BIB,
  roles.PIT_APPROVER_BIB,
  roles.PIT_VIEWER_BIB,
  roles.ROM_CREATOR_BIB,
  roles.ROM_APPROVER_BIB,
  roles.ROM_VIEWER_BIB,
  roles.HAUL_CREATOR_BIB,
  roles.HAUL_APPROVER_BIB,
  roles.HAUL_VIEWER_BIB,
  roles.PORT_CREATOR_BIB,
  roles.PORT_APPROVER_BIB,
  roles.PORT_VIEWER_BIB,
];

export default roles;
