import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, get, post } from 'utils/request';
var _ = require('lodash');

export const initialState = {
  role: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: []
  }
}

export const getRoleList = createAsyncThunk('roles', async () => {
  const response = await get(apiUrls.roleConfig);

  return response?.data;
});

const role = createSlice({
  name: "MODUL",
  initialState,
  reducers: {
    onChangeTableInputDetail: (state, action) => {
      const payload = action.payload;
      console.log(payload.tableRowName);
      _.set(state.role.records.detail[payload.tableIndexRow], payload.tableRowName, parseInt(payload.value))
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRoleList.pending, (state, action) => {
      state.role.loading = true;
    });
    builder.addCase(getRoleList.fulfilled, (state, action) => {
      state.role.records = action.payload;
      state.role.recordsTotal = action.payload.length;
    });
    builder.addCase(getRoleList.rejected, (state, action) => {
      state.role.loading = false;
      state.role.error = 'Invalid get data';
    });
  },
});

export const {
  onChangeTableInputDetail
} = role.actions;
export const roleSelector = (state) => state.role.role;

export default role.reducer;
