import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getAPIEndpoint, apiUrls, deleteRequest, get, patch, post } from 'utils/request';
import { DATASOURCE_VALUE, genReportId, getUsername } from 'utils/helper';

const reportId = "HP-DSR";
const defaultValue = {
  id: null,
  reportId: genReportId(reportId),
  shift: 'Day',
  detail: [],
  version: 1,
  activeVersionFlag: true,
  dataStatus: 1,
  statusLogList: [],
  documentList: [],
  createdAt: new Date().toISOString(),
};

export const initialState = {
  fleetPlanHaulingList: {
    records: [],
    loading: false,
    error: null,
    loading: false,
    error: "",
    recordsTotal: "",
  },
  fleetPlanHaulingDetail: {
    records: null,
    response: defaultValue,
    loading: false,
  },
  equipmentList: [],
  baDistanceList: [],
  versionList: [],
};

export const FLEET_PLAN_HAULING_REDUCER = 'FLEET_PLAN_HAULING_REDUCER';

export const fetchFleetPlanHaulingList = createAsyncThunk(
  'fleet-plan-hauling/list',
  async (params) => {
    const response = await post(`${apiUrls.fleetPlanHaul}filter`,
      {
        "columns": [
          {
            "data": "id",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": false
          },
          {
            "data": "reportId",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "dataStatus",
            "orderable": true,
            "search": {
              "regex": params.dataStatus === DATASOURCE_VALUE.waitingApproval || false,
              "value": ""
            },
            "searchValue": params.dataStatus || "",
            "searchable": true
          },
          {
            "data": "createdAt",
            "orderable": true,
            "search": {
              "regex": true,
              "value": ""
            },
            "searchValue": params.date || "",
            "searchable": true
          },
          {
            "data": "version",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.version || "",
            "searchable": true
          },
          {
            "data": "shift",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.shift || "",
            "searchable": true
          }
        ],
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params?.recordLength ? params.recordLength : -1,
        "order": [],
        "search": {
          "regex": false,
          "value": params.query || ""
        },
        "start": 0
      });
    return response?.data;
  }
);

export const fetchFleetPlanHaulingDetail = createAsyncThunk(
  'fleet-plan-hauling/detail',
  async (id) => {
    const response = await get(`${apiUrls.fleetPlanHaul}${id}`);
    return response.data;
  }
);

export const getVersionList = createAsyncThunk(
  'fleet-plan-hauling/versionList',
  async (param) => {
    const { reportId } = param;
    const response = await get(
      `${apiUrls.fleetPlanHaul}${reportId}/versions`
    );
    return response.data;
  }
);

export const setActivatedVersion = createAsyncThunk(
  'fleet-plan-hauling/setActivatedVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await patch(
      `${apiUrls.fleetPlanHaul}${reportId}/versions/${version}?userName=${getUsername()}`
    );
    return response.data;
  }
);

export const getByVersion = createAsyncThunk(
  'fleet-plan-hauling/getByVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await get(
      `${apiUrls.fleetPlanHaul}/${reportId}/versions/${version}`
    );
    return response.data;
  }
);

export const deleteData = createAsyncThunk(
  'fleet-plan-rom/delete',
  async (id) => {
    const details = await deleteRequest(getAPIEndpoint("fleet-plan-haul", "v1", `/${id}`), {});
    return details.data;
  }
);

export const addData = createAsyncThunk(
  'fleet-plan-haul/add',
  async (params) => {
    const { data } = JSON.parse(localStorage.getItem('token'));
    const details = await post(getAPIEndpoint("fleet-plan-haul", "v1", `/add?userName=${data.userName}`), params, { errorMessage: true });
    const versionList = await get(getAPIEndpoint("fleet-plan-haul", "v1", `/${details.data.reportId}/versions`));
    return {
      details: details.data,
      versionList: versionList.data
    };
  }
);
export const saveData = createAsyncThunk(
  'fleet-plan-haul/save',
  async (params) => {
    const details = await post(getAPIEndpoint("fleet-plan-haul", "v1", `/save`), params);
    const versionList = await get(getAPIEndpoint("fleet-plan-haul", "v1", `/${details.data.reportId}/versions`));
    return {
      details: details.data,
      versionList: versionList.data
    };
  }
);

export const submitData = createAsyncThunk(
  'fleet-plan-haul/submit',
  async (params) => {
    const details = await post(getAPIEndpoint("fleet-plan-haul", "v1", `/submit`), params);
    const versionList = await get(getAPIEndpoint("fleet-plan-haul", "v1", `/${details.data.reportId}/versions`));
    return {
      details: details.data,
      versionList: versionList.data
    };
  }
);

export const approveData = createAsyncThunk(
  'fleet-plan-haul/approve',
  async (params) => {
    const details = await post(getAPIEndpoint("fleet-plan-haul", "v1", `/approve`), params);
    return {
      details: details.data
    };
  }
);
export const rejectData = createAsyncThunk(
  'fleet-plan-haul/reject',
  async (params) => {
    const details = await post(getAPIEndpoint("fleet-plan-haul", "v1", `/reject`), params);
    return {
      details: details.data
    };
  }
);

export const getEquipmentTypes = createAsyncThunk(
  'fleet-plan-haul/masterEquipment',
  async (params) => {
    const filter = {
      "columns": [
        {
          "data": "contractor",
          "orderable": true,
          "search": {
            "regex": true,
            "value": ""
          },
          "searchValue": params.contractor || '',
          "searchable": true
        },
        {
          "data": "dataStatus",
          "orderable": true,
          "search": {
            "regex": false,
            "value": "",
          },
          "searchValue": 3,
          "searchable": true
        },
        {
          "data": "equipment.type",
          "orderable": true,
          "search": {
            "regex": true,
            "value": ""
          },
          "searchValue": "DT|SDT|DDT",
          "searchable": true
        },
      ],
      "search": {
        "regex": false,
        "value": ""
      },
      "draw": 1,
      "length": -1,
      "start": 0,
    }

    const equipmentsHaul = await post(`${apiUrls.masterData}/equipment-haul/filter`, filter);
    const equipments = equipmentsHaul.data.list;
    let dataList = [];

    equipments.forEach((item, i) => {
      const isExist = dataList.findIndex((x) => x.equipment.type === item.equipment.type && x.equipment.contractor === item.equipment.contractor)
      const dataItem = {
        ...item,
        value: item.equipment.type,
        label: item.equipment.type,
        quantity: 1,
      };

      if (isExist === -1) dataList.push(dataItem);
      else if (isExist > 0 || dataList.length > 0) dataList[isExist].quantity = dataList[isExist].quantity + 1;
    });

    return dataList;
  }
);

export const uploadFile = createAsyncThunk(
  'fleet-plan-haul/uploadFile',
  async ({ id, file }) => {
    let formData = new FormData();
    let newFile = new File([file], file.name, { type: file.type });
    formData.append('file', newFile);

    const response = await post(getAPIEndpoint("fleet-plan-haul", "v1", `/${id}/files`), formData, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);

export const deleteFile = createAsyncThunk(
  'fleet-plan-haul/deleteFile',
  async ({ id, file }) => {
    const response = await deleteRequest(getAPIEndpoint("fleet-plan-haul", "v1", `/${id}/files/${file.name}`), {});
    return response.data;
  }
)

export const fetchBaDistanceHaulingList = createAsyncThunk(
  'ba-distance-hauling/list',
  async () => {
    const response = await post(`${apiUrls.distanceHauling}/filter`, {
      columns: [
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: true,
            value: 3,
          },
          searchValue: '',
          searchable: true,
        },
      ],
      draw: 1,
      length: 1,
      start: 0,
    });

    const baDistance = response.data.list[0];
    const baDistanceDetail = await get(`${apiUrls.distanceHauling}/${baDistance?.id}`);

    return baDistanceDetail.data.distanceList || {}
  }
);

const fleetPlanHaulingSlice = createSlice({
  name: FLEET_PLAN_HAULING_REDUCER,
  initialState,
  reducers: {
    clearFleetDetail: (state, action) => {
      state.fleetPlanHaulingDetail = {
        response: defaultValue,
      }
    },

    addDataTableFleet: (state, action) => {
      state.fleetPlanHaulingDetail?.records.unshift(action.payload);
    },

    onEditFleet: (state, action) => {
      const payload = action.payload;
      let fieldName;
      let fieldTotalName;
      let total = 0;

      if (payload.tableRowName == "romA1") {
        fieldName = 'detailROMA1';
        fieldTotalName = 'totalRomA1';
      }
      else if (payload.tableRowName == "romB1") {
        fieldName = 'detailROMB1';
        fieldTotalName = 'totalRomB1';
      }
      else if (payload.tableRowName == "romB2") {
        fieldName = 'detailROMB2';
        fieldTotalName = 'totalRomB2';
      }
      else if (payload.tableRowName == "pit1") {
        fieldName = 'detailPIT1';
        fieldTotalName = 'totalPit1';
      }

      if (payload.calculateField && !isNaN(parseInt(payload.value))) {
        const bkValue = state.fleetPlanHaulingDetail.records[payload.tableIndexRow][fieldName][payload.index];
        const bkTotalValue = state.fleetPlanHaulingDetail.records[payload.tableIndexRow][fieldTotalName];

        state.fleetPlanHaulingDetail.records[payload.tableIndexRow][fieldName][payload.index] = parseInt(payload.value);

        const totalArr = current(state.fleetPlanHaulingDetail.records[payload.tableIndexRow][fieldName]);

        totalArr.map((e) => {
          return total += e ? parseInt(e) : 0
        })

        state.fleetPlanHaulingDetail.records[payload.tableIndexRow][fieldTotalName] = total;

        const totalList = current(state.fleetPlanHaulingDetail.records[payload.tableIndexRow]);

        const quantity = totalList.totalRomA1 + totalList.totalRomB1 + totalList.totalRomB2 + totalList.totalPit1;

        if (quantity <= 100) {
          state.fleetPlanHaulingDetail.records[payload.tableIndexRow]['quantity'] = `${quantity}/100`;
        }
        else if (quantity > 100) {
          state.fleetPlanHaulingDetail.records[payload.tableIndexRow][fieldName][payload.index] = bkValue;
          state.fleetPlanHaulingDetail.records[payload.tableIndexRow][fieldTotalName] = bkTotalValue;
        }
      }
      else {
        state.fleetPlanHaulingDetail.records[payload.tableIndexRow][payload.tableRowName] = payload.value;
      }
    },

    onRemoveDataTableFleet: (state, action) => {
      const payload = action.payload;

      state.fleetPlanHaulingDetail.records.splice(payload.tableIndexRow, 1);
    },

    onAddFileFleet: (state, action) => {
      const payload = action.payload;
      if (state.fleetPlanHaulingDetail.documentList) {
        state.fleetPlanHaulingDetail.documentList.unshift(payload);
      }
      else {
        var arr = [payload]
        state.fleetPlanHaulingDetail.documentList = arr
      }
    },

    onRemoveFileFleet: (state, action) => {
      state.fleetPlanHaulingDetail.documentList.splice(0, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFleetPlanHaulingList.pending, (state, action) => {
      state.fleetPlanHaulingList.loading = true;
    });
    builder.addCase(fetchFleetPlanHaulingList.fulfilled, (state, action) => {
      state.fleetPlanHaulingList.records = action.payload.list;
      state.fleetPlanHaulingList.recordsTotal = action.payload.recordsTotal;
      state.fleetPlanHaulingList.loading = false;
    });
    builder.addCase(fetchFleetPlanHaulingList.rejected, (state, action) => {
      state.fleetPlanHaulingList.loading = false;
      state.fleetPlanHaulingList.error = action.error;
    });

    builder.addCase(fetchFleetPlanHaulingDetail.pending, (state, action) => {
      state.fleetPlanHaulingDetail.loading = true;
    });
    builder.addCase(fetchFleetPlanHaulingDetail.fulfilled, (state, action) => {
      state.fleetPlanHaulingDetail.records = action.payload.distanceList;
      state.fleetPlanHaulingDetail.response = action.payload;
      state.fleetPlanHaulingDetail.loading = false;
    });
    builder.addCase(fetchFleetPlanHaulingDetail.rejected, (state, action) => {
      state.fleetPlanHaulingDetail.loading = false;
      state.baDistanceHaulingList.error = action.error;
    });
    builder.addCase(getEquipmentTypes.fulfilled, (state, action) => {
      state.equipmentList = action.payload;
    })
    builder.addCase(fetchBaDistanceHaulingList.fulfilled, (state, action) => {
      state.baDistanceList = action.payload;
    })
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList = action.payload;
    });
  },
});

export const fleetPlanHaulingSelector = (state) => state.fleetPlanHauling.fleetPlanHaulingList;
export const fleetPlanHaulingDetailSelector = (state) => state.fleetPlanHauling.fleetPlanHaulingDetail;
export const equipmentTypeSelector = (state) => state.fleetPlanHauling.equipmentList;
export const baDistanceSelector = (state) => state.fleetPlanHauling.baDistanceList;
export const fleetVersionListSelector = (state) => state.fleetPlanHauling.versionList;

export const {
  clearFleetDetail,
  onEditFleet,
  addDataTableFleet,
  onRemoveDataTableFleet,
  onAddFileFleet,
  onRemoveFileFleet
} = fleetPlanHaulingSlice.actions;

export default fleetPlanHaulingSlice.reducer;
