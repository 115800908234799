import {
  MemberTable,
  TabPage,
} from 'components';

const UserDetailMobile = ({
  tabArrayMobile = [],
  activeTabIndex = 0,
  loading = false,
  entityActive = "CrsuhingActual",
  setActiveTabIndex = () => { },
  setLoading = () => { },
}) => {

  return (
    <>
      <div style={{ borderTop: "1px solid #E5E6E6" }}>
        <TabPage tabArray={tabArrayMobile} activeTab={activeTabIndex} setActive={setActiveTabIndex} />
      </div>
      <MemberTable
        loading={loading}
        entityActive={entityActive}
        setLoading={setLoading}
      />
    </>
  );
};

export default UserDetailMobile;
