import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getUsername } from 'utils/helper';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';

export const initialState = {
  requestMasterDataList: {
    records: [],
    loading: false,
    error: null,
    loading: false,
    error: "",
    recordsTotal: "",
  },
  requestMasterDataDetail: {
    records: [],
    response: {},
    loading: false,
    error: null,
    statusLogList: [],
    documentList: [],
  },
};

export const REQUEST_MASTER_DATA_REDUCER = 'REQUEST_MASTER_DATA_REDUCER';

export const fetchRequestMasterDataList = createAsyncThunk(
  'request-sublocation/list',
  async (params) => {
    const response = await post(`${apiUrls.masterDataRequest}request/sublocations/filter`,
      {
        "columns": [
          {
            "data": "id",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": false
          },
          {
            "data": "reportId",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "contractor",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params?.contractor,
            "searchable": true
          },
          {
            "data": "dataStatus",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.dataStatus || "",
            "searchable": true
          },
          {
            "data": "createdAt",
            "orderable": true,
            "search": {
              "regex": true,
              "value": ""
            },
            "searchValue": params.date || "",
            "searchable": true
          },
          {
            "data": "bibNotes",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
        ],
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params?.recordLength ? params.recordLength : -1,
        "search": {
          "regex": false,
          "value": params.query || ""
        },
        "start": 0
      });
    return response?.data;
  }
);

export const fetchRequestMasterDataDetail = createAsyncThunk(
  'request-sublocation/detail',
  async (param) => {
    const response = await get(`${apiUrls.masterDataRequest}request/sublocations/${param}`);
    return response.data;
  }
);

export const handleAdminRequestSubLocation = createAsyncThunk(
  'request-sublocation/approve',
  async (param) => {
    const { action, object, id } = param
    let response

    try {
      response = await put(`${apiUrls.masterDataRequest}request/sublocations/${id}/${action}?userName=${getUsername()}`, object);
    }
    catch (e) {
      return e.data;
    }

    return response.data;
  }
);

const requestMasterDataSlice = createSlice({
  name: REQUEST_MASTER_DATA_REDUCER,
  initialState,
  reducers: {
    clearRequestSubLocation: (state, action) => {
      state.requestMasterDataDetail = {
        records: [],
        response: {},
        loading: false,
        error: null,
        statusLogList: [],
        documentList: [],
      }
    },

    addDataTableSubLocationBIB: (state, action) => {
      const payload = action.payload;

      state.requestMasterDataDetail?.records.unshift(payload);
    },

    /**
    * * REMOVE FIRST DATA TABLE SUBLOCATION *
    * Todo: to remove first row on table master data sublocation page
    * @param action
    */
    removeFirstDataTableSubLocationBIB: (state, action) => {
      state.requestMasterDataDetail?.records.shift();
    },

    /**
    * * REMOVE DATA TABLE SUBLOCATION *
    * Todo: to remove row on table master data sublocation page
    * @param action
    */
    onRemoveDataTableSubLocationBIB: (state, action) => {
      const payload = action.payload;

      state.requestMasterDataDetail.records.splice(payload.tableIndexRow, 1);
    },

    /**
    * * ONCHANGE TABLE SUBLOCATION *
    * Todo: to change table on master data sublocation page
    * @param action
    */
    onChangeTableSubLocationBIB: (state, action) => {
      const payload = action.payload;

      if (state.requestMasterDataDetail.records[payload.tableIndexRow][payload.tableRowName]) {
        state.requestMasterDataDetail.records[payload.tableIndexRow][payload.tableRowName] = payload.value;
      }
      else {
        state.requestMasterDataDetail.records[payload.tableIndexRow]["bibNotes"] = payload.value;
      }
    },

    /**
    * * ONREVERT TABLE SUBLOCATION *
    * Todo: to change table on master data sublocation page
    * @param action
    */
    onRevertTableSubLocationBIB: (state, action) => {
      const payload = action.payload;

      state.requestMasterDataDetail.revert = state.requestMasterDataDetail.records;
      if (!payload) state.requestMasterDataDetail.onRevert = true;
      else state.requestMasterDataDetail.onRevert = false;
    },

    /**
    * * ONREVERT TABLE SUBLOCATION *
    * Todo: to change table on master data sublocation page
    * @param action
    */
    onRevertChangeTableSubLocationBIB: (state, action) => {
      state.requestMasterDataDetail.records = state.requestMasterDataDetail.revert;

      state.requestMasterDataDetail.revert = [];
      state.requestMasterDataDetail.onRevert = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRequestMasterDataList.pending, (state, action) => {
      state.requestMasterDataList.loading = true;
    });
    builder.addCase(fetchRequestMasterDataList.fulfilled, (state, action) => {
      state.requestMasterDataList.records = action.payload.list;
      state.requestMasterDataList.recordsTotal = action.payload.recordsTotal;
      state.requestMasterDataList.loading = false;
    });
    builder.addCase(fetchRequestMasterDataList.rejected, (state, action) => {
      state.requestMasterDataList.loading = false;
      state.requestMasterDataList.error = action.error;
    });

    builder.addCase(fetchRequestMasterDataDetail.pending, (state, action) => {
      state.requestMasterDataDetail.loading = true;
    });
    builder.addCase(fetchRequestMasterDataDetail.fulfilled, (state, action) => {
      state.requestMasterDataDetail.records = action.payload.list;
      state.requestMasterDataDetail.response = action.payload;
      state.requestMasterDataDetail.loading = false;
    });
    builder.addCase(fetchRequestMasterDataDetail.rejected, (state, action) => {
      state.requestMasterDataDetail.loading = false;
      state.requestMasterDataList.error = action.error;
    });
  },
});
export const requestMasterDataSelector = (state) => state.requestSubLocation.requestMasterDataList;
export const requestMasterDataDetailSelector = (state) => state.requestSubLocation.requestMasterDataDetail;

export const {
  clearRequestSubLocation,
  onChangeTableSubLocationBIB,
  addDataTableSubLocationBIB,
  removeFirstDataTableSubLocationBIB,
  onRevertChangeTableSubLocationBIB,
  onRevertTableSubLocationBIB,
  onRemoveDataTableSubLocationBIB,
} = requestMasterDataSlice.actions;

export default requestMasterDataSlice.reducer;
