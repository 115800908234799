import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, patch, post } from 'utils/request';

export const initialState = {
  distanceWasteRemovalList: {
    baDistanceResponseList: [],
    recordsTotal: 0,
    loading: false,
    error: null,
  },
  distanceWasteRemovalDetail: {
    data: {},
    loading: false,
    error: null,
  },
};
export const DISTANCE_WASTE_REMOVAL_REDUCER = 'DISTANCE_WASTE_REMOVAL_REDUCER';
export const fetchDistanceWasteRemovalList = createAsyncThunk(
  'distance-waste-removal/list',
  async (params) => {
    const { filter } = params;
    const response = await post(
      `${apiUrls.baDistanceWasteRemoval}/filter`,
      filter
    );
    return response?.data;
  }
);

export const fetchDistanceWasteRemovalDetail = createAsyncThunk(
  'Distance-waste-removal/detail',
  async (param) => {
    const { id } = param;
    const response = await get(`${apiUrls.baDistanceWasteRemoval}/${id}`);
    return response.data;
  }
);
export const addDistanceWasteRemoval = createAsyncThunk(
  'Distance-waste-removal/add',
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.baDistanceWasteRemoval}/add`, data);
    return response.data;
  }
);
export const updateDistanceWasteRemoval = createAsyncThunk(
  'Distance-waste-removal/update',
  async (param) => {
    const { updateData } = param;
    const response = await post(
      `${apiUrls.baDistanceWasteRemoval}/update`,
      updateData
    );
    return response.data;
  }
);
export const deleteDistanceWasteRemoval = createAsyncThunk(
  'Distance-waste-removal/delete',
  async (param) => {
    const { id } = param;
    const response = await deleteRequest(
      `${apiUrls.baDistanceWasteRemoval}/delete/${id}`
    );
    return response.data;
  }
);
export const setDistanceWasteRemovalStatus = createAsyncThunk(
  'Distance-waste-removal/setStatus',
  async (param) => {
    const { data } = param;
    const response = await patch(
      `${apiUrls.baDistanceWasteRemoval}/set-status`,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const DistanceWasteRemovalSubmit = createAsyncThunk(
  'Distance-waste-removal/submit',
  async (param) => {
    const { id } = param;
    const response = await post(`${apiUrls.baDistanceWasteRemoval}/submit`, {
      id,
    });
    return response.data;
  }
);

const distanceWasteRemovalSlice = createSlice({
  name: DISTANCE_WASTE_REMOVAL_REDUCER,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDistanceWasteRemovalList.pending, (state, action) => {
      state.distanceWasteRemovalList.loading = true;
      state.distanceWasteRemovalList.error = null;
    });
    builder.addCase(
      fetchDistanceWasteRemovalList.fulfilled,
      (state, action) => {
        const { recordsTotal, baDistanceResponseList } = action.payload;
        state.distanceWasteRemovalList.loading = false;
        state.distanceWasteRemovalList.baDistanceResponseList =
          baDistanceResponseList;
        state.distanceWasteRemovalList.recordsTotal = recordsTotal;
      }
    );
    builder.addCase(fetchDistanceWasteRemovalList.rejected, (state, action) => {
      state.distanceWasteRemovalList.loading = false;
      state.distanceWasteRemovalList.error = action.error;
    });
    builder.addCase(
      fetchDistanceWasteRemovalDetail.pending,
      (state, action) => {
        state.distanceWasteRemovalDetail.loading = true;
        state.distanceWasteRemovalDetail.error = null;
      }
    );
    builder.addCase(
      fetchDistanceWasteRemovalDetail.fulfilled,
      (state, action) => {
        state.distanceWasteRemovalDetail.loading = false;
        state.distanceWasteRemovalDetail.data = action.payload;
      }
    );
    builder.addCase(
      fetchDistanceWasteRemovalDetail.rejected,
      (state, action) => {
        state.distanceWasteRemovalDetail.loading = false;
        state.distanceWasteRemovalDetail.error = action.error;
      }
    );
  },
});

export const distanceWasteRemovalSelector = (state) =>
  state.distanceWasteRemoval;
export default distanceWasteRemovalSlice.reducer;
