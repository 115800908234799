import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCompany } from 'utils/helper';
import { apiUrls, get, post } from 'utils/request';

export const initialState = {
  requestMasterDataList: {
    records: [],
    loading: false,
    error: "",
    recordsTotal: "",
    dataStatus: 1,
  },

  requestMasterDataDetail: {
    records: [],
    response: {},
    loading: false,
    error: null,
    statusLogList: [],
    documentList: [],
  },
};

export const REQUEST_MASTER_DATA_REDUCER = 'REQUEST_MASTER_DATA_REDUCER';

export const fetchRequestMasterDataList = createAsyncThunk(
  'request-sublocation-contractor/list',
  async (params) => {
    const response = await post(`${apiUrls.masterDataRequest}sublocations/filter`,
      {
        "columns": [
          {
            "data": "id",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": false
          },
          {
            "data": "contractor",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": getCompany(),
            "searchable": true
          },
          {
            "data": "area",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.area || "",
            "searchable": true
          },
          {
            "data": "location",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.location || "",
            "searchable": true
          },
          {
            "data": "name",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.name || "",
            "searchable": true
          },
          {
            "data": "dataStatus",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.dataStatus || "",
            "searchable": false
          },
          {
            "data": "entityStatus",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.status || "",
            "searchable": true
          },
          {
            "data": "createdAt",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.date || "",
            "searchable": true
          },
          {
            "data": "contractorNotes",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "bibNotes",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
        ],
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params?.recordLength ? params.recordLength : -1,
        "search": {
          "regex": false,
          "value": params.query || ""
        },
        "start": 0
      });
    return response?.data;
  }
);

export const getMasterDataSublocationLogs = createAsyncThunk('request-sublocation-contractor/sublocations/logs', async (params) => {
  const response = await get(`${apiUrls.masterDataRequest}sublocations/logs`);
  return response?.data;
});

export const getMasterDataSublocationDeleteCheck = createAsyncThunk('request-sublocation-contractor/sublocations/delete-check', async (params) => {
  const response = await get(`${apiUrls.masterData}/sublocations/request/${params}/delete-check`);
  return response?.data;
});

const requestMasterDataSlice = createSlice({
  name: REQUEST_MASTER_DATA_REDUCER,
  initialState,
  reducers: {

    /*
      SubLocation
    */
    /**
    * * ADD DATA TABLE SUBLOCATION *
    * Todo: to add table on master data sublocation page
    * @param action
    */
    addDataTableSubLocation: (state, action) => {
      const payload = action.payload;

      state.requestMasterDataList?.records.unshift(payload);
    },

    /**
    * * REMOVE FIRST DATA TABLE SUBLOCATION *
    * Todo: to remove first row on table master data sublocation page
    * @param action
    */
    removeFirstDataTableSubLocation: (state, action) => {
      state.requestMasterDataList?.records.shift();
    },

    /**
    * * REMOVE DATA TABLE SUBLOCATION *
    * Todo: to remove row on table master data sublocation page
    * @param action
    */
    onRemoveDataTableSubLocation: (state, action) => {
      const payload = action.payload;

      if (payload.isNew) state.requestMasterDataList.records.splice(payload.tableIndexRow, 1);
      else {
        if (payload.revert) state.requestMasterDataList.records[payload.tableIndexRow]['dataStatus'] = 1;
        else state.requestMasterDataList.records[payload.tableIndexRow]['dataStatus'] = 0;
      }
    },

    /**
    * * ONCHANGE TABLE SUBLOCATION *
    * Todo: to change table on master data sublocation page
    * @param action
    */
    onChangeTableSubLocation: (state, action) => {
      const payload = action.payload;

      if (payload.tableRowName == "area") {
        state.requestMasterDataList.records[payload.tableIndexRow]['location'] = {};
        state.requestMasterDataList.records[payload.tableIndexRow]['location'][payload.tableRowName] = payload.value;
      }
      else state.requestMasterDataList.records[payload.tableIndexRow][payload.tableRowName] = payload.value;
    },

    /**
    * * ONREVERT TABLE SUBLOCATION *
    * Todo: to change table on master data sublocation page
    * @param action
    */
    onRevertTableSubLocation: (state, action) => {
      const payload = action.payload;

      state.requestMasterDataList.revert = state.requestMasterDataList.records;
      if (!payload) state.requestMasterDataList.onRevert = true;
      else state.requestMasterDataList.onRevert = false;
    },

    /**
    * * ONREVERT TABLE SUBLOCATION *
    * Todo: to change table on master data sublocation page
    * @param action
    */
    onRevertChangeTableSubLocation: (state, action) => {
      state.requestMasterDataList.records = state.requestMasterDataList.revert;

      state.requestMasterDataList.revert = [];
      state.requestMasterDataList.onRevert = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRequestMasterDataList.pending, (state, action) => {
      state.requestMasterDataList.loading = true;
    });
    builder.addCase(fetchRequestMasterDataList.fulfilled, (state, action) => {
      state.requestMasterDataList.dataStatus = action.payload.dataStatus;
      state.requestMasterDataList.records = action.payload.list;
      state.requestMasterDataList.recordsTotal = action.payload.recordsTotal;
      state.requestMasterDataList.loading = false;
    });
    builder.addCase(fetchRequestMasterDataList.rejected, (state, action) => {
      state.requestMasterDataList.loading = false;
      state.requestMasterDataList.error = action.error;
    });
    builder.addCase(getMasterDataSublocationLogs.pending, (state, action) => {
      state.requestMasterDataList.loading = true;
    });
    builder.addCase(getMasterDataSublocationLogs.fulfilled, (state, action) => {
      state.requestMasterDataList.statusLogList = action.payload;
    });
    builder.addCase(getMasterDataSublocationLogs.rejected, (state, action) => {
      state.requestMasterDataList.loading = false;
      state.requestMasterDataList.error = 'Invalid get data';
    });
  },
});
export const requestMasterDataSelector = (state) => state.requestSubLocationContractor.requestMasterDataList;
export const requestMasterDataDetailSelector = (state) => state.requestSubLocationContractor.requestMasterDataDetail;

export const {
  onChangeTableSubLocation,
  addDataTableSubLocation,
  removeFirstDataTableSubLocation,
  onRevertChangeTableSubLocation,
  onRevertTableSubLocation,
  onRemoveDataTableSubLocation,
} = requestMasterDataSlice.actions;

export default requestMasterDataSlice.reducer;
