import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUsername } from 'utils/helper';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';

export const initialState = {
  coalHaulDistanceList: {
    records: [],
    loading: false,
    error: null,
    loading: false,
    error: "",
    recordsTotal: "",
  },
  coalHaulDistanceDetail: {
    records: [],
    response: [],
    loading: false,
    error: null,
    statusLogList: [],
    documentList: [],
    shift: null,
    contractor: {},
  },
  currentCoalGetting: {
    loading: false,
    records: [],
    error: ""
  },
};

export const COAL_HAUL_DISTANCE_REDUCER = 'COAL_HAUL_DISTANCE_REDUCER';

export const fetchCoalHaulDistanceList = createAsyncThunk(
  'coal-haul-distance/list',
  async (params) => {
    const response = await post(`${apiUrls.masterData}/haul-distance-coal/filter`,
      {
        "columns": [
          {
            "data": "id",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": false
          },
          {
            "data": "reportId",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "phase",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "dataStatus",
            "orderable": true,
            "search": {
              "regex": params?.dataStatus === DATASOURCE_VALUE.waitingApproval || false,
              "value": ""
            },
            "searchValue": params.dataStatus || "",
            "searchable": true
          },
          {
            "data": "createdAt",
            "orderable": true,
            "search": {
              "regex": params.date ? true : false,
              "value": ""
            },
            "searchValue": params.date || "",
            "searchable": true
          }
        ],
        "draw": 1,
        "length": 50,
        "order": [
          {
            "column": 0,
            "dir": "desc"
          }
        ],
        "search": {
          "regex": false,
          "value": params.query || ""
        },
        "start": 0
      });
    return response?.data;
  }
);

export const fetchCoalHaulDetail = createAsyncThunk(
  'coal-haul-distance/detail',
  async (param) => {
    const { id } = param;
    const response = await get(`${apiUrls.masterData}/haul-distance-coal/${id}`);
    return response.data;
  }
);

export const handleCreateCoalHaul = createAsyncThunk(
  'coal-haul-distance/create-new',
  async (param) => {
    const { action, object } = param
    const response = await post(`${apiUrls.masterData}/haul-distance-coal/${action}?userName=${getUsername()}`, object);
    return response.data;
  }
);

export const handleEditCoalHaul = createAsyncThunk(
  'coal-haul-distance/edit',
  async (param) => {
    const { action, object, id } = param
    const response = await put(`${apiUrls.masterData}/haul-distance-coal/${id}/${action}?userName=${getUsername()}`, object);
    return response.data;
  }
);

export const handleApproveCoalHaul = createAsyncThunk(
  'coal-haul-distance/approve',
  async (param) => {
    const { id } = param
    // const response = await put(`${apiUrls.masterData}/haul-distance-coal/${id}/approve?userName=${getUsername()}`, { notes: '' });
    const response = await put(`${apiUrls.masterData}/haul-distance-coal/${id}/approve`, { notes: '' });
    return response.data;
  }
);

export const handleRejectCoalHaul = createAsyncThunk(
  'coal-haul-distance/reject',
  async (param) => {
    const { id, notes } = param
    // const response = await put(`${apiUrls.masterData}/haul-distance-coal/${id}/reject?userName=${getUsername()}`, { notes });
    const response = await put(`${apiUrls.masterData}/haul-distance-coal/${id}/reject`, { notes });
    return response.data;
  }
);

export const getCurrentCoalGetting = createAsyncThunk(
  'coal-haul-distance/getCurrentCoalGetting',
  async (params) => {
    const response = await get(`${apiUrls.coalGetting}/productions/contractor-name/${params}`);
    return response?.data;
  }
);

export const getContractorByName = createAsyncThunk(
  'coal-haul-distance/contractorByName',
  async (param) => {
    const response = await get(
      `${apiUrls.masterData}/contractors/names/${param}`
    );
    return response.data;
  }
);


const coalHaulDistanceSlice = createSlice({
  name: COAL_HAUL_DISTANCE_REDUCER,
  initialState,
  reducers: {
    clearCoalHaulDetail: (state, action) => {
      state.coalHaulDistanceDetail = {
        records: [],
        response: {},
        loading: false,
        error: null,
        statusLogList: [],
        documentList: [],
        shift: null,
        contractor: {},
      }
    },

    addDataTableCoalHaul: (state, action) => {
      if (!state.coalHaulDistanceDetail?.records) {
        state.coalHaulDistanceDetail.records = [action.payload]
      }
      else {
        state.coalHaulDistanceDetail?.records.push(action.payload);
      }
    },

    onEditCoalHaul: (state, action) => {
      const payload = action.payload;
      if (payload.tableRowName === "loadingLocation") {
        state.coalHaulDistanceDetail.records[payload.tableIndexRow]['loadingSublocation'] = "";
      }

      if (payload.tableRowName === "dumpingLocation") {
        state.coalHaulDistanceDetail.records[payload.tableIndexRow]['dumpingSublocation'] = "";
      }

      state.coalHaulDistanceDetail.records[payload.tableIndexRow][payload.tableRowName] = payload.value;
    },
    onRemoveDataTableCoalHaul: (state, action) => {
      const payload = action.payload;

      state.coalHaulDistanceDetail.records.splice(payload.tableIndexRow, 1);
    },

    onAddFileCoalHaul: (state, action) => {
      const payload = action.payload;
      if (state.coalHaulDistanceDetail.documentList) {
        state.coalHaulDistanceDetail.documentList.unshift(payload);
      }
      else {
        var arr = [payload]
        state.coalHaulDistanceDetail.documentList = arr
      }
    },

    onRemoveFileCoalHaul: (state, action) => {
      state.coalHaulDistanceDetail.documentList.splice(0, 1);
    },

    onChangeShiftWatch: (state, action) => {
      const payload = action.payload;
      state.coalHaulDistanceDetail[payload.name] = payload.value.value;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoalHaulDistanceList.pending, (state, action) => {
      state.coalHaulDistanceList.loading = true;
    });
    builder.addCase(fetchCoalHaulDistanceList.fulfilled, (state, action) => {
      state.coalHaulDistanceList.records = action.payload.list;
      state.coalHaulDistanceList.recordsTotal = action.payload.recordsTotal;
      state.coalHaulDistanceList.loading = false;
    });
    builder.addCase(fetchCoalHaulDistanceList.rejected, (state, action) => {
      state.coalHaulDistanceList.loading = false;
      state.coalHaulDistanceList.error = action.error;
    });

    builder.addCase(fetchCoalHaulDetail.pending, (state, action) => {
      state.coalHaulDistanceDetail.loading = true;
    });
    builder.addCase(fetchCoalHaulDetail.fulfilled, (state, action) => {
      state.coalHaulDistanceDetail.records = action.payload.loadingDumpingDistanceList;
      state.coalHaulDistanceDetail.response = action.payload;
      state.coalHaulDistanceDetail.shift = action.payload?.shift;
      state.coalHaulDistanceDetail.contractor = action.payload?.contractor;
      state.coalHaulDistanceDetail.loading = false;
    });
    builder.addCase(fetchCoalHaulDetail.rejected, (state, action) => {
      state.coalHaulDistanceDetail.loading = false;
      state.baDistanceHaulingList.error = action.error;
    });

    builder.addCase(getCurrentCoalGetting.pending, (state, action) => {
      state.loading = true;
      state.coalHaulDistanceDetail.loading = true;
    });
    builder.addCase(getCurrentCoalGetting.fulfilled, (state, action) => {
      const result = [];
      let rn = 0;
      action?.payload?.production?.data.forEach(item => {
        item.subRows.forEach(dt => {
          let nsr = {};
          if(dt.unit !== "" && dt.material !== "") {
            nsr.status = item.status.job;
            nsr.fleet = item.rowNumber;
            nsr.unit = item.unit;
            nsr.id = item.id;
            nsr.trackingTime = "";
            nsr.distanceOdometer ="";
            nsr.distanceGps = "";
            nsr.notes = dt.notes || "";
            nsr.dumpingPointSubLocation = dt.dumpingPoint || "";
            nsr.dumpingPointLocation = dt.loadingPoint || "";
            nsr.loadingPointSubLocation = dt.loadingPointSubLocation || "";
            nsr.material = dt.material || "";
            nsr.quantity = dt.quantity || 0;
            nsr.rowNumber = rn;
            result.push(nsr);
            rn++;
          }
        });
      });
      state.coalHaulDistanceDetail.loading = false;
      state.coalHaulDistanceDetail.records = result;
    });
    builder.addCase(getCurrentCoalGetting.rejected, (state, action) => {
      state.loading = false;
      state.coalHaulDistanceDetail.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(getContractorByName.pending, (state, action) => {
      state.coalHaulDistanceDetail.loading = true;
    });
    builder.addCase(getContractorByName.fulfilled, (state, action) => {
      state.coalHaulDistanceDetail.contractor = action.payload;
      state.coalHaulDistanceDetail.loading = false;
    });
    builder.addCase(getContractorByName.rejected, (state, action) => {
      state.coalHaulDistanceDetail.loading = false;
      state.coalHaulDistanceDetail.error = action.error;
    });

  },
});
export const coalHaulDistanceSelector = (state) => state.coalHaulDistance.coalHaulDistanceList;
export const coalHaulingDetailSelector = (state) => state.coalHaulDistance.coalHaulDistanceDetail;

export const currentCoalGettingSelector = (state) => state.coalHaulDistance.currentCoalGetting;

export const {
  clearCoalHaulDetail,
  onEditCoalHaul,
  addDataTableCoalHaul,
  onRemoveDataTableCoalHaul,
  onAddFileCoalHaul,
  onRemoveFileCoalHaul,
  onChangeShiftWatch,
} = coalHaulDistanceSlice.actions;

export default coalHaulDistanceSlice.reducer;
