import { createRef } from 'react';
import Form from 'react-bootstrap/Form';
import RDatePicker from 'react-datepicker';
import moment from 'moment';
import cx from 'classnames';
import { Controller } from 'react-hook-form';
import {
  LeftChevron,
  RightChevron,
  Calender,
  ArrowDownDatePicker,
} from './Icons';
import bn from 'utils/bemNames';

const bem = bn('date-picker');

export const YearPicker = ({
  name,
  control,
  rules,
  error,
  label,
  labelClass,
  format = 'YYYY',
  group,
  groupClass = 'mb-3',
  arrow,
  className,
  dateFormat,
  ...rest
}) => {
  let refDatePicker = createRef();
  let datePickerProps = {};
  if (rest.min) {
    datePickerProps.minDate =
      typeof rest.min === 'string' ? moment(rest.min).toDate() : rest.min;
  }
  if (rest.max) {
    datePickerProps.maxDate =
      typeof rest.max === 'string' ? moment(rest.max).toDate() : rest.max;
  }
  if (rest.placeholder) {
    datePickerProps.placeholderText = rest.placeholder;
  }
  const onKeyDown = (e) => {
    window.scrollBy(0, -1);
    window.scrollBy(0, 1);
    if (e.key === 'Tab') {
      refDatePicker.current.setOpen(false);
    }
  };
  const DatePickerNode = (
    <>
      {label && <Form.Label className={labelClass}>{label}</Form.Label>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <div className={cx('ccm-input-wrapper', bem.b(className))}>
              <div
                className="icon-box"
                onClick={() => {
                  if (refDatePicker.current) {
                    refDatePicker.current.deferFocusInput();
                  }
                }}
              >
                {arrow ? <ArrowDownDatePicker /> : <Calender />}
              </div>
              <RDatePicker
                dateFormat={dateFormat || 'yyyy'}
                selected={
                  field && field.value && typeof field.value === 'string'
                    ? moment(field.value, format).toDate()
                    : field.value
                }
                {...datePickerProps}
                autoComplete="off"
                onChange={(e) => {
                  if (!e) {
                    onChange('');
                    return;
                  }
                  const date = moment(e).toDate();
                  const fullYear = e.getFullYear();
                  if (Number(fullYear) <= 9999) {
                    onChange(date);
                  }
                }}
                ref={refDatePicker}
                onKeyDown={onKeyDown}
                {...rest}
              />
            </div>
          );
        }}
      />
      <div className="sdp-error">{error}</div>
    </>
  );
  return group ? (
    <Form.Group className={groupClass}>{DatePickerNode}</Form.Group>
  ) : (
    DatePickerNode
  );
};

export default YearPicker;
