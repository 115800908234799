import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUsername } from 'utils/helper';
import { getAPIEndpoint, post, get, deleteRequest, apiUrls } from 'utils/request';

// initial state
export const initialState = {
  portCrusher: {
    loading: false,
    records: [],
    recordsTotal: 0,
    error: '',
  },
  detailCrusher: {
    date: '',
    shift: '',
    updatedAt: '',
    crushingPlant: {
      plant: '',
    },
    activityList: [],
    production: {
      production: [],
    },
  },
  attendances: {
    loading: false,
    records: [],
    error: "",
  },
  listAttendances: {
    loading: false,
    records: [],
    error: "",
  },

};

// fetch functions
export const fetchActualPortCrusher = createAsyncThunk(
  'actual-port-crusher/filter',
  async (filter) => {
    const response = await post(
      getAPIEndpoint('actual-port', 'v1', '/crushing/filter'),
      {
        columns: [
          {
            data: 'date',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: filter.filterDate,
            searchable: true,
          },
          {
            data: 'shift',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: filter.filterShift,
            searchable: true,
          },
        ],
        draw: 1,
        length: -1,
        order: [],
      }
    );
    return response.data;
  }
);

export const fetchActualPortCrusherInterval = createAsyncThunk(
  'actual-port-crusher/filter-interval',
  async (filter) => {
    const response = await post(
      getAPIEndpoint('actual-port', 'v1', '/crushing/filter'),
      {
        columns: [
          {
            data: 'date',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: filter.filterDate,
            searchable: true,
          },
          {
            data: 'shift',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: filter.filterShift,
            searchable: true,
          },
        ],
        draw: 1,
        length: -1,
        order: [],
      }
    );
    return response.data;
  }
);

export const fetchDetailActualPort = createAsyncThunk(
  'actual-port-crusher/detail',
  async (id) => {
    const response = await get(
      getAPIEndpoint('actual-port', 'v1', '/crushing/' + id)
    );
    return response.data;
  }
);

export const addActualPortCrusher = createAsyncThunk(
  'actual-port-crusher/add',
  async (body) => {
    let response;
    try {
      response = await post(
        getAPIEndpoint(
          'actual-port',
          'v1',
          `/crushing/add?userName=${getUsername()}&date=${body.date}`
        ),
        body
      );

      return response
    } catch (e) {
      return e
    }
  }
);

export const adminSaveActualPortCrusher = createAsyncThunk(
  'actual-port-crusher/adminSave',
  async ({ body, date, userName, id }) => {
    let response;

    try {
      response = await post(
        getAPIEndpoint(
          'actual-port',
          'v1',
          `/crushing/admin/${id}/save?date=${date}&userName=${userName}`
        ),
        body
      );

      return response
    } catch (e) {
      return e
    }
  }
);

export const saveActualCrusherProduction = createAsyncThunk(
  'actual-port-crusher/save',
  async ({ data, username, action }) => {
    return await action(
      getAPIEndpoint(
        'actual-port',
        'v1',
        '/crushing/production/save?userName=' + username
      ),
      data
    );
  }
);

const dataUrlToBase64 = (dataUrl) => {
  const commaIndex = dataUrl.indexOf(',');
  if (commaIndex === -1) {
    throw new Error('Invalid data URL');
  }
  const base64 = dataUrl.substring(commaIndex + 1);
  return base64;
};

const convertImageDataUrlToBase64 = (input) => {
  const dataUrlPattern = /^data:image\/\w+;base64,/;
  if (dataUrlPattern.test(input)) {
    return dataUrlToBase64(input);
  } else {
    return input;
  }
}

export const addActualCrusherActivity = createAsyncThunk(
  'actual-port-crusher/add-activity',
  async ({ data, username, action }) => {
    const payload = {
      ...data,
      documentList: data.documentList.map((item) => {
        return item?.base64 ? {
          ...item,
          base64: convertImageDataUrlToBase64(item?.base64)
        } : item;
      })
    };
    return await action(`${apiUrls.actualPortCrushing}/activity/add?userName=${username}`, payload)

  }
);

export const saveActualCrusherActivity = createAsyncThunk(
  'actual-port-crusher/save-activity',
  async ({ data, username, action, id }) => {
    const payload = {
      ...data,
      documentList: data.documentList.map((item) => {
        return item?.base64 ? {
          ...item,
          base64: convertImageDataUrlToBase64(item?.base64)
        } : item;
      })
    };
    return await action(`${apiUrls.actualPortCrushing}/activity/${id}/save?userName=${username}`, payload)
  }
);

export const deleteActualCrusher = createAsyncThunk(
  'actual-port-crusher/delete',
  async ({ id, username }) => {
    return await deleteRequest(
      getAPIEndpoint(
        'actual-port',
        'v1',
        `/crushing/${id}?userName=${username}`
      )
    );
  }
);

export const fetchDetailOperatorCrusher = createAsyncThunk(
  'actual-port-crusher/attendance/detail-operator',
  async () => {
    const response = await get(`${apiUrls.attendances}/detail-operator/CrsuhingActual`);

    return response?.data;
  }
);

export const fetchListAttendance = createAsyncThunk(
  'actual-port-crusher/attendance/list-attendance',
  async (option) => {
    const response = await post(`${apiUrls.attendances}/list-attendance`, option);

    return response?.data;
  }
);

export const saveAttendance = createAsyncThunk(
  'actual-port-crusher/attendance/list-attendance',
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.attendances}/attendance/save?userName=${params.userName}`, params.options);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const handleDeleteCrushingActivity = createAsyncThunk(
  'actual-port-crusher/delete-activity',
  async (param) => {
    const { userName, id } = param
    let response

    try {
      response = await deleteRequest(`${apiUrls.actualPortCrushing}/activity/${id}?userName=${userName}`);
      return response.data;
    }
    catch (e) {
      return e
    }
  }
);

// slicing
const actualAdminCrusher = createSlice({
  name: 'ACTUAL_ADMIN_CRUSHER',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActualPortCrusher.rejected, (state, action) => {
      state.portCrusher.loading = false;
    });
    builder.addCase(fetchActualPortCrusher.fulfilled, (state, action) => {
      state.portCrusher.loading = false;
      state.portCrusher.records = action.payload?.list;
      state.portCrusher.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(fetchActualPortCrusher.pending, (state) => {
      state.portCrusher.loading = true;
    });
    builder.addCase(fetchActualPortCrusherInterval.fulfilled, (state, action) => {
      state.portCrusher.records = action.payload?.list;
      state.portCrusher.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(fetchDetailActualPort.fulfilled, (state, action) => {
      let activityList = JSON.parse(
        JSON.stringify(action.payload.activityList)
      );

      const activityListSortedByStartTime = activityList.sort(function (a, b) {
        let aStartTime = `${a.startTime}`;
        let bStartTime = `${b.startTime}`;
        if (action.payload.shift === 'Day') {
          return aStartTime.localeCompare(bStartTime);
        } else if (action.payload.shift === 'Night') {
          aStartTime = a.startTime.startsWith('0')
            ? 'B' + aStartTime
            : 'A' + aStartTime;
          bStartTime = b.startTime.startsWith('0')
            ? 'B' + bStartTime
            : 'A' + bStartTime;
          return aStartTime.localeCompare(bStartTime);
        }
      });

      for (var i = 0; i < activityListSortedByStartTime.length; i++) {
        if (i + 1 != activityListSortedByStartTime.length) {
          activityListSortedByStartTime[i].endTime =
            activityListSortedByStartTime[i + 1].startTime;
        } else {
          if (action.payload.shift.toLowerCase() === 'night') {
            activityListSortedByStartTime[i].endTime = '06:00';
          } else {
            activityListSortedByStartTime[i].endTime = '18:00';
          }
        }

        const time = activityListSortedByStartTime[i].startTime.split(":");
        const timeFormat = `${time[0]}:${time[1]}`;
        activityListSortedByStartTime[i].timeFormat = timeFormat;
      }

      state.detailCrusher = {
        ...action.payload,
        activityList: activityListSortedByStartTime,
      };
    });

    builder.addCase(fetchDetailOperatorCrusher.pending, (state, action) => {
      state.attendances.loading = true;
    });
    builder.addCase(fetchDetailOperatorCrusher.fulfilled, (state, action) => {
      const payload = action.payload.filter(e => e.entityStatus === 1)
      const payloadFilter = payload.sort((a, b) => a['name'].toLowerCase() < b['name'].toLowerCase() ? -1 : 1)

      state.attendances.records = payloadFilter;
      state.attendances.loading = false;
    });
    builder.addCase(fetchDetailOperatorCrusher.rejected, (state, action) => {
      state.attendances.loading = false;
      state.attendances.error = 'Invalid get data';
    });

    builder.addCase(fetchListAttendance.pending, (state, action) => {
      state.listAttendances.loading = true;
    });
    builder.addCase(fetchListAttendance.fulfilled, (state, action) => {
      state.listAttendances.records = action.payload;
      state.listAttendances.loading = false;
    });
    builder.addCase(fetchListAttendance.rejected, (state, action) => {
      state.listAttendances.loading = false;
      state.listAttendances.error = 'Invalid get data';
    });
  },
});

export const portActualCrusher = (state) =>
  state.actualAdminCrusher.portCrusher;
export const detailDataSelector = (state) =>
  state.actualAdminCrusher.detailCrusher;
export const crusherAttendanceSelector = (state) => state.actualAdminCrusher.attendances;
export const crusherListAttendanceSelector = (state) => state.actualAdminCrusher.listAttendances;

export default actualAdminCrusher.reducer;
