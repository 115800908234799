import { v4 as uuidv4 } from 'uuid';

export const isFunction = (functionToCheck) => {
  return typeof functionToCheck === 'function';
};

export const ConfirmIcon = ({ strokeColor, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.0064 8.77964L5.20639 0.377231C4.54338 0.0471472 3.79473 -0.0703648 3.06251 0.0407164C2.3303 0.151798 1.65014 0.486066 1.11479 0.997948C0.579437 1.50983 0.214936 2.17441 0.0709839 2.90109C-0.0729682 3.62776 0.0106342 4.38116 0.310393 5.05857L3.19039 11.5044C3.25574 11.6603 3.2894 11.8276 3.2894 11.9966C3.2894 12.1656 3.25574 12.3329 3.19039 12.4887L0.310393 18.9345C0.0664327 19.4828 -0.0367006 20.0833 0.0103651 20.6815C0.0574308 21.2797 0.253203 21.8567 0.579891 22.36C0.906579 22.8633 1.35382 23.2769 1.88098 23.5633C2.40814 23.8498 2.99849 23.9999 3.59839 24C4.16027 23.9944 4.71378 23.8632 5.21839 23.6159L22.0184 15.2135C22.6143 14.9136 23.1152 14.454 23.4652 13.886C23.8152 13.318 24.0006 12.6638 24.0006 11.9966C24.0006 11.3293 23.8152 10.6752 23.4652 10.1071C23.1152 9.53909 22.6143 9.0795 22.0184 8.77964H22.0064ZM20.9384 13.0649L4.13839 21.4673C3.91779 21.5732 3.67007 21.6092 3.42846 21.5703C3.18684 21.5315 2.96288 21.4196 2.78661 21.2498C2.61033 21.08 2.49017 20.8604 2.44223 20.6203C2.39429 20.3803 2.42086 20.1313 2.51839 19.9068L5.38639 13.461C5.42352 13.3749 5.45557 13.2867 5.48239 13.1969H13.7504C14.0687 13.1969 14.3739 13.0704 14.5989 12.8453C14.824 12.6202 14.9504 12.3149 14.9504 11.9966C14.9504 11.6782 14.824 11.3729 14.5989 11.1478C14.3739 10.9227 14.0687 10.7962 13.7504 10.7962H5.48239C5.45557 10.7064 5.42352 10.6182 5.38639 10.5321L2.51839 4.08629C2.42086 3.86179 2.39429 3.61282 2.44223 3.37277C2.49017 3.13273 2.61033 2.91308 2.78661 2.74329C2.96288 2.57349 3.18684 2.46166 3.42846 2.4228C3.67007 2.38394 3.91779 2.41989 4.13839 2.52585L20.9384 10.9283C21.135 11.029 21.2999 11.182 21.4151 11.3705C21.5303 11.559 21.5912 11.7756 21.5912 11.9966C21.5912 12.2175 21.5303 12.4341 21.4151 12.6226C21.2999 12.8111 21.135 12.9641 20.9384 13.0649Z" fill="#FF0C2B" />
    </svg>

  );
};

export const numberToString = (number) => {
  return number ? number.toString() : '';
};

export const shortData = (data) => {
  const tempData = data.map((item) => {
    if (item.question) {
      const listQuestion = item.question.sort((a, b) => a.sort - b.sort);
      item.question = listQuestion;
    }
    return item;
  });

  return tempData.sort((a, b) => a.sort - b.sort);
};

export const addUuid = (data) => {
  return data.map((item) => {
    if (!item.uuid) {
      item.uuid = uuidv4();
    }
    if (item.question) {
      const listQuestion = item.question.map((question) => {
        if (!question.uuid) {
          question.uuid = uuidv4();
        }
        return question;
      });
      item.question = listQuestion;
    }
    return item;
  });
};
