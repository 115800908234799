import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';

const serviceName = 'payload-waste-removal';
export const initialState = {
  payloadWasteRemovalList: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: '',
  },
  payloadWasteRemovalDetail: {
    data: [],
    records: [],
    response: [],
    loading: false,
    error: null,
    statusLogList: [],
  },
  payloadWasteRemovalDetailTemplate: {
    data: [],
    records: [],
    loading: false,
    error: null,
  },
};

export const PAYLOAD_WASTE_REMOVAL_REDUCER = 'PAYLOAD_WASTE_REMOVAL_REDUCER';

export const fetchPayloadWasteRemovalList = createAsyncThunk(
  'payload-waste-removal/list',
  async (params) => {
    const response = await post(`${apiUrls.payloadWasteRemoval}/filter`, {
      columns: [
        {
          data: 'payloadId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params?.dataStatus === DATASOURCE_VALUE.waitingApproval || false,
            value: '',
          },
          searchValue: params.dataStatus || '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.date || '',
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.dataLength ? params.dataLength : -1,
      order: [
      ],
      search: {
        regex: false,
        value: params.query || '',
      },
      start: 0,
    });
    return response?.data;
  }
);

export const deletePayloadWasteRemoval = createAsyncThunk(
  'payload-waste-removal/delete',
  async (param) => {
    const { id } = param;
    const response = await deleteRequest(
      `${apiUrls.payloadWasteRemoval}/delete/${id}`
    );
    return response;
  }
);

export const setPayloadWasteRemovalStatus = createAsyncThunk(
  'payload-waste-removal/setStatus',
  async (param) => {
    const { data } = param;
    const response = await patch(`${apiUrls.payloadWasteRemoval}/set-status`, {
      ...data,
    });
    return response.data;
  }
);

export const payloadWasteRemovalDetailTemplate = createAsyncThunk(
  'payload-waste-removal/template',
  async (param) => {
    const response = await get(
      `${apiUrls.payloadWasteRemoval}/detail-template`
    );
    return response.data;
  }
);

export const getDetailService = createAsyncThunk(
  `${serviceName}/detail`,
  async (params) => {
    const { id } = params;
    const response = await get(`${apiUrls.payloadWasteRemoval}/${id}`);
    return response?.data;
  }
);

export const addService = createAsyncThunk(
  `${serviceName}/add`,
  async (param) => {
    const { data, username } = param;
    const response = await post(`${apiUrls.payloadWasteRemoval}/save?userName=${username}`, data);
    return response.data;
  }
);

export const saveService = createAsyncThunk(
  `${serviceName}/save`,
  async (param) => {
    const { data, username } = param;
    const response = await post(`${apiUrls.payloadWasteRemoval}/save?userName=${username}`, data);
    return response.data;
  }
);

export const submitService = createAsyncThunk(
  `${serviceName}/submit`,
  async (param) => {
    const { data, username } = param;
    const response = await post(`${apiUrls.payloadWasteRemoval}/submit?userName=${username}`, data);
    return response.data;
  }
);

export const uploadFileService = createAsyncThunk(
  `${serviceName}/uploadFile`,
  async (param) => {
    const { id, file } = param;
    const response = await post(`${apiUrls.payloadWasteRemoval}/${id}/files`, file, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);

export const deleteFileService = createAsyncThunk(
  `${serviceName}/deleteFile`,
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.payloadWasteRemoval}/${id}/files/${name}`
    );
    return response.data;
  }
);

export const updateDataService = createAsyncThunk(
  `${serviceName}/adminUpdate`,
  async (param) => {
    const { id, action, username, data } = param;
    const response = await put(
      `${apiUrls.payloadWasteRemoval}/${id}/admin/${action}?userName=${username}`, data
    );
    return response.data;
  }
);

const payloadWasteRemovalSlice = createSlice({
  name: PAYLOAD_WASTE_REMOVAL_REDUCER,
  initialState,
  reducers: {
    clearPayloadWasteDetail: (state, action) => {
      state.payloadWasteRemovalDetail = {
        data: {},
        loading: false,
        error: null,
      };
    },

    onChangeWaste: (state, action) => {
      const payload = action.payload;

      let index = payload.tableRowName.split('value');
      index = parseInt(index[1]) - 1;

      state.payloadWasteRemovalDetailTemplate.records[payload.tableIndexRow][
        'equipment'
      ][index]['value'] = payload.value;
    },

    onEditWaste: (state, action) => {
      const payload = action.payload;

      let index = payload.tableRowName.split('value');
      index = parseInt(index[1]) - 1;

      state.payloadWasteRemovalDetail.records[payload.tableIndexRow][
        'equipment'
      ][index]['value'] = payload.value;
    },

    onReset: (state, action) => {
      state.payloadWasteRemovalDetail = {
        data: [],
        records: [],
        response: [],
        loading: false,
        error: null,
        statusLogList: [],
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayloadWasteRemovalList.pending, (state, action) => {
      state.payloadWasteRemovalList.loading = true;
    });
    builder.addCase(fetchPayloadWasteRemovalList.fulfilled, (state, action) => {
      state.payloadWasteRemovalList.records = action.payload.list;
      state.payloadWasteRemovalList.recordsTotal = action.payload.recordsTotal;
      state.payloadWasteRemovalList.loading = false;
    });
    builder.addCase(fetchPayloadWasteRemovalList.rejected, (state, action) => {
      state.payloadWasteRemovalList.loading = false;
      state.payloadWasteRemovalList.error = action.error;
    });

    builder.addCase(getDetailService.pending, (state, action) => {
      state.payloadWasteRemovalDetail.loading = true;
    });
    builder.addCase(
      getDetailService.fulfilled,
      (state, action) => {
        state.payloadWasteRemovalDetail.records = action.payload.detail;
        state.payloadWasteRemovalDetail.data = action.payload.detail;
        state.payloadWasteRemovalDetail.response = action.payload;
        state.payloadWasteRemovalDetail.statusLogList =
          action.payload.statusLogList;
        state.payloadWasteRemovalDetail.loading = false;
      }
    );
    builder.addCase(
      getDetailService.rejected,
      (state, action) => {
        state.payloadWasteRemovalDetail.loading = false;
        state.payloadWasteRemovalDetail.error = action.error;
      }
    );

    builder.addCase(
      payloadWasteRemovalDetailTemplate.pending,
      (state, action) => {
        state.payloadWasteRemovalDetailTemplate.loading = true;
      }
    );
    builder.addCase(
      payloadWasteRemovalDetailTemplate.fulfilled,
      (state, action) => {
        state.payloadWasteRemovalDetailTemplate.data = action.payload;
        state.payloadWasteRemovalDetailTemplate.records = action.payload;
        state.payloadWasteRemovalDetailTemplate.loading = false;
      }
    );
    builder.addCase(
      payloadWasteRemovalDetailTemplate.rejected,
      (state, action) => {
        state.payloadWasteRemovalDetailTemplate.loading = false;
        state.payloadWasteRemovalDetailTemplate.error = action.error;
      }
    );
  },
});
export const payloadWasteRemovalSelector = (state) =>
  state.payloadWasteRemoval.payloadWasteRemovalList;
export const payloadWasteRemovalDetailSelector = (state) =>
  state.payloadWasteRemoval.payloadWasteRemovalDetail;
export const payloadWasteRemovalTemplateSelector = (state) =>
  state.payloadWasteRemoval.payloadWasteRemovalDetailTemplate;

export const {
  clearPayloadWasteDetail,
  onChangeWaste,
  onEditWaste,
  onReset
} = payloadWasteRemovalSlice.actions;

export default payloadWasteRemovalSlice.reducer;
