import { forwardRef, useCallback, useMemo, useRef } from 'react';
import RDatePicker from 'react-datepicker';
import Select from 'react-select';
import { range } from 'lodash';
import moment from 'moment';
import {
  Table,
} from 'components';
import { shortMonthListEN as shortMonthList } from 'utils/constants';

import cx from 'classnames';

const shiftList = [
  {
    label: "Day",
    value: "day"
  },
  {
    label: "Night",
    value: "night"
  }
]

const WorkingHourAvailableDetail = ({
  getValues = () => { },
  setValue = () => { },
  available = null,
  isAdd = true
}) => {
  let editableKeyToFocus = useRef();

  const renderCell = useCallback((cellInfo) => {
    const name = `available[${cellInfo.column.id}]`;
    const header = cellInfo.column.id;
    const listMonth = getValues("available");
    let fullYear = 0;

    if (listMonth) listMonth?.map((val, index) => fullYear += listMonth[index].value)

    return (
      <div>{header === "fullYear" ? fullYear : getValues(name)?.value}</div>
    );
  }, [])

  const WorkingHoursData = useMemo(() => {
    let column = shortMonthList.map((item, index) => {
      return {
        accessor: item,
        Header: item,
        id: index.toString(),
        disableSortBy: true,
        maxWidth: 50,
        Cell: renderCell,
      };
    });

    column.push({
      accessor: "fullYear",
      Header: (<div style={{ color: "#FF0C2B" }}>Full Year</div>),
      disableSortBy: true,
      maxWidth: 50,
      Cell: renderCell,
    })
    return [
      {
        Header: '',
        accessor: 'header',
        disableSortBy: true,
        maxWidth: 50,
      },
      ...column,
    ];
  }, [renderCell]);

  const HolidayData = useMemo(() => {
    const data = {
      header: 'Available Day',
    };
    shortMonthList.forEach((item, index) => {
      data[item] = '';
    });
    return data;
  }, []);

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <input style={{ border: "none", width: "100%" }} onClick={onClick} ref={ref} value={value} readOnly />
  ));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      border: 'none',
      minHeight: '23px',
      height: '23px',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '23px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '23px',
    }),
  };

  const calculateWorkingHour = () => {
    const arrayValue = ["newYear", "newYearDec", "independence", "eidAlFitr", "eidAlAdha"]

    for (var i in range(5)) {
      const value = getValues(arrayValue[i])
      if (
        value.startDate &&
        value.startShift &&
        value.holidayDuration &&
        value.endDate &&
        value.endShift
      ) {
        let available = getValues('available').map(e => { return { ...e } })
        let dateValidation = null
        let shift = value.startShift.label
        let duration = 0
        let durationNextMonth = 0

        for (var index in range(value.holidayDuration)) {
          const startDate = moment(value.startDate)
          if (shift === "Day") {
            shift = "Night"
            duration += 0.5
          }
          else if (shift === "Night") {
            shift = "Day"
            duration += 0.5
          }

          dateValidation = moment(startDate).add(parseInt(duration), "days")
          if (startDate.get("months") === dateValidation.get("months")) {
            const month = dateValidation.get("months")
            let prevVal = i == 0 ? moment().set("months", month).daysInMonth() : getValues(`available[${month}].value`)
            available[month].value = prevVal - duration
          }
          else {
            durationNextMonth += 0.5
            const month = dateValidation.get("months")
            let prevVal = i == 0 ? moment().set("months", month).daysInMonth() : getValues(`available[${month}].value`)
            available[month].value = prevVal - durationNextMonth
          }
        }
        setValue("available", available)
      }
    }
  }

  const calculateHoliday = (name) => {
    const value = getValues(name);
    setValue("available", available)
    setValue(`${name}.endShift`, null)
    setValue(`${name}.endDate`, null)

    if (value.startDate && value.startShift && value.holidayDuration) {
      const duration = value.holidayDuration || 0
      let dateDuration = 0
      let endDuration = 0
      let shift = value.startShift.label

      for (var i in range(duration)) {
        if (i === "0") {
          // endDuration += 1
          dateDuration += 0.5
        }
        else if (shift === "Day") shift = "Night"
        else if (shift === "Night") {
          shift = "Day"
          endDuration += 1
        }

        dateDuration += 0.5
      }

      setValue(`${name}.endShift`, shift)
      setValue(`${name}.dateDuration`, dateDuration)

      const endDate = moment(value.startDate).add(endDuration.toString().split(".")[0], "days")
      setValue(`${name}.endDate`, endDate.format("DD/MMMM/yyyy"))
    }
    calculateWorkingHour()
  }

  const customHeader = (id, loc) => {
    const name = id.split(".")[0]
    if (loc.code === "startDate") {
      const value = getValues(`${name}.startDate`)
      return (
        <div className={cx('ccm-input-wrapper')}>
          <RDatePicker
            selected={value ? new Date(value) : null}
            onChange={(date) => {
              setValue(`${name}.startDate`, date)
              calculateHoliday(name)
              editableKeyToFocus.current = `${name}.startDate`;
            }}
            autoComplete="off"
            dateFormat="dd/MMMM/yyyy"
            disabled={!isAdd}
            customInput={<CustomDateInput />}
          />
        </div>
      )
    }
    else if (loc.code === "startShift") {
      const value = getValues(`${name}.startShift`)
      return (
        <Select
          placeholder=""
          styles={customStyles}
          value={value}
          onChange={(val) => {
            setValue(`${name}.startShift`, val)
            calculateHoliday(name)
            editableKeyToFocus.current = `${name}.startShift`;
          }}
          options={shiftList}
          isDisabled={!isAdd}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      )
    }
    else if (loc.code === "holidayDuration") {
      const value = getValues(`${name}.holidayDuration`)
      return (
        <div className={cx('ccm-input-wrapper')}>
          <input
            style={{ maxHeight: "23px", width: "100%", border: "none" }}
            type={"number"}
            min={0}
            value={value}
            disabled={!isAdd}
            onChange={(val) => {
              setValue(`${name}.holidayDuration`, parseInt(val.target.value || 0))
              calculateHoliday(name)
              editableKeyToFocus.current = `${name}.holidayDuration`;
            }}
            autoFocus={editableKeyToFocus.current === `${name}.holidayDuration`}
          />
        </div>
      )
    }
    else if (loc.code === "endDate") {
      const value = getValues(`${name}.endDate`)
      return (
        <div>
          {value ? moment(value).format("DD/MMMM/yyyy") : null}
        </div>
      )
    }
    else if (loc.code === "endShift") {
      const value = getValues(`${name}.endShift`)
      return (
        <div>
          {value}
        </div>
      )
    }
  }

  const HolidayDetailData = useMemo(() => {
    const dataDetail = [
      {
        label: "Start Date",
        code: "startDate"
      },
      {
        label: "Start Shift",
        code: "startShift"
      },
      {
        label: "Holiday Duration",
        code: "holidayDuration"
      },
      {
        label: "End Date",
        code: "endDate"
      },
      {
        label: "End Shift",
        code: "endShift"
      },
    ]

    return (dataDetail || []).map((loc, i) => {
      return {
        Header: loc.label,
        id: loc.code + "-main",
        disableSortBy: true,
        className: 'border-right text-center fw-bold bg-white',
        columns: [
          {
            Header: (cellInfo) => customHeader(cellInfo.column.id, loc),
            id: `newYear.${loc.code}`,
            disableSortBy: true,
            className: `text-left border-right ${loc.code !== "endDate" && loc.code !== "endShift" ? "bg-white" : ""}`,
            columns: [
              {
                Header: (cellInfo) => customHeader(cellInfo.column.id, loc),
                id: `newYearDec.${loc.code}`,
                disableSortBy: true,
                className: `text-left border-right ${loc.code !== "endDate" && loc.code !== "endShift" ? "bg-white" : ""}`,
                columns: [
                  {
                    Header: (cellInfo) => customHeader(cellInfo.column.id, loc),
                    id: `independence.${loc.code}`,
                    disableSortBy: true,
                    className: `text-left border-right ${loc.code !== "endDate" && loc.code !== "endShift" ? "bg-white" : ""}`,
                    columns: [
                      {
                        Header: (cellInfo) => customHeader(cellInfo.column.id, loc),
                        id: `eidAlFitr.${loc.code}`,
                        disableSortBy: true,
                        className: `text-left border-right ${loc.code !== "endDate" && loc.code !== "endShift" ? "bg-white" : ""}`,
                        columns: [
                          {
                            Header: (cellInfo) => customHeader(cellInfo.column.id, loc),
                            id: `eidAlAdha.${loc.code}`,
                            disableSortBy: true,
                            className: `text-left border-right ${loc.code !== "endDate" && loc.code !== "endShift" ? "bg-white" : ""}`,
                          },
                        ]
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      }
    })
  })

  const HolidayList = [
    {
      Header: 'Holiday',
      id: 'header',
      className: 'border-right text-center fw-bold bg-white',
      disableSortBy: true,
      columns: [
        {
          Header: 'New Year January',
          disableSortBy: true,
          className: 'border-right text-left',
          columns: [
            {
              Header: 'New Year December',
              disableSortBy: true,
              className: 'border-right text-left',
              columns: [
                {
                  Header: 'Independence Day',
                  disableSortBy: true,
                  className: 'border-right text-left',
                  columns: [
                    {
                      Header: 'Eid Al Fitr',
                      disableSortBy: true,
                      className: 'border-right text-left',
                      columns: [
                        {
                          Header: 'Eid Al Adha',
                          disableSortBy: true,
                          className: 'border-right text-left',
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
  ]
    .concat(HolidayDetailData)

  const tableConfig = {
    columns: WorkingHoursData,
    data: [HolidayData],
    className: 'primary no-flex',
    showEntries: false,
    responsive: false,
    showPagination: false,
    showEmpetyText: false,
  };

  const tableConfigHoliday = {
    dataEmpty: true,
    data: [],
    columns: HolidayList,
    showEntries: false,
    showPagination: false,
    showEmpetyText: false,
    responsive: false,
    className: `primary no-flex`,
  }

  return (
    <>
      <div className="title">Working Hours</div>
      <div className='pt-20'>
        <Table {...tableConfig} />
      </div>
      <div className='pb-200'>
        <Table {...tableConfigHoliday} />
      </div>
    </>
  );
};

export default WorkingHourAvailableDetail;
