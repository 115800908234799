import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FormCheck } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import { onChangeStatus, onSelectAllModule } from "containers/Configuration/UserConfig/reducer";
import {
  Table,
  Loader,
} from "components";

const RoleTable = ({
  loading = false,
  data = [],
  role = "",
  checkBox,
  setCheckBox = () => { }
}) => {
  const dispatch = useDispatch()

  const {
    control,
    formState: { errors },
  } = useForm()

  const handleChangeStatus = (id, cellInfo, value) => {
    const obj = {
      id,
      data: cellInfo.row.original,
      value: !value
    }

    dispatch(onChangeStatus(obj))
  }

  const handleSelectAll = (cellInfo, checked) => {
    const obj = {
      data: cellInfo.row.original,
      checked,
      role
    }

    let checkBoxVal = checkBox;
    checkBoxVal[cellInfo.row.original.modulCode] = checked
    setCheckBox(checkBoxVal)
    dispatch(onSelectAllModule(obj))
  }

  const renderCell = (cellInfo) => {
    const value = cellInfo?.value;
    const isCheckBox = cellInfo?.row?.original?.isCheckBox
    const id = cellInfo.column.id
    let disabled = false

    if (role === "Viewer" && id === "create" || role === "Viewer" && id === "approve") disabled = true
    else if (role === "Creator" && id === "approve") disabled = true
    else if (role === "Approver" && id === "create") disabled = true
    else if (role === "Admin Contractor" && id === "approve") disabled = true
    else if (role === "Admin Mobile" && id === "approve") disabled = true
    else if (role === "Captain Contractor" && id === "approve") disabled = true
    else if (role === "Creator Contractor" && id === "approve") disabled = true

    if (id === "name") {
      if (isCheckBox) return <FormCheck className="role-select-all" defaultChecked={checkBox[cellInfo.row.original.modulCode]}
        type="checkbox" label="Select All" disabled={disabled} onChange={(e) => handleSelectAll(cellInfo, e.target.checked)} />
      return <span>{value}</span>
    }
    if (isCheckBox) return

    return <FormCheck className="role" disabled={disabled} defaultChecked={value} type="checkbox" label=""
      onChange={() => handleChangeStatus(id, cellInfo, value)} />
  };

  const columns = useMemo(
    () => [
      {
        Header: "Module",
        accessor: "name",
        disableSortBy: true,
        Cell: renderCell,
      },
      {
        Header: "View",
        accessor: "view",
        disableSortBy: true,
        Cell: renderCell,
      },
      {
        Header: "Creator",
        accessor: "create",
        disableSortBy: true,
        Cell: renderCell,
      },
      {
        Header: "Approve",
        accessor: "approve",
        disableSortBy: true,
        Cell: renderCell,
      },
    ],
    [data]
  );

  const tableConfig = {
    columns,
    data: data,
    className: `primary role`,
    showPagination: false,
    showEntries: false,
    pageSize: 100,
  };

  return (
    <>
      {(loading === "fetch") &&
        <div className="my-30">
          <Loader />
        </div>}
      {(loading !== "fetch") &&
        <div className="wrapper-table mb-10 mt-30">
          <Table {...tableConfig} />
        </div>}
      <ToastContainer />
    </>
  );
};

export default RoleTable;
