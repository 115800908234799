const SubHeader = ({children}) => {
    return(
        <div className="sub-header">
            <div className="container">
                {children}
            </div>
        </div>
    );
}

export default SubHeader;

