import { SubmitIcon } from "assets/icons";
import Modal from "./Modal";
import Button from "./Button";

export const RollbackModal = ({
  showDialog,
  setShowDialog,
  setRollbackId = () => { },
  onClick = () => { }
}) => {
  return (
    <Modal
      title={'Are you sure to <span>Change the Status?</span>'}
      showDialog={showDialog}
      showHeader={true}
      icon={<SubmitIcon />}
      status=""
      activeID={false}
      useHTMLString={true}
    >
      <p style={{ fontSize: '13px', textAlign: 'center' }}>
        After you confirm, the status will change without changing the version.
      </p>
      <div className="wrap-button">
        <button
          className="btn btn-light"
          onClick={() => {
            setShowDialog(false)
            setRollbackId(false)
          }}
        >
          Cancel
        </button>
        <Button variant="primary" onClick={() => onClick()}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default RollbackModal;
