import { Row, Col } from 'react-bootstrap';
import { DeleteCloseIconLarge } from 'assets/icons';
import { useEffect } from 'react';
import { useRef } from 'react';

const DashboardInformation = ({
  closeLog = null,
  p2hActive = true,
  p2hStatus = "Crushing Plant",
  p2hStatusNormal = "Crushing Plant",
  p2hStatusAbnormal = "Crushing Plant",
}) => {
  const ref = useRef();

  const handleClickOutside = e => {
    if (!ref.current.contains(e.target)) onClose()
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const onClose = () => closeLog(false)

  return (
    <div ref={ref}>
      <div className="text-left border-t log-status dashboard">
        <div className='border-b d-flex' style={{ justifyContent: "space-between", background: "#F5F6F6", padding: "15px 0" }}>
          <div className="pl-20 log-status__title">Legend</div>
          {closeLog && (
            <div className='pr-10' style={{ cursor: "pointer" }} onClick={() => onClose()}>
              <DeleteCloseIconLarge style={{ borderRadius: "50%" }} />
            </div>
          )}
        </div>
        <div className="mt-20 log-status__wrapper">
          {p2hActive && (
            <div className='content-modal'>
              <div className='mb-20 fs-16 fw-bold'>{p2hStatus} P2H Status</div>
              <div className='my-20'>
                <Row>
                  <Col md={2}>
                    <div className='circle-safe' />
                  </Col>
                  <Col md={9}>
                    <div className='pr-10 text-left fs-14 -indent-3'>{p2hStatusNormal} Normal</div>
                  </Col>
                </Row>
              </div>
              <div className='my-20'>
                <Row>
                  <Col md={2}>
                    <div className='circle-danger' />
                  </Col>
                  <Col md={9}>
                    <div className='pr-10 text-left fs-14 -indent-3'>{p2hStatusAbnormal} Abnormal</div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          <div className='my-10' style={{ border: "1px solid #E5E6E6" }} />
          <div className='content-modal'>
            <div className='mb-20 fs-16 fw-bold'>Production & Productivity</div>
            <Row>
              <Col md={2}>
                <div className='circle-purple' />
              </Col>
              <Col md={4}>
              </Col>
              <Col md={2}>
                <div className='pr-10 text-left fs-14'>Actual</div>
              </Col>
              <Col md={4}>
                <div className='text-left fs-14'><span>&#8805;</span>110% x target</div>
              </Col>
            </Row>

            <div className='my-20'>
              <Row>
                <Col md={2}>
                  <div className='circle-safe' />
                </Col>
                <Col md={4}>
                  <div className='pr-10 text-left fs-14 -indent-0.5'>100% x target <span>&#8805;</span></div>
                </Col>
                <Col md={2}>
                  <div className='pr-10 text-left fs-14'>Actual</div>
                </Col>
                <Col md={4}>
                  <div className='text-left fs-14'>{"<"}110% x target</div>
                </Col>
              </Row>
            </div>
            <div className='my-20'>
              <Row>
                <Col md={2}>
                  <div className='circle-warning' />
                </Col>
                <Col md={4}>
                  <div className='pr-10 text-left fs-14'>90% x target <span>&#8805;</span></div>
                </Col>
                <Col md={2}>
                  <div className='pr-10 text-left fs-14'>Actual</div>
                </Col>
                <Col md={4}>
                  <div className='text-left fs-14'><span>&#8804;</span>100% x target</div>
                </Col>
              </Row>
            </div>
            <div className='my-20'>
              <Row>
                <Col md={2}>
                  <div className='circle-danger' />
                </Col>
                <Col md={4}>
                  <div className='pr-10 text-left fs-14'>0% x target <span>&#8805;</span></div>
                </Col>
                <Col md={2}>
                  <div className='pr-10 text-left fs-14'>Actual</div>
                </Col>
                <Col md={4}>
                  <div className='text-left fs-14'><span>&#8804;</span>90% x target</div>
                </Col>
              </Row>
            </div>
          </div>
          <div className='my-10' style={{ border: "1px solid #E5E6E6" }} />
          <div className='content-modal'>
            <div className='mb-20 fs-16 fw-bold'>Statistic</div>
            <Row>
              <Col md={2}>
                <div className='circle-safe' />
              </Col>
              <Col md={4}>
              </Col>
              <Col md={2}>
                <div className='pr-10 text-left fs-14'>Actual</div>
              </Col>
              <Col md={4}>
                <div className='text-left fs-14'><span>&#8805;</span>100% x target</div>
              </Col>
            </Row>

            <div className='my-20'>
              <Row>
                <Col md={2}>
                  <div className='circle-warning' />
                </Col>
                <Col md={4}>
                  <div className='pr-10 text-left fs-14'>90% x target <span>&#8805;</span></div>
                </Col>
                <Col md={2}>
                  <div className='pr-10 text-left fs-14'>Actual</div>
                </Col>
                <Col md={4}>
                  <div className='text-left fs-14'>{"<"}100% x target</div>
                </Col>
              </Row>
            </div>
            <div className='my-20'>
              <Row>
                <Col md={2}>
                  <div className='circle-danger' />
                </Col>
                <Col md={4}>
                  <div className='pr-10 text-left fs-14'>0% x target <span>&#8805;</span></div>
                </Col>
                <Col md={2}>
                  <div className='pr-10 text-left fs-14'>Actual</div>
                </Col>
                <Col md={4}>
                  <div className='text-left fs-14'>{"<"}90% x target</div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardInformation;
