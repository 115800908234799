import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUsername } from 'utils/helper';
import { apiUrls } from 'utils/request';
import { v4 as uuidv4 } from 'uuid';
import { addUuid, shortData } from './helpers';

const initialState = {
  records: [],
  dataCreate: {
    listCP: [],
    listCategory: [],
    originalList: [],
  },
  loading: false,
  error: {},
  recordsTotal: 0,
  showPopup: {
    children: {},
    isOpen: false,
    onchange: {},
  },
  detailAdmin: [],
  detailActualPort: [],
  romActualUserListOptions: [],
};

const API_CATEGORY = `${apiUrls.romActualPortCategory}`;
const API_ANSWER = `${apiUrls.romActualPortAnswer}`;

export const fetchDetailActualPort = createAsyncThunk(
  'ROMP2H/actual-port-rom/detail',
  async (id) => {
    const response = await axios.get(`${apiUrls.actualRom}/${id}`);
    return response.data;
  }
);

export const getCategoryByFilter = createAsyncThunk(
  "ROMP2H/category/getByFilter",
  async (params, thunkAPI) => {
    const response = await axios.post(`${API_CATEGORY}/filter`, {
      columns: [],
      start: 0
    });
    return response.data;
  }
);

export const getCategoryById = createAsyncThunk(
  "ROMP2H/category/getById",
  async (id, thunkAPI) => {
    const response = await axios.post(`${API_CATEGORY}/${id}`);
    return response.data;
  }
);

export const addCategory = createAsyncThunk(
  "ROMP2H/category/add",
  async (params, thunkAPI) => {
    const response = await axios.post(`${API_CATEGORY}/add?userName=${getUsername()}`, params);
    return response.data;
  }
);

export const saveCategory = createAsyncThunk(
  "ROMP2H/category/save",
  async (params, thunkAPI) => {
    const renumberSort = (data) => {
      data.categoryList = data.categoryList.map((item, index) => {
        return {
          ...item,
          sort: index,
        };
      });

      return data;
    }
    const newPayload = renumberSort(params);

    const response = await axios.put(`${API_CATEGORY}/save?userName=${getUsername()}`, newPayload);
    return response.data;
  }
);

export const romActualUserList = createAsyncThunk(
  "ROMP2H/user/romActual",
  async (params, thunkAPI) => {
    const response = await axios.get(`${apiUrls.userMember}/user-members`);
    return response.data;
  }
);

export const getDetailAdmin = createAsyncThunk(
  "ROMP2H/answer/getByFilter",
  async (params, thunkAPI) => {
    if (!params.category) {
      delete params.category;
    }
    const response = await axios.post(`${API_ANSWER}/get?userName=${getUsername()}`, params);
    return response.data;
  }
);

export const saveAnswer = createAsyncThunk(
  "ROMP2H/answer/save",
  async (params, thunkAPI) => {
    const response = await axios.post(`${API_ANSWER}/save?userName=${getUsername()}`, params);
    return response.data;
  }
);

export const getMasterDataListRom = createAsyncThunk(
  "ROMP2H//rom",
  async (params) => {
    try {
      const response = await axios.post(`${apiUrls.mdRom}/filter`, {
        columns: [
          {
            data: "id",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: false,
          },
          {
            data: "port",
            orderable: true,
            search: {
              regex: true,
              value: "",
            },
            searchValue: params.port || "",
            searchable: true,
          },
          {
            data: "rom",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "designCapacity",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.designCapacity || "",
            searchable: true,
          },
          {
            data: "bibNotes",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "entityStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.status,
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: true,
              value: params.statusRegex || "",
            },
            searchValue: params.dataStatus || "",
            searchable: true,
          },
        ],
        search: {
          regex: false,
          value: params.query || "",
        },
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params?.recordLength ? params.recordLength : -1,
        start: 0,
      });

      console.log('😒 | getMasterDataListRom response?.data:', response?.data);
      return response?.data;
    } catch (err) {
      console.error('Error in getMasterDataListRom:', err);
      throw err;
    }
  }
);


const ROMP2H = createSlice({
  name: "ROMP2H",
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = {};
    },
    resetPopup: (state) => {
      state.showPopup = {
        children: {},
        isOpen: false,
        onchange: {},
      };
    },
    setPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    insertNewCategory: (state, action) => {
      let listCategory = state.dataCreate.listCategory;
      listCategory.forEach(e => e.sort += 1)
      state.dataCreate.listCategory = listCategory

      const categoryTemplate = {
        id: null,
        uuid: uuidv4(),
        isNew: true,
        sort: 0,
        name: `New Category`,
        refId: null,
        entityStatus: 1,
        disableRow: false,
        question: [],
      };
      state.dataCreate.listCategory.unshift(categoryTemplate);
    },
    deleteNewCategory: (state, action) => {
      const uuid = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === uuid);
      if (index !== -1) {
        state.dataCreate.listCategory.splice(index, 1);
      }
    },
    resetNewData: (state) => {
      state.dataCreate = {
        listCP: [],
        listCategory: [],
      };
    },
    insertListCP: (state, action) => {
      state.dataCreate.listCP = action.payload;
    },
    handleDragDrop: (state, action) => {
      const { listCategory } = action.payload;
      state.dataCreate.listCategory = listCategory;
    },
    handleDragDropQuestion: (state, action) => {
      const { categoryUuid, listQuestion } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        state.dataCreate.listCategory[index].question = listQuestion;
      }
    },
    insertQuestion: (state, action) => {
      const { uuid } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === uuid);
      if (index !== -1) {
        let listQuestion = state.dataCreate.listCategory[index].question;
        listQuestion.forEach(e => e.sort += 1)
        state.dataCreate.listCategory[index].question = listQuestion

        const questionTemplate = {
          disableRow: false,
          entityStatus: 1,
          id: null,
          isNew: false,
          name: 'Question',
          rom: '',
          sort: 0,
          typeAnswer: 0,
          uuid: uuidv4(),
        };
        state.dataCreate.listCategory[index].question.unshift(questionTemplate);
      }
    },
    handleDeleteQuestion: (state, action) => {
      const { categoryUuid, questionUuid } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        const questionIndex = state.dataCreate.listCategory[index].question.findIndex((item) => item.uuid === questionUuid);
        if (questionIndex !== -1) {
          state.dataCreate.listCategory[index].question.splice(questionIndex, 1);
        }
      }
    },
    replaceQuestion: (state, action) => {
      const { categoryUuid, questionUuid, question } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        const questionIndex = state.dataCreate.listCategory[index].question.findIndex((item) => item.uuid === questionUuid);
        if (questionIndex !== -1) {
          state.dataCreate.listCategory[index].question[questionIndex].name = question;
        }
      }
    },
    replaceAnswer: (state, action) => {
      const { categoryUuid, questionUuid, typeAnswer } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        const questionIndex = state.dataCreate.listCategory[index].question.findIndex((item) => item.uuid === questionUuid);
        if (questionIndex !== -1) {
          state.dataCreate.listCategory[index].question[questionIndex].typeAnswer = typeAnswer;
        }
      }
    },
    replaceCategory: (state, action) => {
      const { categoryUuid, category } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        state.dataCreate.listCategory[index].name = category;
      }
    },
    handleInsertQuestionAdmin: (state, action) => {
      const { categoryId, questionId, answerId, parentData, nik, inputName, inputValue } = action.payload;

      const index = state.detailAdmin.findIndex((item) => item?.id === categoryId);
      if (index !== -1) {
        const questionIndex = state.detailAdmin[index]?.question.findIndex((item) => item?.id === questionId);
        if (questionIndex !== -1) {
          state.detailAdmin = state.detailAdmin.map((item) => {
            if (item.id === categoryId) {
              return {
                ...item,
                question: item.question.map((itemQuestion) => {
                  if (itemQuestion.id === questionId) {
                    const newAnswer = {
                      category: item?.name,
                      checkbox: itemQuestion?.typeAnswer,
                      documentList: [],
                      questionId,
                      freetext: '',
                    };

                    const isNewAnswer = itemQuestion?.answer === null;
                    let tempAnswer = isNewAnswer ? newAnswer : itemQuestion.answer;
                    const isInputNameOperator = inputName === 'operator';

                    if (isInputNameOperator) {
                      tempAnswer = {
                        ...tempAnswer,
                        nik: nik || ''
                      };
                    }

                    return {
                      ...itemQuestion,
                      answer: {
                        ...tempAnswer,
                        questionId,
                        [inputName]: inputValue,
                        // updatedBy: getUsername(),
                        // updatedAt: new Date().toISOString(),
                      }
                    };
                  }
                  return itemQuestion;
                })
              };
            }
            return item;
          });
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMasterDataListRom.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMasterDataListRom.fulfilled, (state, action) => {
      // const filterStatusActive = (data) => {
      //   const STATUS_ACTIVE = 1;
      //   return data.filter((item) => item.entityStatus === STATUS_ACTIVE);
      // }
      console.log('😒 | getMasterDataListRom:', action.payload);
      // state.loading = false;
      state.records = action.payload?.list;
      // state.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(getMasterDataListRom.rejected, (state, action) => {
      state.loading = false;
      state.error.getMasterDataListRom = action.error;
    });
    builder.addCase(getCategoryById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategoryById.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      console.log('😒 | dataCC:', shortData(addUuid(data)));
      state.dataCreate.listCategory = shortData(addUuid(data));
      state.dataCreate.originalList = shortData(addUuid(data));
    });
    builder.addCase(getCategoryById.rejected, (state, action) => {
      state.loading = false;
      state.error.getCategoryById = action.error;
    });
    builder.addCase(addCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCategory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addCategory.rejected, (state, action) => {
      state.loading = false;
      state.error.addCategory = action.error;
    });
    builder.addCase(saveCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveCategory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(saveCategory.rejected, (state, action) => {
      state.loading = false;
      state.error.saveCategory = action.error;
    });
    builder.addCase(romActualUserList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(romActualUserList.fulfilled, (state, action) => {
      state.loading = false;
      const romActualUserList = action.payload.filter((item) => item.entityName === "RomActual");
      state.romActualUserListOptions = romActualUserList.map((item) => ({
        value: item.name,
        label: item.name,
        ...item,
      }));
    });
    builder.addCase(romActualUserList.rejected, (state, action) => {
      state.loading = false;
      state.error.romActualUserList = action.error;
    });
    builder.addCase(getDetailAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDetailAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.detailAdmin = action.payload;
    });
    builder.addCase(getDetailAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error.getDetailAdmin = action.error;
    });
    builder.addCase(fetchDetailActualPort.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDetailActualPort.fulfilled, (state, action) => {
      state.loading = false;
      state.detailActualPort = action.payload;
    });
    builder.addCase(fetchDetailActualPort.rejected, (state, action) => {
      state.loading = false;
      state.error.fetchDetailActualPort = action.error;
    });
  }
});

export const {
  resetPopup,
  resetError,
  setPopup,
  insertNewCategory,
  resetNewData,
  insertListCP,
  deleteNewCategory,
  insertQuestion,
  handleDeleteQuestion,
  replaceQuestion,
  replaceAnswer,
  replaceCategory,
  handleDragDrop,
  handleDragDropQuestion,
  handleInsertQuestionAdmin,
  insertPayloadParentData,
  getAllAnswer
} = ROMP2H.actions;
export default ROMP2H.reducer;
