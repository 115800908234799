import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';

var _ = require('lodash');
const serviceName = 'confJetty';

export const initialState = {
  confJetty: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  confJettyMaster: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  confJettyDetail: {
    loading: false,
    records: {
      detail: [],
      statusLogList: [{ dataStatus: 'DRAFT' }],
      documentList: [],
      createdAt: '',
    },
    recordsTotal: '',
    error: '',
  },
  confJettyLatest: {
    loading: false,
    records: [],
    error: '',
  },
};

export const getconfJettyData = createAsyncThunk(
  `${serviceName}/get`,
  async (params) => {
    const response = await post(apiUrls.confJetty.concat(`filter`), {
      columns: [
        {
          data: 'reportId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: true,
        },
        {
          data: 'bibNotes',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params?.dataStatus === DATASOURCE_VALUE.waitingApproval || params?.dataStatus >= 20 ? true : false,
            value: '',
          },
          searchValue: params.dataStatus,
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.date,
          searchable: true,
        },
      ],
      order: [],
      search: {
        regex: false,
        value: params.query || '',
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.dataLength ? params.dataLength : -1,
      start: 0,
    });
    return response?.data;
  }
);

// export const getconfJettyDetail = createAsyncThunk('confJettyDetail/get', async (params) => {
//   const response = await get(apiUrls.confJetty.concat(`${params}`));
//   return response?.data;
// });

export const getDetailService = createAsyncThunk(
  `${serviceName}/detail`,
  async (params) => {
    const { id } = params;
    const response = await get(`${apiUrls.confJetty}${id}`);
    return response?.data;
  }
);

export const getLatestJettyConfig = createAsyncThunk(
  `${serviceName}/latest-jetty-config`,
  async (params) => {
    const response = await get(`${apiUrls.confJetty}/latest-detail`);
    return response?.data;
  }
);

export const addService = createAsyncThunk(
  `${serviceName}/add`,
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.confJetty}save`, data);
    return response.data;
  }
);

export const saveService = createAsyncThunk(
  `${serviceName}/save`,
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.confJetty}save`, data);
    return response.data;
  }
);

export const submitService = createAsyncThunk(
  `${serviceName}/submit`,
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.confJetty}submit`, data);
    return response.data;
  }
);

export const uploadFileService = createAsyncThunk(
  `${serviceName}/uploadFile`,
  async (param) => {
    const { id, file } = param;
    const response = await post(`${apiUrls.confJetty}${id}/files`, file, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);

export const deleteFileService = createAsyncThunk(
  `${serviceName}/deleteFile`,
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.confJetty}${id}/files/${name}`
    );
    return response.data;
  }
);

export const updateDataService = createAsyncThunk(
  `${serviceName}/adminUpdate`,
  async (param) => {
    const { id, action, username, data } = param;
    const response = await put(
      `${apiUrls.confJetty}${id}/${action}?userName=${username}`,
      data
    );
    return response.data;
  }
);

export const getMasterData = createAsyncThunk(
  `${serviceName}/master`,
  async (params) => {
    const response = await post(apiUrls.mdPortJetty.concat(`/filter`), {
      draw: 1,
      length: -1,
      start: 0,
    });
    var filtered = response.data.list.map((v) => {
      return {
        jettyLoading: v,
        productivityTarget: 0,
        bib: {
          eu: 0,
          ma: 0,
          pa: 0,
          ua: 0,
        },
        kepmen1827: {
          eu: 0,
          ma: 0,
          pa: 0,
          ua: 0,
        },
      };
    });
    response.data.list = filtered;
    return response?.data;
  }
);

export const intervensiConfJetty = createAsyncThunk(
  `${serviceName}/intervensi`,
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.intervensiConfigPort}/user-role?moduleName=Jetty`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const ConfJetty = createSlice({
  name: 'CONF_JETTY',
  initialState,
  reducers: {
    onChangeTableInput: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confJettyMaster.records[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },
    onChangeTableInputDetail: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confJettyDetail.records.detail[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },
    onReset: (state, action) => {
      state.confJettyDetail = {
        loading: false,
        records: {
          detail: [],
          statusLogList: [{ dataStatus: 'DRAFT' }],
          documentList: [],
          createdAt: '',
        },
        recordsTotal: '',
        error: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getconfJettyData.pending, (state, action) => {
      state.confJetty.loading = true;
    });
    builder.addCase(getconfJettyData.fulfilled, (state, action) => {
      state.confJetty.records = action.payload.list;
      state.confJetty.recordsTotal = action.payload.recordsTotal;
      state.confJetty.loading = false;
    });
    builder.addCase(getconfJettyData.rejected, (state, action) => {
      state.confJetty.loading = false;
      state.confJetty.error = 'Invalid get data';
    });

    builder.addCase(getDetailService.pending, (state, action) => {
      state.confJettyDetail.loading = true;
    });
    builder.addCase(getDetailService.fulfilled, (state, action) => {
      let currentConfJettyDetail = current(state.confJettyDetail.records);
      if (!currentConfJettyDetail?.id) {
        state.confJettyDetail.records = action.payload;
      } else {
        state.confJettyDetail.records = {
          ...action.payload,
          detail: currentConfJettyDetail.detail,
        };
      }
    });
    builder.addCase(getDetailService.rejected, (state, action) => {
      state.confJettyDetail.loading = false;
      state.confJettyDetail.error = 'Invalid get data';
    });

    builder.addCase(getMasterData.pending, (state, action) => {
      state.confJettyMaster.loading = true;
    });
    builder.addCase(getMasterData.fulfilled, (state, action) => {
      state.confJettyMaster.records = action.payload.list;
      state.confJettyMaster.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterData.rejected, (state, action) => {
      state.confJettyMaster.loading = false;
      state.confJettyMaster.error = 'Invalid get data';
    });

    builder.addCase(getLatestJettyConfig.pending, (state, action) => {
      state.confJettyLatest.loading = true;
    });
    builder.addCase(getLatestJettyConfig.fulfilled, (state, action) => {
      state.confJettyLatest.loading = false;
      state.confJettyLatest.records = action.payload;
    });
    builder.addCase(getLatestJettyConfig.rejected, (state, action) => {
      state.confJettyLatest.loading = false;
      state.confJettyLatest.error = 'Invalid get data';
    });
  },
});

export const { onChangeTableInput, onChangeTableInputDetail, onReset } =
  ConfJetty.actions;
export const confJettyDataSelector = (state) => state.ConfJetty.confJetty;
export const confJettyDetailSelector = (state) =>
  state.ConfJetty.confJettyDetail;
export const confJettyMaster = (state) => state.ConfJetty.confJettyMaster;
export const confJettyLatest = (state) => state.ConfJetty.confJettyLatest;

export default ConfJetty.reducer;
