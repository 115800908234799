import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, InputGroup } from 'react-bootstrap';
import { isArray } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import {
  userMemberCrushingSelector,
  userMemberBlcSelector,
  userMemberRomSelector
} from "../containers/Configuration/UserConfig/reducer";
import {
  SearchIcon,
  DeleteCloseIconLarge,
  RefreshIcon,
} from "assets/icons";
import {
  Table,
  Modal,
} from "components";
import { saveAttendance as saveAttendanceCrushing } from "containers/Port/CrushingActualAdmin/reducer";
import { saveAttendance as saveAttendanceRom } from "containers/Port/RomActualAdmin/reducer";
import { formatLastSavedOperator, getUsername } from "utils/helper";

const ModalOperatorPort = ({
  showOperator = false,
  setShowOperator = () => { },
  entityActive = "crushing",
  fetchData = () => { },
  fetchDataAttendance = () => { },
  query = "",
  currentLength = 10,
  setCurrentLength = () => { },
  setPageIndex = () => { },
  handleSearch = () => { }
}) => {
  const dispatch = useDispatch()

  const { records: dataMemberBlc, recordsTotal: recordsTotalBlc, loading: loadingMember } = useSelector(userMemberBlcSelector);
  const { records: dataMemberCrushing, recordsTotal: recordsTotalCrushing } = useSelector(userMemberCrushingSelector);
  const { records: dataMemberRom, recordsTotal: recordsTotalRom } = useSelector(userMemberRomSelector);

  const [resetIndex, setResetIndex] = useState(false);
  const [isCheckOut, setIsCheckOut] = useState(false);

  const checkOutAttendance = (data) => {
    setIsCheckOut(true);

    const obj = {
      name: data.name,
      shift: data.shift,
      activity: false,
      time: moment().format("HH:mm"),
      refId: data.refId,
      entityName: data.entityName,
      id: data.id
    }

    const saveAttendance = entityActive === "rom" ? saveAttendanceRom : saveAttendanceCrushing

    dispatch(saveAttendance({ options: obj, userName: getUsername() })).then((e) => {
      setIsCheckOut(false);
      fetchData();

      if (isArray(e.payload)) {
        return toast.success('Operator check out success', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }

      return toast.error(e?.payload?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Member',
        accessor: 'name',
      },
      {
        Header: 'Last Update',
        accessor: 'updatedAt',
        Cell: ({ row: { original } }) => formatLastSavedOperator(original?.updatedAt)
      },
      {
        Header: 'Update By',
        accessor: "updatedBy"
      },
      {
        Header: 'Status',
        accessor: "detail"
      },
      {
        Header: 'CP/CV',
        accessor: "location"
      },
      {
        Header: 'Action',
        accessor: '',
        disableSortBy: true,
        Cell: ({ row: { original } }) =>
          (original.detail && original.detail === "CheckIn") && (
            <div className="">
              <button
                className="btn btn-primary"
                onClick={() => checkOutAttendance(original)}
                disabled={isCheckOut}
              >
                Check Out
              </button>
            </div>
          ),
      }
    ]
  );

  const onResetIndex = (val) => setResetIndex(val);
  const changePage = (val) => setCurrentLength(val);
  const changeIndex = (val) => setPageIndex(val);

  const checkEntity = (crushing, blc, rom, defaultValue) => {
    return entityActive === "crushing" ? crushing :
      entityActive === "blc" ? blc :
        entityActive === "rom" ? rom : defaultValue
  }

  const tableConfig = {
    data: checkEntity(dataMemberCrushing, dataMemberBlc, dataMemberRom, []),
    className: `primary`,
    columns: columns,
    totalCount: checkEntity(recordsTotalCrushing, recordsTotalBlc, recordsTotalRom, 0),
    pageSize: currentLength,
    pageCount: Math.ceil(checkEntity(recordsTotalCrushing, recordsTotalBlc, recordsTotalRom, 0) / currentLength),
    manualPagination: true,
    changePage,
    changeIndex,
    resetIndex,
    onResetIndex,
    loadingFetch: loadingMember
  };


  return (
    <>
      <Modal title="" showDialog={showOperator} showHeader={false} className="long-term-modal operator-modal">
        <div className="modal-header" style={{ marginBottom: '0px' }}>
          <h3 className="fs-16 mb-0 fw-500">Operator</h3>
          <div className="wrap-button">
            <DeleteCloseIconLarge style={{ borderRadius: "50%", cursor: "pointer" }} onClick={() => setShowOperator(false)} />
          </div>
        </div>

        <div className="content">
          <div style={{
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
            <div style={{
              width: "36px",
              height: "36px",
              border: "1px solid #E5E6E6",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <RefreshIcon style={{ cursor: "pointer" }} onClick={() => fetchDataAttendance()} />
            </div>
            <div style={{ paddingRight: "1rem" }}>
              <InputGroup className={""} style={{ border: "1px solid #E5E6E6", margin: "0.5rem 0 0 1rem" }}>
                <InputGroup.Text className="input-icon bg-white border-white">
                  <SearchIcon />
                </InputGroup.Text>
                <Form.Control
                  className="border-white"
                  type="text"
                  placeholder="Search"
                  defaultValue={query}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </InputGroup>
            </div>
          </div>

          <div className="wrapper-table mb-10 px-10">
            <Table {...tableConfig} />
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ModalOperatorPort;
