import { useCallback, useMemo, useRef } from 'react';
import {
  Table,
} from 'components';
import { shortMonthList } from 'utils/constants';

const OtherDelayDetail = ({
  getValues = () => { },
  setValue = () => { },
  handleInputChangeField = () => { },
  control = null,
  ref = null,
  cursor = null,
  isAdd = true
}) => {
  let editableKeyToFocus = useRef();

  const renderCell = useCallback((cellInfo) => {
    const name = `otherDelay[${cellInfo.column.id}]`;
    const header = cellInfo.column.id;
    const listMonth = getValues("otherDelay");
    let fullYear = 0;

    if (listMonth) listMonth.map((val, index) => {
      if (listMonth[index]?.value) {
        fullYear += parseFloat(listMonth[index]?.value.replace(",", "."))
      }
    })

    return (
      <div>{header === "fullYear" ? fullYear.toFixed(2) : getValues(name)?.value}</div>
    );
  }, [])

  const renderTableEditableCell = useCallback(
    (cellInfo) => {
      const name = `otherDelay[${cellInfo.column.id}]`;
      let currentValue = getValues(name)
      let otherDelay = getValues("otherDelay").map(e => { return { ...e } })

      return (
        <div>
          <input
            name={name}
            className="form-control p-0 text-right"
            type={"text"}
            control={control}
            value={currentValue?.value}
            placeholder={0}
            ref={ref}
            disabled={!isAdd}
            onChange={(e) => {
              const keyDown = e.nativeEvent.data;
              if (!isNaN(keyDown) && keyDown !== " ") {
                currentValue.value = e.target.value
                otherDelay[cellInfo.column.id] = currentValue

                setValue(`otherDelay`, otherDelay)
                handleInputChangeField(name, e, true, cellInfo.column.id)
                editableKeyToFocus.current = name;
              }
            }}
            autoFocus={editableKeyToFocus.current === name}
          />
        </div>
      );
    },
    [control, handleInputChangeField, getValues, ref, cursor]
  );

  const OtherDelayColumn = useMemo(() => {
    let column = shortMonthList.map((item, index) => {
      return {
        accessor: item,
        Header: item,
        id: index.toString(),
        disableSortBy: true,
        maxWidth: 50,
        Cell: renderTableEditableCell,
      };
    });

    column.push({
      accessor: "fullYear",
      Header: (<div style={{ color: "#FF0C2B" }}>Full Year</div>),
      disableSortBy: true,
      maxWidth: 50,
      Cell: renderCell,
    })
    return [
      {
        Header: '',
        accessor: 'header',
        disableSortBy: true,
        maxWidth: 100,
      },
      ...column,
    ];
  }, [renderCell]);

  const OtherDelayData = useMemo(() => {
    const data = {
      header: 'Other Delay Hours',
    };
    shortMonthList.forEach((item, index) => {
      data[item] = '';
    });
    return data;
  }, []);

  const tableConfig = {
    columns: OtherDelayColumn,
    data: [OtherDelayData],
    className: 'primary no-flex',
    showEntries: false,
    responsive: false,
    showPagination: false,
    showEmpetyText: false,
  };

  return (
    <>
      <div className="title">Working Hours</div>
      <div className='pt-20'>
        <Table {...tableConfig} />
      </div>
    </>
  );
};

export default OtherDelayDetail;
