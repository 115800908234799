import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, Accordion, InputGroup } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { debounce } from "lodash";
import {
  addDataTableMember,
  deleteUserMember,
  getUserMemberFilter,
  onChangeTableMember,
  onRemoveDataTableMember,
  submitUserMember,
  userMemberCrushingSelector,
  userMemberBlcSelector,
  userMemberJettySelector,
  userMemberRomSelector,
} from "../containers/Configuration/UserConfig/reducer";
import {
  DeleteIcon,
  SearchIcon,
  AddIcon,
  SubmitIcon,
  DeleteRedIcon,
} from "assets/icons";
import {
  Button,
  Table,
  Modal,
} from "components";
import {
  masterDataIdChecker,
} from "utils/helper";
import { AddRow } from "assets/icons";

const MemberTable = ({
  loading = false,
  entityActive = "CrsuhingActual",
  setLoading = () => { }
}) => {
  const dispatch = useDispatch()

  const { records: CrushingActual, loading: loadingMember, recordsTotal: recordsTotalCrushing } = useSelector(userMemberCrushingSelector);
  const { records: BlcActual, recordsTotal: recordsTotalBlc } = useSelector(userMemberBlcSelector);
  const { records: JettyActual, recordsTotal: recordsTotalJetty } = useSelector(userMemberJettySelector);
  const { records: RomActual, recordsTotal: recordsTotalRom } = useSelector(userMemberRomSelector);

  const schema = yup.array(
    yup.object({
      company: yup.string().nullable().required("Company is required"),
      name: yup.string().nullable().required("Member Name is required"),
    })
  );

  const {
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [isEdit, setIsEdit] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [memberIdChanged, setMemberIdChanged] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteObj, setDeleteObj] = useState(false)
  const [resetIndex, setResetIndex] = useState(false);
  const [currentLength, setCurrentLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [queryCrushing, setQueryCrushing] = useState("")
  const [queryBlc, setQueryBlc] = useState("")
  const [queryJetty, setQueryJetty] = useState("")
  const [queryRom, setQueryRom] = useState("")

  useEffect(() => {
    handleFetchData(false);
  }, [
    entityActive,
    pageIndex,
    currentLength,
  ]);

  useEffect(() => {
    handleFetchData(true);
  }, [
    queryCrushing,
    queryBlc,
    queryJetty,
    queryRom
  ]);

  const handleFetchData = (changeQuery = false) => {
    let objCrushing = { entityName: "CrsuhingActual" },
      objBlc = { entityName: "LoadingBlc" },
      objJetty = { entityName: "LoadingJetty" },
      objRom = { entityName: "RomActual" }

    if (queryCrushing) objCrushing["query"] = queryCrushing;
    if (currentLength) objCrushing["recordLength"] = currentLength;

    if (pageIndex && !changeQuery) objCrushing["pageIndex"] = pageIndex;
    else objCrushing["pageIndex"] = 1;

    if (queryBlc) objBlc["query"] = queryBlc;
    if (currentLength) objBlc["recordLength"] = currentLength;

    if (pageIndex && !changeQuery) objBlc["pageIndex"] = pageIndex;
    else objBlc["pageIndex"] = 1;

    if (queryJetty) objJetty["query"] = queryJetty;
    if (currentLength) objJetty["recordLength"] = currentLength;

    if (pageIndex && !changeQuery) objJetty["pageIndex"] = pageIndex;
    else objJetty["pageIndex"] = 1;

    if (queryRom) objRom["query"] = queryRom;
    if (currentLength) objRom["recordLength"] = currentLength;

    if (pageIndex && !changeQuery) objRom["pageIndex"] = pageIndex;
    else objRom["pageIndex"] = 1;

    if (entityActive === "CrsuhingActual") dispatch(getUserMemberFilter(objCrushing));
    else if (entityActive === "LoadingBlc") dispatch(getUserMemberFilter(objBlc));
    else if (entityActive === "LoadingJetty") dispatch(getUserMemberFilter(objJetty));
    else if (entityActive === "RomActual") dispatch(getUserMemberFilter(objRom));
  };

  const handleInputChangeField = (cellInfo, value) => {
    resetErrorField(cellInfo.rows)

    var changedMember = false;

    if (cellInfo.row.original.id) {
      var memberIdObj = {
        id: cellInfo.row.original.id,
      };

      var memberIdArr = memberIdChanged;

      if (memberIdArr.length == 0) {
        memberIdArr.push(memberIdObj);
        setMemberIdChanged(memberIdArr);
        changedMember = true;
      } else {
        for (var i of memberIdArr) {
          if (i.id == cellInfo.row.original.id) {
            changedMember = true;
          }
        }
      }

      if (!changedMember) {
        memberIdArr.push(memberIdObj);
        setMemberIdChanged(memberIdArr);
      }
    }

    const rowId = cellInfo.row.id;
    const rowIdSplice = rowId.split(".");
    const rowIndex = parseInt(rowIdSplice[0]);

    if (cellInfo.column.id === "entityStatus") value = value ? 1 : 2

    const object = {
      tableIndexRow: rowIndex,
      subRowIndex: cellInfo.row.index,
      tableRowName: cellInfo.column.id,
      value,
      entity: entityActive,
    };

    dispatch(onChangeTableMember(object));
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);

    dispatch(deleteUserMember([deleteObj.dataOriginal])).then(e => {
      if (e.payload.data.message) {
        toast.error(e.payload.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      }
      else {
        toast.success("Successfully Delete Member", {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      }
      setLoading(false);
      setIsEdit(false);
    }).then(() => {
      setShowDeleteModal(false);
      handleFetchData();
    }).catch(err => {
      toast.error(err, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      setShowDeleteModal(false);
      handleFetchData();
      setLoading(false);
      setIsEdit(false);
    })
  };

  const handleDeleteRow = (cellInfo) => {
    resetErrorField(cellInfo.rows)

    const rowId = cellInfo.row.id;
    const rowIdSplice = rowId.split(".");
    const rowIndex = parseInt(rowIdSplice[0]);

    const object = {
      tableIndexRow: rowIndex,
      subRowIndex: cellInfo.row.index,
      tableRowName: cellInfo.column.id,
      dataOriginal: cellInfo.row.original,
      entity: entityActive
    };

    if (cellInfo.row.original.isNew) {
      dispatch(onRemoveDataTableMember(object));
      return;
    }

    setDeleteObj(object);
    setShowDeleteModal(true);
  };

  const renderEditableInput = (cellInfo) => {
    const isHeaderNIK = cellInfo.column.Header === "NIK";
    const id = cellInfo.column.id;
    const rowId = cellInfo.cell.row.id;
    const type = isHeaderNIK ? "number" : "text";

    return (
      <>
        <Form.Control
          className={`form-control p-9 input-${rowId}-${id}`}
          style={
            cellInfo.row.original.dataStatus === 1
              ? { color: "#007AFF" }
              : { color: "#000" }
          }
          name="input"
          type={type}
          onChange={(e) => {
            if (isHeaderNIK && e.target.value.length > 16) {
              toast.error("NIK must be 16 digits", {
                position: toast.POSITION.BOTTOM_RIGHT,
              })
              return;
            }

            handleInputChangeField(cellInfo, e.target.value);
          }
          }
          value={cellInfo.value}
          placeholder={`Input ${cellInfo.column.Header}`}
        />
        <div className={`sdp-error sdp-${rowId}-${id} d-none`}>
          Field is required!
        </div>
        <div className={`sdp-error sdp-${rowId}-${id}duplicate d-none`}>
          Field is duplicated!
        </div>
      </>
    );
  };

  const renderCell = (cellInfo) => {
    if (cellInfo.column.id == "no" && cellInfo.row.original.isNew) {
      return <span className="l-border-red">{cellInfo.row.index + 1}</span>;
    }
    else if (cellInfo.column.id == "no") {
      return <span>{cellInfo.row.index + 1}</span>;
    }

    return <span>{cellInfo?.value}</span>;
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "no",
        className: "text-center d-flex",
        disableSortBy: true,
        width: 20,
        style: {
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: renderCell,
      },
      {
        Header: "Name",
        accessor: "name",
        disableSortBy: true,
        Cell: isEdit ? renderEditableInput : renderCell,
      },
      {
        Header: "Company",
        accessor: "company",
        disableSortBy: true,
        Cell: isEdit ? renderEditableInput : renderCell,
      },
      {
        Header: "NIK",
        accessor: "nik",
        disableSortBy: true,
        Cell: isEdit ? renderEditableInput : renderCell,
      },
      {
        Header: "Status",
        accessor: "entityStatus",
        className: "d-flex",
        disableSortBy: true,
        width: 40,
        style: {
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (cellInfo) => (
          <Form.Check
            type="switch"
            id="custom-switch"
            className="d-flex justify-content-evenly"
            style={{ gap: "1rem" }}
            label={
              cellInfo.row.original.entityStatus === "ACTIVE" || cellInfo.row.original.entityStatus === 1
                ? "Active" : "Inactive"
            }
            checked={
              cellInfo.row.original.entityStatus === "ACTIVE" || cellInfo.row.original.entityStatus === 1
                ? true : false}
            onChange={(e) => {
              if (isEdit)
                handleInputChangeField(cellInfo, e.target.checked);
            }}
          />
        ),
      },
      {
        Header: ``,
        accessor: "id",
        disableSortBy: true,
        width: 20,
        className: "text-end pr-10 d-flex",
        style: {
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (cellInfo) => {
          if (isEdit) {
            return (
              <div className="action justify-content-end">
                <Button
                  className="btn btn-table"
                  style={{ marginTop: 0 }}
                  onClick={() => handleDeleteRow(cellInfo)}
                >
                  <DeleteIcon />
                </Button>
              </div>
            );
          }
        },
      },
    ],
    [isEdit]
  );

  const checkEntity = (crushing, blc, jetty, rom, defaultValue) => {
    return entityActive === "CrsuhingActual" ? crushing :
      entityActive === "LoadingBlc" ? blc :
        entityActive === "LoadingJetty" ? jetty :
          entityActive === "RomActual" ? rom : defaultValue
  }

  const onResetIndex = (val) => setResetIndex(val);
  const changePage = (val) => setCurrentLength(val);
  const changeIndex = (val) => setPageIndex(val);

  const tableConfig = {
    columns,
    className: `primary ${isEdit ? "first-border" : ""}`,
    data: checkEntity(CrushingActual, BlcActual, JettyActual, RomActual, []),
    totalCount: checkEntity(recordsTotalCrushing, recordsTotalBlc, recordsTotalJetty, recordsTotalRom, 0),
    pageSize: currentLength,
    pageCount: Math.ceil(checkEntity(recordsTotalCrushing, recordsTotalBlc, recordsTotalJetty, recordsTotalRom, 0) / currentLength),
    manualPagination: true,
    changePage,
    changeIndex,
    resetIndex,
    onResetIndex,
    loadingFetch: loadingMember
  };

  const resetErrorField = (data) => {
    data.forEach((item, index) => {
      const name = document.querySelector(`.input-${index}-name`);
      const nameMsg = document.querySelector(`.sdp-${index}-name`);
      const company = document.querySelector(`.input-${index}-company`);
      const companyMsg = document.querySelector(`.sdp-${index}-company`);

      name.classList.remove("has-error");
      nameMsg.classList.add("d-none");

      company.classList.remove("has-error");
      companyMsg.classList.add("d-none");
    });
  }

  const renderErrorField = (data) => {
    data.forEach((item, index) => {
      const name = document.querySelector(`.input-${index}-name`);
      const nameMsg = document.querySelector(`.sdp-${index}-name`);
      const company = document.querySelector(`.input-${index}-company`);
      const companyMsg = document.querySelector(`.sdp-${index}-company`);

      if (!item.name) {
        name.classList.add("has-error");
        nameMsg.classList.remove("d-none");
      } else {
        name.classList.remove("has-error");
        nameMsg.classList.add("d-none");
      }

      if (!item.company) {
        company.classList.add("has-error");
        companyMsg.classList.remove("d-none");
      } else {
        company.classList.remove("has-error");
        companyMsg.classList.add("d-none");
      }
    });
  };

  const handleSave = (action) => {
    setLoading("fetch");

    let dataRow = checkEntity(CrushingActual, BlcActual, JettyActual, RomActual, [])
    let validationAddNew = false;
    let validationEdit = false;
    var dataRowArr = [];

    // Edit
    if (isEdit) {
      dataRow.map((val, index) => {
        if (val.isNew) return dataRowArr.push(val);
      });

      if (dataRowArr.length > 0) {
        try {
          schema.validateSync(dataRowArr);
          validationAddNew = true;
        } catch (err) {
          if (memberIdChanged.length == 0) {
            setLoading(false)
            setShowSubmitModal(false)
            return renderErrorField(dataRowArr);
          }

          renderErrorField(dataRowArr);
        }
      }

      var rowId = memberIdChanged;

      if (memberIdChanged.length > 0) {
        rowId = masterDataIdChecker(dataRow, rowId);

        try {
          schema.validateSync(dataRow);
          validationEdit = true;
        } catch (err) {
          setLoading(false)
          setShowSubmitModal(false)
          return renderErrorField(dataRow);
        }
      }

      if (validationAddNew && validationEdit) {
        if (action == "submit") {
          Promise.all([dispatch(submitUserMember(rowId)), dispatch(submitUserMember(dataRowArr))]).then((e) => {

            e.map(val => {
              if (val.payload.data.message) {
                toast.error(val.payload.data.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                })
              }
              else {
                if (val.meta.arg[0].isNew) {
                  toast.success("Successfully Submit Member", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  })
                }
                else {
                  toast.success("Successfully Edit Member", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  })
                }
              }
            })

            setIsEdit(false);
            setLoading(false)
            handleFetchData();
          })
            .catch(err => toast.error(err, {
              position: toast.POSITION.BOTTOM_RIGHT,
            }))
        }
      }

      if (validationEdit && !validationAddNew) {
        if (action == "submit") {
          dispatch(submitUserMember(rowId)).then((e) => {
            if (e.payload.data.message) {
              toast.error(e.payload.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              })
            }
            else {
              toast.success("Successfully Edit Member", {
                position: toast.POSITION.BOTTOM_RIGHT,
              })
            }

            setIsEdit(false);
            setLoading(false)
            handleFetchData();
          })
            .catch(err => toast.error(err, {
              position: toast.POSITION.BOTTOM_RIGHT,
            }))
        };
      }

      if (validationAddNew && !validationEdit) {
        if (action == "submit") {
          dispatch(submitUserMember(dataRowArr)).then((e) => {
            if (e.payload.data.message) {
              toast.error(e.payload.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              })
            }
            else {
              toast.success("Successfully Submit Member", {
                position: toast.POSITION.BOTTOM_RIGHT,
              })
            }

            setIsEdit(false);
            setLoading(false)
            handleFetchData();
          })
            .catch(err => toast.error(err, {
              position: toast.POSITION.BOTTOM_RIGHT,
            }))
        };
      }

      if (dataRowArr.length == 0 && memberIdChanged.length == 0) {
        setIsEdit(false);
      }
    }
    else {
      setIsEdit(false);
      setLoading(false)
    }

    if (action == "submit") setShowSubmitModal(false);
  };

  const handleSearch = debounce((val) => {
    if (entityActive === "CrsuhingActual") setQueryCrushing(val)
    else if (entityActive === "LoadingBlc") setQueryBlc(val)
    else if (entityActive === "LoadingJetty") setQueryJetty(val)
    else if (entityActive === "RomActual") setQueryRom(val)
  }, 500);

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Member</Accordion.Header>
          <Accordion.Body>
            <div className="d-flex justify-content-between mb-10">
              <div className="d-flex" style={{ gap: "1rem" }}>
                {isEdit && (
                  <Button
                    variant="secondary-outline"
                    style={{ "margin": "0.5rem 0 0 1rem" }}
                    onClick={() => {
                      let dataTable = {
                        name: "",
                        company: "",
                        isNew: true,
                        entityName: entityActive,
                        entityStatus: 1,
                        nik: ""
                      };
                      dispatch(addDataTableMember({
                        data: dataTable,
                        entity: entityActive
                      }));
                    }}
                  >
                    <div className="d-flex"
                      style={{ alignItems: "center", justifyContent: "space-evenly", gap: "0.25rem" }}
                    >
                      <div>
                        <AddRow width={10} style={{ marginRight: "0.5rem" }} />
                      </div>
                      <div style={{ width: "max-content" }}>
                        Add Data
                      </div>
                    </div>
                  </Button>
                )}

                {entityActive === "CrsuhingActual" && (
                  <InputGroup className={isEdit ? "isEdit" : ""} style={{ border: "1px solid #E5E6E6", margin: "0.5rem 0 0 1rem" }}>
                    <InputGroup.Text className="bg-white border-white input-icon">
                      <SearchIcon />
                    </InputGroup.Text>
                    <Form.Control
                      className="border-white"
                      type="text"
                      placeholder="Search"
                      defaultValue={queryCrushing}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </InputGroup>
                )}

                {entityActive === "LoadingBlc" && (
                  <InputGroup className={isEdit ? "isEdit" : ""} style={{ border: "1px solid #E5E6E6", margin: "0.5rem 0 0 1rem" }}>
                    <InputGroup.Text className="bg-white border-white input-icon">
                      <SearchIcon />
                    </InputGroup.Text>
                    <Form.Control
                      className="border-white"
                      type="text"
                      placeholder="Search"
                      defaultValue={queryBlc}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </InputGroup>
                )}

                {entityActive === "LoadingJetty" && (
                  <InputGroup className={isEdit ? "isEdit" : ""} style={{ border: "1px solid #E5E6E6", margin: "0.5rem 0 0 1rem" }}>
                    <InputGroup.Text className="input-icon bg-white border-white">
                      <SearchIcon />
                    </InputGroup.Text>
                    <Form.Control
                      className="border-white"
                      type="text"
                      placeholder="Search"
                      defaultValue={queryJetty}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </InputGroup>
                )}

                {
                  entityActive === "RomActual" && (
                    <InputGroup className={isEdit ? "isEdit" : ""} style={{ border: "1px solid #E5E6E6", margin: "0.5rem 0 0 1rem" }}>
                      <InputGroup.Text className="input-icon bg-white border-white">
                        <SearchIcon />
                      </InputGroup.Text>
                      <Form.Control
                        className="border-white"
                        type="text"
                        placeholder="Search"
                        defaultValue={queryRom}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </InputGroup>
                  )
                }
              </div>
              {!isEdit && (
                <Button
                  variant="secondary-outline"
                  style={{ "margin": "0.5rem 1rem 0 0" }}
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  <AddIcon width={12} style={{ marginRight: "0.5rem" }} />Add / Edit Member
                </Button>
              )}
              {
                isEdit && (
                  <div
                    className="content-right d-flex"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Button variant="secondary-outline" onClick={() => {
                      setIsEdit(false)
                      handleFetchData()
                      setMemberIdChanged([]);
                    }}>
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      loading={loading === "fetch" && true}
                      disabled={loading === "fetch"}
                      onClick={() => setShowSubmitModal(true)}
                    >
                      Submit
                    </Button>
                  </div>
                )
              }
            </div >
            <div className="mb-10 wrapper-table">
              <Table {...tableConfig} />
            </div>
          </Accordion.Body >
        </Accordion.Item >
      </Accordion >
      <Modal
        showDialog={showSubmitModal}
        showHeader={true}
        icon={<SubmitIcon />}
        status=""
        title={
          <>
            Are you sure submit this{" "}
            <span style={{ color: "#FF0C2B", fontWeight: "bold" }}>
              Member
            </span>{" "}
          </>
        }
        activeID={false}
      >
        <div className="wrap-button">
          <button
            className="btn btn-light"
            onClick={() => {
              setShowSubmitModal(false)
            }}
          >
            Cancel
          </button>
          <Button
            loading={loading === "fetch" && true}
            variant="primary"
            onClick={() => handleSave("submit")}
          >
            Confirm
          </Button>
        </div>
      </Modal>

      <Modal
        showDialog={showDeleteModal}
        showHeader={true}
        icon={<DeleteRedIcon />}
        status=""
        title={
          <>
            Are you sure{" "}
            <span style={{ color: "#FF0C2B", fontWeight: "bold" }}>delete</span>{" "}
            this row?
          </>
        }
        activeID={false}
      >
        <div className="wrap-button">
          <button
            className="btn btn-light"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <Button
            loading={loading}
            variant="primary"
            onClick={() => {
              handleConfirmDelete()
              setLoading("fetch")
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default MemberTable;
