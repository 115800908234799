import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATASOURCE_VALUE } from 'utils/helper';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';

export const initialState = {
  pitProgressList: {
    record: [],
    recordsTotal: 0,
    loading: false,
    error: null,
  },
  pitProgressDetail: {
    data: {},
    dataSurvey: {},
    loading: false,
    error: null,
  },
  versionList: [],
  locationList: [],
  pitList: [],
};
export const PIT_PROGRESS_REDUCER = 'PIT_PROGRESS_REDUCER';
export const fetchPitProgressList = createAsyncThunk(
  'PIT_PROGRESS/list',
  async (params) => {
    const {
      query = '',
      dataStatus,
      reportId,
      surveyDate,
      startPeriod,
      endPeriod,
      contractor,
      version,
      pit,
      draw = 1,
      length = -1,
    } = params;
    const filter = {
      columns: [
        {
          data: 'reportId',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: reportId || '',
          searchable: true,
        },
        {
          data: 'surveyDate',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: surveyDate || '',
          searchable: true,
        },
        {
          data: 'startPeriod',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: startPeriod || '',
          searchable: true,
        },
        {
          data: 'endPeriod',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: endPeriod || '',
          searchable: true,
        },
        {
          data: 'contractor',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: contractor || '',
          searchable: true,
        },
        {
          data: 'pit',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: pit || '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: dataStatus === DATASOURCE_VALUE.waitingApproval || false,
            value: '',
          },
          searchValue: dataStatus || '',
          searchable: true,
        },
        {
          data: 'version',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: version || '',
          searchable: true,
        },
      ],
      draw: 1,
      length: length ? length : -1,
      search: {
        regex: true,
        value: query || '',
      },
    };
    const response = await post(`${apiUrls.pitProgress}/filter`, filter);
    return response?.data;
  }
);

export const fetchPitProgressDetail = createAsyncThunk(
  'PIT_PROGRESS/detail',
  async (param) => {
    const { id } = param;
    const response = await get(`${apiUrls.pitProgress}/${id}`);
    return response.data;
  }
);

export const getVersionList = createAsyncThunk(
  'PIT_PROGRESS/versionList',
  async (param) => {
    const { reportId } = param;
    const response = await get(`${apiUrls.pitProgress}/${reportId}/versions`);
    return response.data;
  }
);

export const getLocationList = createAsyncThunk(
  'PIT_PROGRESS/locationList',
  async () => {
    const response = await get(`${apiUrls.masterDataV2}/contractors`);
    return response.data;
  }
);

export const getPitList = createAsyncThunk('PIT_PROGRESS/pitList', async () => {
  const response = await get(`${apiUrls.masterDataV2}/locations`);
  return response.data;
});

export const setActivatedVersion = createAsyncThunk(
  'PIT_PROGRESS/setActivatedVersion',
  async (param) => {
    const { reportId, version, userName } = param;
    const response = await patch(
      `${apiUrls.pitProgress}/${reportId}/versions/${version}?userName=${userName}`
    );
    return response.data;
  }
);
export const getRomByVersion = createAsyncThunk(
  'PIT_PROGRESS/getPitProgressVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await get(
      `${apiUrls.pitProgress}/${reportId}/versions/${version}`
    );
    return response.data;
  }
);
export const getPitProgressByVersion = createAsyncThunk(
  'PIT_PROGRESS/petPitProgressByVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await get(
      `${apiUrls.pitProgress}/${reportId}/versions/${version}`
    );
    return response.data;
  }
);

export const addPitProgress = createAsyncThunk(
  'PIT_PROGRESS/add',
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.pitProgress}/add`, data);
    return response.data;
  }
);

export const updatePitProgress = createAsyncThunk(
  'PIT_PROGRESS/update',
  async (param) => {
    const { updateData } = param;
    const response = await post(`${apiUrls.pitProgress}/update`, updateData);
    return response.data;
  }
);
export const deleteProgress = createAsyncThunk(
  'PIT_PROGRESS/delete',
  async (param) => {
    const { id } = param;
    const response = await deleteRequest(`${apiUrls.pitProgress}/${id}`);
    return response.data;
  }
);
export const setPitProgressStatus = createAsyncThunk(
  'PIT_PROGRESS/setStatus',
  async (param) => {
    const { data } = param;
    const response = await patch(`${apiUrls.pitProgress}/set-status`, {
      ...data,
    });
    return response.data;
  }
);
export const savePitProgress = createAsyncThunk(
  'PIT_PROGRESS/save',
  async (param) => {
    const { data } = param;
    const response = await put(`${apiUrls.pitProgress}/save`, data);
    return response.data;
  }
);
export const submitPitProgress = createAsyncThunk(
  'PIT_PROGRESS/submit',
  async (param) => {
    const { data } = param;
    const response = await put(`${apiUrls.pitProgress}/submit`, data);
    return response.data;
  }
);

export const PitProgressSubmit = createAsyncThunk(
  'PIT_PROGRESS/submit',
  async (param) => {
    const { id } = param;
    const response = await post(`${apiUrls.pitProgress}/submit`, {
      id,
    });
    return response.data;
  }
);

export const PitProgressAdminAction = createAsyncThunk(
  'PIT_PROGRESS/adminAction',
  async (param) => {
    const { id, adminAction, notes, userName } = param;
    const response = await put(
      `${apiUrls.pitProgress}/${id}/${adminAction}?userName=${userName}`,
      {
        notes: adminAction === 'reject' ? notes : '',
      }
    );
    return response.data;
  }
);

export const deletePitProgressFile = createAsyncThunk(
  'PIT_PROGRESS/deleteFile',
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.pitProgress}/${id}/files/${name}`
    );
    return response.data;
  }
);

export const uploadPitProgressFile = createAsyncThunk(
  'PIT_PROGRESS/uploadFile',
  async (param) => {
    const { id, file } = param;
    console.log({ param });
    const response = await post(`${apiUrls.pitProgress}/${id}/files`, file, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);

export const pitProgressReducer = createSlice({
  name: PIT_PROGRESS_REDUCER,
  initialState,
  reducers: {
    onChangeDetailSurvey: (state, action) => {
      const payload = action.payload;

      state.pitProgressDetail.dataSurvey[payload.surveyName] = payload.value;
    },
    resetProgressDetail: (state, action) => {
      state.pitProgressDetail.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPitProgressList.pending, (state, action) => {
      state.pitProgressList.loading = true;
    });
    builder.addCase(fetchPitProgressList.fulfilled, (state, action) => {
      const { list, recordsTotal } = action.payload;
      state.pitProgressList.record = list;
      state.pitProgressList.recordsTotal = recordsTotal;
      state.pitProgressList.loading = false;
    });
    builder.addCase(fetchPitProgressList.rejected, (state, action) => {
      state.pitProgressList.loading = false;
      state.pitProgressList.error = action.error;
    });
    builder.addCase(fetchPitProgressDetail.pending, (state, action) => {
      state.pitProgressDetail.loading = true;
    });
    builder.addCase(fetchPitProgressDetail.fulfilled, (state, action) => {
      state.pitProgressDetail.data = action.payload;
      state.pitProgressDetail.loading = false;
    });
    builder.addCase(fetchPitProgressDetail.rejected, (state, action) => {
      state.pitProgressDetail.loading = false;
      state.pitProgressDetail.error = action.error;
    });

    builder.addCase(getVersionList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList = action.payload;
    });
    builder.addCase(getVersionList.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(getRomByVersion.pending, (state, action) => {
      state.pitProgressDetail.loading = true;
    });
    builder.addCase(getRomByVersion.fulfilled, (state, action) => {
      state.pitProgressDetail.data = action.payload;
      state.pitProgressDetail.loading = false;
    });
    builder.addCase(getRomByVersion.rejected, (state, action) => {
      state.pitProgressDetail.loading = false;
      state.pitProgressDetail.error = action.error;
    });
    builder.addCase(getPitList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPitList.fulfilled, (state, action) => {
      state.pitList = action.payload;
    });
    builder.addCase(getPitList.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });
    builder.addCase(getLocationList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLocationList.fulfilled, (state, action) => {
      state.locationList = action.payload;
    });
    builder.addCase(getLocationList.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });
  },
});
export const pitProgressSelector = (state) => state.pitProgress;
export const pitProgressListSelector = (state) =>
  state.pitProgress.pitProgressList;
export const pitProgressVersionListSelector = (state) =>
  state.pitProgress?.versionList;
export const pitProgressContractorListSelector = (state) =>
  state.pitProgress?.locationList;
export const pitProgressLocationListSelector = (state) =>
  state.pitProgress?.pitList;

export const pitProgressDetailSelector = (state) =>
  state.pitProgress?.pitProgressDetail;

export const { onChangeDetailSurvey, resetProgressDetail } =
  pitProgressReducer.actions;

export default pitProgressReducer.reducer;
