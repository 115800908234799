import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';

export const initialState = {
  slipperyCoalGettingList: {
    list: [],
    recordsTotal: 0,
    loading: false,
    error: null,
  },
  slipperyCoalGettingDetail: {
    data: {},
    loading: false,
    error: null,
  },
};

export const SLIPPERY_COAL_GETTING_REDUCER = 'SLIPPERY_COAL_GETTING_REDUCER';

export const fetchSlipperyCoalGettingList = createAsyncThunk(
  'slipperyCoalGetting/list',
  async (params) => {
    const response = await post(`${apiUrls.slipperyCoalGetting}/filter`, {
      columns: [
        {
          data: 'slipperyId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params?.reportId || '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params?.date || '',
          searchable: true,
        },
        {
          data: 'year',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params?.year || '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params?.dataStatus === DATASOURCE_VALUE.waitingApproval || false,
            value: '',
          },
          searchValue: params?.dataStatus || '',
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.query || '',
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.dataLength ? params.dataLength : -1,
      order: [],
    });
    return response?.data;
  }
);

export const fetchSlipperyCoalGettingDetail = createAsyncThunk(
  'slipperyCoalGetting/detail',
  async (param) => {
    const { id } = param;
    const response = await get(`${apiUrls.slipperyCoalGetting}/${id}`);
    return response.data;
  }
);

export const addSlipperyCoalGetting = createAsyncThunk(
  'slipperyCoalGetting/add',
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.slipperyCoalGetting}/add`, data);
    return response.data;
  }
);

export const updateSlipperyCoalGetting = createAsyncThunk(
  'slipperyCoalGetting/update',
  async (param) => {
    const { data } = param;
    const response = await put(`${apiUrls.slipperyCoalGetting}/save`, data);
    return response.data;
  }
);

export const deleteSlipperyCoalGetting = createAsyncThunk(
  'slipperyCoalGetting/delete',
  async (param) => {
    const { id } = param;
    const response = await deleteRequest(
      `${apiUrls.slipperyCoalGetting}/delete/${id}`
    );
    return response.data;
  }
);

export const setSlipperyCoalGettingStatus = createAsyncThunk(
  'slipperyCoalGetting/setStatus',
  async (param) => {
    const { data } = param;
    const response = await patch(
      `${apiUrls.slipperyCoalGetting}/set-status?userName=admin`,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const saveSlipperyCoalGetting = createAsyncThunk(
  'slipperyCoalGetting/save',
  async (param) => {
    const { data, userName } = param;
    const response = await put(
      `${apiUrls.slipperyCoalGetting}/save?userName=${userName}`,
      data
    );
    return response.data;
  }
);

export const submitSlipperyCoalGetting = createAsyncThunk(
  'slipperyCoalGetting/submit',
  async (param) => {
    const { data, userName } = param;
    const response = await post(
      `${apiUrls.slipperyCoalGetting}/submit?userName=${userName}`,
      data
    );
    return response.data;
  }
);

export const deleteSlipperyCoalGettingFile = createAsyncThunk(
  'slipperyCoalGetting/deleteFile',
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.slipperyCoalGetting}/${id}/files/${name}`
    );
    return response.data;
  }
);
export const uploadSlipperyCoalGettingFile = createAsyncThunk(
  'slipperyCoalGetting/uploadFile',
  async (param) => {
    const { id, file } = param;
    const response = await post(
      `${apiUrls.slipperyCoalGetting}/${id}/files`,
      file,
      {
        headers: { 'Content-Type': undefined },
      }
    );
    return response.data;
  }
);

export const handleApproveReject = createAsyncThunk(
  'slipperyCoalGetting/approval',
  async (param) => {
    const { id, notes, userName, action } = param;
    const response = await put(
      `${apiUrls.slipperyCoalGetting}/${id}/admin/${action}?userName=${userName}`,
      { notes: action === 'reject' ? notes : '' }
    );
    return response.data;
  }
);

const slipperyWasteRemovalSlice = createSlice({
  name: SLIPPERY_COAL_GETTING_REDUCER,
  initialState,
  reducers: {
    resetSlipperyCoalDetail: (state, action) => {
      state.slipperyCoalGettingDetail = {
        data: {},
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSlipperyCoalGettingList.pending, (state, action) => {
      state.slipperyCoalGettingList.loading = true;
    });
    builder.addCase(fetchSlipperyCoalGettingList.fulfilled, (state, action) => {
      const { list, recordsTotal } = action.payload;
      state.slipperyCoalGettingList.loading = false;
      state.slipperyCoalGettingList.list = list;
      state.slipperyCoalGettingList.recordsTotal = recordsTotal;
    });
    builder.addCase(fetchSlipperyCoalGettingList.rejected, (state, action) => {
      state.slipperyCoalGettingList.loading = false;
      state.slipperyCoalGettingList.error = action.error;
    });
    builder.addCase(fetchSlipperyCoalGettingDetail.pending, (state, action) => {
      state.slipperyCoalGettingDetail.loading = true;
    });
    builder.addCase(
      fetchSlipperyCoalGettingDetail.fulfilled,
      (state, action) => {
        state.slipperyCoalGettingDetail.loading = false;
        state.slipperyCoalGettingDetail.data = action.payload;
      }
    );
    builder.addCase(
      fetchSlipperyCoalGettingDetail.rejected,
      (state, action) => {
        state.slipperyCoalGettingDetail.loading = false;
        state.slipperyCoalGettingDetail.error = action.error;
      }
    );
  },
});

export const { resetSlipperyCoalDetail } = slipperyWasteRemovalSlice.actions;

export const slipperyCoalGettingSelector = (state) => state.slipperyCoalGetting;
export default slipperyWasteRemovalSlice.reducer;
