import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiUrls, get, patch, post } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';
var _ = require('lodash');

export const initialState = {
  portJettyLoadingPlans: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  confJettyMaster: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  portJettyLoadingPlansDetail: {
    loading: false,
    records: {
      detail: [],
      statusLogList: [],
    },
    recordsTotal: '',
    error: '',
  },
  jettySummary: {
    records: {
      production: 0,
      productivity: 0,
    },
  },
  versionList: [],
};

export const getportJettyLoadingPlansData = createAsyncThunk(
  'portJettyLoadingPlans/get',
  async (params) => {
    const response = await post(
      apiUrls.portJettyLoadingPlans.concat(`/filter`),
      {
        columns: [
          {
            data: 'id',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: '',
            searchable: false,
          },
          {
            data: 'reportId',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: '',
            searchable: true,
          },
          {
            data: 'createdAt',
            orderable: true,
            search: {
              regex: true,
              value: '',
            },
            searchValue: params.date,
            searchable: true,
          },
          {
            data: 'planDate',
            orderable: true,
            search: {
              regex: true,
              value: '',
            },
            searchValue: params.planDate,
            searchable: true,
          },
          {
            data: 'port',
            orderable: true,
            search: {
              regex: true,
              value: '',
            },
            searchValue: params.port,
            searchable: true,
          },
          {
            data: 'shift',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: params.shift || '',
            searchable: true,
          },
          {
            data: 'version',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: params.version || '',
            searchable: true,
          },
          {
            data: 'bibNotes',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: '',
            searchable: true,
          },
          {
            data: 'dataStatus',
            orderable: true,
            search: {
              regex: params?.status === DATASOURCE_VALUE.waitingApproval || params?.status >= 20 ? true : false,
              value: '',
            },
            searchValue: params.status,
            searchable: true,
          },
        ],
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params.dataLength ? params.dataLength : -1,
        search: {
          regex: false,
          value: params.query || '',
        },
        start: 0,
      }
    );
    return response?.data;
  }
);

export const getPortJettyLoadingPlansDetail = createAsyncThunk(
  'portJettyLoadingPlansDetail/get',
  async (params) => {
    const response = await get(
      apiUrls.portJettyLoadingPlans.concat(`/${params}`)
    );
    return response?.data;
  }
);

export const getLatestPortJetty = createAsyncThunk(
  'portJettyLoadingPlans/getLatestByPortCode',
  async (params) => {
    const response = await get(apiUrls.portJettyLoadingPlans.concat(`/port-code/${params}`));
    return response?.data;
  }
);

export const getConfJettyMaster = createAsyncThunk(
  'confJettyMaster/get',
  async (params) => {
    const response = await post(apiUrls.mdPortJetty.concat('/filter'), {
      columns: [
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.dataStatus,
          searchable: true,
        },
      ],
      draw: 1,
      length: params.dataLength ? params.dataLength : -1,
      start: 0,
    });

    var filtered = response.data.list
      .map((v) => {
        return {
          jetty: v.jettyLoading,
          status: {
            running: 0,
            idle: 0,
            shutdown: 12,
            breakdown: 0,
          },
          performanceTarget: {
            eu: 0,
            ma: 0,
            pa: 0,
            ua: 0,
          },
          production: {
            productivityTarget: v.designCapacity,
            total: 0,
          },
          port: v.port,
          bibNotes: '',
        };
      })
      .sort((a, b) => a.jetty.localeCompare(b.jetty));
    response.data = filtered;
    return response?.data;
  }
);

export const getJettySummary = createAsyncThunk(
  'jetty/summary',
  async (params) => {
    const { date, portId, shift } = params;
    const response = await get(
      `${apiUrls.portJettyLoadingPlans}/summary?date=${date}&portId=${portId}&shift=${shift}`
    );
    return response?.data;
  }
);

export const getVersionList = createAsyncThunk(
  'jetty/versionList',
  async (param) => {
    const { reportId } = param;
    const response = await get(
      `${apiUrls.portJettyLoadingPlans}/${reportId}/versions`
    );
    return response.data;
  }
);

export const setActivatedVersion = createAsyncThunk(
  'jetty/setActivatedVersion',
  async (param) => {
    const { reportId, version, userName } = param;
    const response = await patch(
      `${apiUrls.portJettyLoadingPlans}/${reportId}/versions/${version}?userName=${userName}`
    );
    return response.data;
  }
);

export const getByVersion = createAsyncThunk(
  'jetty/getByVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await get(
      `${apiUrls.portJettyLoadingPlans}/${reportId}/versions/${version}`
    );
    return response.data;
  }
);

const portJettyLoadingPlans = createSlice({
  name: 'CONF_CRUSHER',
  initialState,
  reducers: {
    onChangeTableInput: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confJettyMaster.records[payload.tableIndexRow],
        payload.tableRowName,
        payload.value
      );
    },
    onChangeTableActivityInput: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confJettyMaster.records[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },
    onChangeTableInputDetail: (state, action) => {
      const payload = action.payload;
      const intData = payload.int != undefined ? payload.int : true;

      if (intData) {
        _.set(
          state.portJettyLoadingPlansDetail?.records?.detail[
          payload.tableIndexRow
          ],
          payload.tableRowName,
          parseInt(payload.value)
        );
      } else {
        _.set(
          state.portJettyLoadingPlansDetail?.records?.detail[
          payload.tableIndexRow
          ],
          payload.tableRowName,
          payload.value
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getportJettyLoadingPlansData.pending, (state, action) => {
      state.portJettyLoadingPlans.loading = true;
    });
    builder.addCase(getportJettyLoadingPlansData.fulfilled, (state, action) => {
      state.portJettyLoadingPlans.loading = false;
      state.portJettyLoadingPlans.records = action.payload.list;
      state.portJettyLoadingPlans.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getportJettyLoadingPlansData.rejected, (state, action) => {
      state.portJettyLoadingPlans.loading = false;
      state.portJettyLoadingPlans.error = 'Invalid get data';
    });
    builder.addCase(getConfJettyMaster.pending, (state, action) => {
      state.confJettyMaster.loading = true;
    });
    builder.addCase(getConfJettyMaster.fulfilled, (state, action) => {
      state.confJettyMaster.records = action.payload;
    });
    builder.addCase(getConfJettyMaster.rejected, (state, action) => {
      state.confJettyMaster.loading = false;
      state.confJettyMaster.error = 'Invalid get data';
    });
    builder.addCase(getPortJettyLoadingPlansDetail.pending, (state, action) => {
      state.portJettyLoadingPlansDetail.loading = true;
    });
    builder.addCase(
      getPortJettyLoadingPlansDetail.fulfilled,
      (state, action) => {
        state.portJettyLoadingPlansDetail.records = action.payload;
      }
    );
    builder.addCase(
      getPortJettyLoadingPlansDetail.rejected,
      (state, action) => {
        state.portJettyLoadingPlansDetail.loading = false;
        state.portJettyLoadingPlansDetail.error = 'Invalid get data';
      }
    );
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList = action.payload;
    });

    builder.addCase(getLatestPortJetty.pending, (state, action) => {
      state.confJettyMaster.loading = true;
    });
    builder.addCase(getLatestPortJetty.fulfilled, (state, action) => {
      if (action.payload?.id !== null && action.payload?.detail !== null) {
        state.confJettyMaster.records = action.payload?.detail;
      }
    });
    builder.addCase(getLatestPortJetty.rejected, (state, action) => {
      state.confJettyMaster.loading = false;
      state.confJettyMaster.error = 'Invalid get data';
    });
  },
});

export const intervensiJettyLoadingPlan = createAsyncThunk(
  'jetty/intervensi',
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.portPlanIntervensi}/user-role?moduleName=Jetty`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const {
  onChangeTableInput,
  onChangeTableInputDetail,
  onChangeTableActivityInput,
} = portJettyLoadingPlans.actions;
export const portJettyLoadingPlansDataSelector = (state) =>
  state.portJettyLoadingPlans.portJettyLoadingPlans;
export const portJettyLoadingPlansDetailSelector = (state) =>
  state.portJettyLoadingPlans.portJettyLoadingPlansDetail;
export const confJettyMasterSelector = (state) =>
  state.portJettyLoadingPlans.confJettyMaster;
export const jettySummary = (state) => state.portJettyLoadingPlans.jettySummary;
export const jettyVersionListSelector = (state) =>
  state.portJettyLoadingPlans.versionList;

export default portJettyLoadingPlans.reducer;
