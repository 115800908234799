import RBButton from 'react-bootstrap/Button';
import Loader from './Loader';

const Button = ({ children, disabled, loading, className, ...props }) => {
  const isDisabled = disabled || loading;
  return (
    <RBButton {...props} className={isDisabled ? 'd-flex align-items-center justify-content-center' : className} onClick={isDisabled ? null : props.onClick} disabled={isDisabled}>
      {loading && <Loader />}
      {children}
    </RBButton>
  );
};

export default Button;
