import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getCompany } from 'utils/helper';
import { apiUrls, deleteRequest, get, patch, post } from 'utils/request';

export const initialState = {
  requestMasterDataList: {
    records: [],
    loading: false,
    error: "",
    recordsTotal: "",
    dataStatus: 1,
  },

  requestMasterDataDetail: {
    records: [],
    response: {},
    loading: false,
    error: null,
    statusLogList: [],
    documentList: [],
  },
};

export const REQUEST_MASTER_DATA_REDUCER = 'REQUEST_MASTER_DATA_REDUCER';

export const fetchRequestMasterDataEquipmentDetailList = createAsyncThunk(
  'request-equipment-detail-contractor/list',
  async (params) => {
    const response = await post(`${apiUrls.masterDataRequest}equipment-details/filter`,
      {
        "columns": [
          {
            "data": "id",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": false
          },
          {
            "data": "contractor",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": getCompany(),
            "searchable": true
          },
          {
            "data": "equipmentClass",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.equipmentClass || "",
            "searchable": true
          },
          {
            "data": "brand",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "equipmentDetailId",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "engineNumber",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "chasisNumber",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "type",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.type || "",
            "searchable": true
          },
          {
            "data": "job",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.job || "",
            "searchable": true
          },
          {
            "data": "dataStatus",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.dataStatus || "",
            "searchable": false
          },
          {
            "data": "entityStatus",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.status || "",
            "searchable": true
          },
          {
            "data": "createdAt",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": params.date || "",
            "searchable": true
          },
          {
            "data": "contractorNotes",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
          {
            "data": "bibNotes",
            "orderable": true,
            "search": {
              "regex": false,
              "value": ""
            },
            "searchValue": "",
            "searchable": true
          },
        ],
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params?.recordLength ? params.recordLength : -1,
        "search": {
          "regex": false,
          "value": params.query || ""
        },
        "start": 0
      });
    return response?.data;
  }
);

export const getMasterDataEquipmentDetailLogs = createAsyncThunk('request-equipment-detail-contractor/equipments/logs', async (params) => {
  const response = await get(`${apiUrls.masterDataRequest}equipment-details/logs`);
  return response?.data;
});

export const getMasterDataEquipmentDetailDeleteCheck = createAsyncThunk('request-equipment-detail-contractor/equipment-details/delete-check', async (params) => {
  const response = await get(`${apiUrls.masterData}/equipment-details/request/${params}/delete-check`);
  return response?.data;
});

const requestMasterDataSlice = createSlice({
  name: REQUEST_MASTER_DATA_REDUCER,
  initialState,
  reducers: {

    /*
      EquipmentDetail
    */
    /**
    * * ADD DATA TABLE EQUIPMENT DETAIL *
    * Todo: to add table on master data equipment-detail page
    * @param action
    */
    addDataTableEquipmentDetail: (state, action) => {
      const payload = action.payload;

      state.requestMasterDataList?.records.unshift(payload);
    },

    /**
    * * REMOVE FIRST DATA TABLE EQUIPMENT DETAIL *
    * Todo: to remove first row on table master data equipment-detail page
    * @param action
    */
    removeFirstDataTableEquipmentDetail: (state, action) => {
      state.requestMasterDataList?.records.shift();
    },

    /**
    * * REMOVE DATA TABLE EQUIPMENT DETAIL *
    * Todo: to remove row on table master data equipment-detail page
    * @param action
    */
    onRemoveDataTableEquipmentDetail: (state, action) => {
      const payload = action.payload;

      if (payload.isNew) state.requestMasterDataList.records.splice(payload.tableIndexRow, 1);
      else {
        if (payload.revert) state.requestMasterDataList.records[payload.tableIndexRow]['dataStatus'] = 1;
        else state.requestMasterDataList.records[payload.tableIndexRow]['dataStatus'] = 0;
      }
    },

    /**
    * * ONCHANGE TABLE EQUIPMENT DETAIL *
    * Todo: to change table on master data equipment-detail page
    * @param action
    */
    onChangeTableEquipmentDetail: (state, action) => {
      const payload = action.payload;

      if (payload.tableRowName == "equipment.type") {
        state.requestMasterDataList.records[payload.tableIndexRow]['equipment']['equipmentClass'] = null;
      }

      if (payload.tableRowName == "equipment.equipmentClass" || payload.tableRowName == "equipment.type") {
        state.requestMasterDataList.records[payload.tableIndexRow]['equipment'][payload.tableRowName] = payload.value;
      }
      else if (payload.tableRowName == "equipment") {
        state.requestMasterDataList.records[payload.tableIndexRow][payload.tableRowName] = payload.value;
        if (payload.tablePrevious == "equipment.type") {
          state.requestMasterDataList.records[payload.tableIndexRow]['equipment']['equipmentClass'] = null;
        }
      }
      else state.requestMasterDataList.records[payload.tableIndexRow][payload.tableRowName] = payload.value;
    },

    /**
    * * ONREVERT TABLE EQUIPMENT DETAIL *
    * Todo: to change table on master data equipment-detail page
    * @param action
    */
    onRevertTableEquipmentDetail: (state, action) => {
      const payload = action.payload;

      state.requestMasterDataList.revert = state.requestMasterDataList.records;
      if (!payload) state.requestMasterDataList.onRevert = true;
      else state.requestMasterDataList.onRevert = false;
    },

    /**
    * * ONREVERT TABLE EQUIPMENT DETAIL *
    * Todo: to change table on master data equipment-detail page
    * @param action
    */
    onRevertChangeTableEquipmentDetail: (state, action) => {
      state.requestMasterDataList.records = state.requestMasterDataList.revert;

      state.requestMasterDataList.revert = [];
      state.requestMasterDataList.onRevert = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRequestMasterDataEquipmentDetailList.pending, (state, action) => {
      state.requestMasterDataList.loading = true;
    });
    builder.addCase(fetchRequestMasterDataEquipmentDetailList.fulfilled, (state, action) => {
      state.requestMasterDataList.dataStatus = action.payload.dataStatus;
      state.requestMasterDataList.records = action.payload.list;
      state.requestMasterDataList.recordsTotal = action.payload.recordsTotal;
      state.requestMasterDataList.loading = false;
    });
    builder.addCase(fetchRequestMasterDataEquipmentDetailList.rejected, (state, action) => {
      state.requestMasterDataList.loading = false;
      state.requestMasterDataList.error = action.error;
    });
    builder.addCase(getMasterDataEquipmentDetailLogs.pending, (state, action) => {
      state.requestMasterDataList.loading = true;
    });
    builder.addCase(getMasterDataEquipmentDetailLogs.fulfilled, (state, action) => {
      state.requestMasterDataList.statusLogList = action.payload;
    });
    builder.addCase(getMasterDataEquipmentDetailLogs.rejected, (state, action) => {
      state.requestMasterDataList.loading = false;
      state.requestMasterDataList.error = 'Invalid get data';
    });
  },
});
export const requestMasterDataSelector = (state) => state.requestEquipmentDetailContractor.requestMasterDataList;
export const requestMasterDataDetailSelector = (state) => state.requestEquipmentDetailContractor.requestMasterDataDetail;

export const {
  onChangeTableEquipmentDetail,
  addDataTableEquipmentDetail,
  removeFirstDataTableEquipmentDetail,
  onRevertChangeTableEquipmentDetail,
  onRevertTableEquipmentDetail,
  onRemoveDataTableEquipmentDetail,
} = requestMasterDataSlice.actions;

export default requestMasterDataSlice.reducer;
