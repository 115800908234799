import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';

export const initialState = {
  wasteHaulDistanceList: {
    records: [],
    loading: false,
    error: null,
    loading: false,
    error: '',
    recordsTotal: '',
  },
  wasteHaulDistanceDetail: {
    records: [],
    response: [],
    loading: false,
    error: null,
    statusLogList: [],
    documentList: [],
    shift: null,
    contractor: {},
  },
  currentWasteRemoval: {
    loading: false,
    records: [],
    error: ""
  },
};

export const WASTE_HAUL_DISTANCE_REDUCER = 'WASTE_HAUL_DISTANCE_REDUCER';

export const fetchWasteHaulDistanceList = createAsyncThunk(
  'waste-haul-distance/list',
  async (params) => {
    const response = await post(
      `${apiUrls.masterData}/haul-distance-waste/filter`,
      {
        columns: [
          {
            data: 'id',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: '',
            searchable: false,
          },
          {
            data: 'reportId',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: '',
            searchable: true,
          },
          {
            data: 'phase',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: '',
            searchable: true,
          },
          {
            data: 'dataStatus',
            orderable: true,
            search: {
              regex: params?.dataStatus === DATASOURCE_VALUE.waitingApproval || false,
              value: '',
            },
            searchValue: params.dataStatus || '',
            searchable: true,
          },
          {
            data: 'createdAt',
            orderable: true,
            search: {
              regex: params.date ? true : false,
              value: '',
            },
            searchValue: params.date || '',
            searchable: true,
          },
        ],
        draw: 1,
        length: params.dataLength || -1,
        order: [
        ],
        search: {
          regex: false,
          value: params.query || '',
        },
        start: 0,
      }
    );
    return response?.data;
  }
);

export const fetchWasteHaulDetail = createAsyncThunk(
  'waste-haul-distance/detail',
  async (param) => {
    const { id } = param;
    const response = await get(
      `${apiUrls.masterData}/haul-distance-waste/${id}`
    );
    return response.data;
  }
);

export const handleCreateWasteHaul = createAsyncThunk(
  'waste-haul-distance/create-new',
  async (param) => {
    const { action, object, userName } = param;
    const response = await post(
      `${apiUrls.masterData}/haul-distance-waste/${action}?userName=${userName}`,
      object
    );
    return response.data;
  }
);

export const handleEditWasteHaul = createAsyncThunk(
  'waste-haul-distance/edit',
  async (param) => {
    const { action, object, id, userName } = param;
    const response = await put(
      `${apiUrls.masterData}/haul-distance-waste/${id}/${action}?userName=${userName}`,
      object
    );
    return response.data;
  }
);

export const handleApproveWasteHaul = createAsyncThunk(
  'waste-haul-distance/approve',
  async (param) => {
    const { id, userName } = param;
    const response = await put(
      // `${apiUrls.masterData}/haul-distance-waste/${id}/approve?userName=${userName}`,
      `${apiUrls.masterData}/haul-distance-waste/${id}/approve`,
      { notes: '' }
    );
    return response.data;
  }
);

export const handleRejectWasteHaul = createAsyncThunk(
  'waste-haul-distance/reject',
  async (param) => {
    const { id, notes, userName } = param;
    const response = await put(
      // `${apiUrls.masterData}/haul-distance-waste/${id}/reject?userName=${userName}`,
      `${apiUrls.masterData}/haul-distance-waste/${id}/reject`,
      { notes }
    );
    return response.data;
  }
);

export const getCurrentWasteRemoval = createAsyncThunk(
  'waste-haul-distance/getCurrentWasteRemoval',
  async (params) => {
    const response = await get(`${apiUrls.wasteRemoval}/productions/contractor-name/${params}`);
    return response?.data;
  }
);

export const getContractorByName = createAsyncThunk(
  'waste-haul-distance/contractorByName',
  async (param) => {
    const response = await get(
      `${apiUrls.masterData}/contractors/names/${param}`
    );
    return response.data;
  }
);

const wasteHaulDistanceSlice = createSlice({
  name: WASTE_HAUL_DISTANCE_REDUCER,
  initialState,
  reducers: {
    clearWasteHaulDetail: (state, action) => {
      state.wasteHaulDistanceDetail = {
        records: [],
        response: [],
        loading: false,
        error: null,
        statusLogList: [],
        documentList: [],
        shift: null,
        contractor: {},
      };
    },

    addDataTableWasteHaul: (state, action) => {
      if (!state.wasteHaulDistanceDetail?.records) {
        state.wasteHaulDistanceDetail.records = [action.payload];
      } else {
        state.wasteHaulDistanceDetail?.records.push(action.payload);
      }
    },

    onEditWasteHaul: (state, action) => {
      const payload = action.payload;
      if (payload.tableRowName === "loadingLocation") {
        state.wasteHaulDistanceDetail.records[payload.tableIndexRow]['loadingSublocation'] = "";
      }

      if (payload.tableRowName === "dumpingLocation") {
        state.wasteHaulDistanceDetail.records[payload.tableIndexRow]['dumpingSublocation'] = "";
      }

      state.wasteHaulDistanceDetail.records[payload.tableIndexRow][
        payload.tableRowName
      ] = payload.value;
    },

    onRemoveDataTableWasteHaul: (state, action) => {
      const payload = action.payload;

      state.wasteHaulDistanceDetail.records.splice(payload.tableIndexRow, 1);
    },

    onAddFileWasteHaul: (state, action) => {
      const payload = action.payload;
      if (state.wasteHaulDistanceDetail.documentList) {
        state.wasteHaulDistanceDetail.documentList.unshift(payload);
      } else {
        var arr = [payload];
        state.wasteHaulDistanceDetail.documentList = arr;
      }
    },

    onRemoveFileWasteHaul: (state, action) => {
      state.wasteHaulDistanceDetail.documentList.splice(0, 1);
    },

    onChangeShiftWatch: (state, action) => {
      const payload = action.payload;
      state.wasteHaulDistanceDetail[payload.name] = payload.value.value;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(fetchWasteHaulDistanceList.pending, (state, action) => {
      state.wasteHaulDistanceList.loading = true;
    });
    builder.addCase(fetchWasteHaulDistanceList.fulfilled, (state, action) => {
      state.wasteHaulDistanceList.records = action.payload.list;
      state.wasteHaulDistanceList.recordsTotal = action.payload.recordsTotal;
      state.wasteHaulDistanceList.loading = false;
    });
    builder.addCase(fetchWasteHaulDistanceList.rejected, (state, action) => {
      state.wasteHaulDistanceList.loading = false;
      state.wasteHaulDistanceList.error = action.error;
    });

    builder.addCase(fetchWasteHaulDetail.pending, (state, action) => {
      state.wasteHaulDistanceDetail.loading = true;
    });
    builder.addCase(fetchWasteHaulDetail.fulfilled, (state, action) => {
      state.wasteHaulDistanceDetail.records =
        action.payload.loadingDumpingDistanceList;
      state.wasteHaulDistanceDetail.response = action.payload;
      state.wasteHaulDistanceDetail.shift = action.payload?.shift;
      state.wasteHaulDistanceDetail.contractor = action.payload?.contractor;
      state.wasteHaulDistanceDetail.loading = false;
    });
    builder.addCase(fetchWasteHaulDetail.rejected, (state, action) => {
      state.wasteHaulDistanceDetail.loading = false;
      state.baDistanceHaulingList.error = action.error;
    });

    builder.addCase(getCurrentWasteRemoval.pending, (state, action) => {
      state.loading = true;
      state.wasteHaulDistanceDetail.loading = true;
    });
    builder.addCase(getCurrentWasteRemoval.fulfilled, (state, action) => {
      const result = [];
      let rn = 0;
      action?.payload?.production?.data.forEach(item => {
        item.subRows.forEach(dt => {
          let nsr = {};
          if(dt.unit !== "" && dt.material !== "") {
            nsr.status = item.status.job;
            nsr.fleet = item.rowNumber;
            nsr.unit = item.unit;
            nsr.id = item.id;
            nsr.trackingTime = "";
            nsr.distanceOdometer ="";
            nsr.distanceGps = "";
            nsr.notes = dt.notes || "";
            nsr.dumpingPointSubLocation = dt.dumpingPoint || "";
            // nsr.dumpingPointLocation = dt.loadingPoint || "";
            nsr.loadingPointSubLocation = dt.loadingPointSubLocation || "";
            nsr.material = dt.material || "";
            nsr.quantity = dt.quantity || "";
            nsr.rowNumber = rn;
            result.push(nsr);
            rn++;
          }
        });
      });
      state.wasteHaulDistanceDetail.loading = false;
      state.wasteHaulDistanceDetail.records = result;
    });
    builder.addCase(getCurrentWasteRemoval.rejected, (state, action) => {
      state.loading = false;
      state.wasteHaulDistanceDetail.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(getContractorByName.pending, (state, action) => {
      state.wasteHaulDistanceDetail.loading = true;
    });
    builder.addCase(getContractorByName.fulfilled, (state, action) => {
      state.wasteHaulDistanceDetail.contractor = action.payload;
      state.wasteHaulDistanceDetail.loading = false;
    });
    builder.addCase(getContractorByName.rejected, (state, action) => {
      state.wasteHaulDistanceDetail.loading = false;
      state.wasteHaulDistanceDetail.error = action.error;
    });
  },
});
export const wasteHaulDistanceSelector = (state) =>
  state.wasteHaulDistance.wasteHaulDistanceList;
export const wasteHaulingDetailSelector = (state) =>
  state.wasteHaulDistance.wasteHaulDistanceDetail;

export const currentWasteRemovalSelector = (state) => state.wasteHaulDistance.currentWasteRemoval;

export const {
  clearWasteHaulDetail,
  onEditWasteHaul,
  addDataTableWasteHaul,
  onRemoveDataTableWasteHaul,
  onAddFileWasteHaul,
  onRemoveFileWasteHaul,
  onChangeShiftWatch,
} = wasteHaulDistanceSlice.actions;

export default wasteHaulDistanceSlice.reducer;
