import { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import cx from 'classnames';

import bn from 'utils/bemNames';
import {
  ArrowLeft,
  ThumbsUpIcon,
  ThumbsDownIcon,
  CloseIcon,
} from 'assets/icons';
import {
  Button,
  SubHeader,
  Dropdown,
  LogStatus,
  Modal,
  Table,
  Input,
} from 'components';
import {
  formatDateTime,
  getStatusClass,
  optionMapper,
  optionMapperVersion,
} from 'utils/helper';
import { getUserLogged } from 'containers/App/reducer';
import roles from 'config/roleList';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const bem = bn('configuration');

const FleetDetail = ({
  titlePage,
  responseData = {
    dataStatus: 1,
  },
  tableConfig = {
    columns: [],
  },
  filterInput,
  control,
  errors,
  isLoading,
  versionList = [],
  addNewRow = () => {},
  getValues = () => {},
  setValue = () => {},
  submitData = () => {},
  activatedVersion = () => {},
  goBack = () => {},
}) => {
  const navigate = useNavigate();
  const { qmode } = useParams();
  const { dataStatus, updatedAt } = responseData;

  const divClass = getStatusClass(dataStatus || 'draft');
  const isAdd = qmode === 'add' || qmode === 'create';
  const lastSaved = formatDateTime(updatedAt);
  const isSaving = getValues('isSaving') || false;
  const [showConfirmModal, setConfirmModal] = useState(false);

  const userData = useSelector(getUserLogged);
  const userRole = useMemo(() => {
    if (userData?.role === roles.ADMIN_BIB) {
      return ['approver', 'creator', 'viewer'];
    } else if (userData?.role === roles.ROM_CREATOR_CONTRACTOR) {
      return ['creator', 'viewer'];
    } else if (
      userData?.role === roles.ROM_APPROVER_CONTRACTOR ||
      userData?.role === roles.ROM_APPROVER_BIB
    ) {
      return ['approver', 'viewer'];
    } else if (userData?.role === roles.ROM_VIEWER_BIB) {
      return ['viewer'];
    }
    return [];
  }, [userData]);

  const sendData = (action) => {
    submitData(action);
  };

  const handleInputFile = (inputFile) => {
    const size = 15120000;
    let documentList = getValues('documentList') || [];

    // const type = ['image/jpeg', 'image/png', 'image/jpg'];
    if (inputFile?.size > size) {
      return toast.error('Error, Max size is 15Mb', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    // set data
    if (
      !documentList.some(
        (item) => item.name === inputFile.name && item.type === inputFile.type
      )
    ) {
      documentList.push(inputFile);
      setValue('documentList', documentList);
    } else {
      toast.error('Cannot upload same file', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    // reset input file
    document.getElementById('fileInput').value = '';
  };

  const handleDeleteFile = (doc) => {
    let documentList = getValues('documentList') || [];
    let deletedDocs = getValues('deletedDocs') || [];
    deletedDocs.push(doc);

    // handle delete
    setValue(
      'deletedDocs',
      deletedDocs.filter((item) => item.hasOwnProperty('id'))
    );
    setValue(
      'documentList',
      documentList.filter((item) => item !== doc)
    );
    // reset input file
    document.getElementById('fileInput').value = '';
  };

  const handleClickFile = () => {
    document.getElementById('fileInput').click();
  };

  const handleDownloadFile = (doc) => {
    if (doc.hasOwnProperty('id')) {
      window.open(doc.src);
    }
  };

  return (
    <div className={cx(bem.b(), 'detail')}>
      <SubHeader>
        <div className="content-left">
          <Button onClick={goBack}>
            <ArrowLeft />
          </Button>
          <h1>{titlePage}</h1>
        </div>
        <div className="content-right">
          {!isSaving && lastSaved && !lastSaved.includes('Invalid') && (
            <span>Last saved {lastSaved}</span>
          )}
          {isSaving && <span>Saving...</span>}
          {(responseData.dataStatus === 1 || responseData.dataStatus === 2 || responseData.dataStatus >= 20) &&
            (qmode === 'add' || qmode === 'edit' || qmode === 'create') && (
              <>
                <Button variant="outline-primary" onClick={goBack}>
                  Cancel
                </Button>
                {userRole.includes('creator') && (
                  <>
                    <Button
                      variant="secondary-outline"
                      loading={isLoading === 'save' && true}
                      onClick={() => sendData('save')}
                    >
                      Save
                    </Button>
                    <Button
                      variant="primary"
                      loading={isLoading === 'submit' && true}
                      onClick={() => setConfirmModal('submit')}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </>
            )}
          {responseData.dataStatus !== 1 &&
            responseData.dataStatus !== 4 &&
            responseData.dataStatus !== 3 &&
            qmode === 'detail' &&
            userRole.includes('approver') && (
              <>
                <Button
                  variant="secondary"
                  loading={isLoading === 'reject' && true}
                  onClick={() => setConfirmModal('reject')}
                >
                  Reject
                </Button>
                <Button
                  variant="success"
                  className="text-white"
                  loading={isLoading === 'approve' && true}
                  onClick={() => setConfirmModal('approve')}
                >
                  Approve
                </Button>
              </>
            )}
        </div>
      </SubHeader>

      <Row>
        <Col md={!isAdd ? 9 : 12} className="p-0">
          {!isAdd && (
            <div
              className={cx(
                divClass?.divBG || '',
                'br-2 p-12 flex-grow-1 flex-center'
              )}
            >
              <span className={cx(divClass?.textColor || '', 'fs-14 lh-17')}>
                {divClass?.text || ''}
              </span>
            </div>
          )}
          <div className="wrapper-action">
            <div></div>
            <div className="right">
              <Dropdown
                name="version"
                control={control}
                options={optionMapperVersion(versionList)}
                onSelect={(e) => setValue('version', e)}
                placeholder="Version"
                disabled={!responseData.id}
                selectedOption={getValues('version')}
              />
              <Button
                onClick={() => activatedVersion()}
                disabled={!responseData.id}
                variant="primary-light"
                loading={isLoading === 'version' && true}
              >
                Activated Version
              </Button>
            </div>
          </div>
          <div className="wrapper-content">
            <div className="title" style={{ background: '#F5F6F6' }}>
              General Information
            </div>
            <div className="wrapper-input">{filterInput}</div>

            <Table className={bem.e('table', 'primary')} {...tableConfig} />
            {qmode !== 'detail' && (
              <div className="py-20 flex-center">
                <Button variant="primary" onClick={addNewRow}>
                  Add
                </Button>
              </div>
            )}

            <div className="wrapper-section">
              <div className="action-footer">
                <div className="upload-document d-flex align-items-center">
                  <span>Documents</span>
                  <Form.Control
                    type="file"
                    id="fileInput"
                    onChange={(e) => handleInputFile(e.target.files[0])}
                    style={{ display: 'none' }}
                  />
                  {(isAdd || qmode === 'edit') && (
                    <Button
                      variant="secondary-outline"
                      onClick={handleClickFile}
                    >
                      Upload
                    </Button>
                  )}
                </div>
                <div className="d-flex flex-wrap">
                  {(getValues('documentList') || []).length > 0 &&
                    (getValues('documentList') || []).map((doc, index) => (
                      <div key={index} className="wrapper-document">
                        <div
                          className="document"
                          onClick={() => handleDownloadFile(doc)}
                        >
                          {doc.name}
                        </div>
                        {(isAdd || qmode === 'edit') && (
                          <CloseIcon onClick={() => handleDeleteFile(doc)} />
                        )}
                      </div>
                    ))}
                </div>
              </div>
              <div className="action-footer">
                <Form.Group className="w-100">
                  <Form.Label>NOTES</Form.Label>
                  <Input
                    name="notes"
                    as="textarea"
                    control={control}
                    disabled
                    placeholder="Note (Optional)"
                    type="text"
                    value={responseData?.bibNotes}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </Col>
        {!isAdd && (
          <Col md={3} className="p-0">
            <LogStatus data={responseData?.statusLogList} />
          </Col>
        )}
      </Row>

      <ToastContainer />

      <Modal
        showDialog={showConfirmModal === 'submit'}
        showHeader={true}
        icon={<ThumbsUpIcon />}
        status=""
        title={`Are you sure submit this ${titlePage}?`}
        activeID={false}
      >
        <p style={{ fontSize: '13px', textAlign: 'center' }}>
          After you confirm, you can’t make any change in this version.
        </p>
        <div className="wrap-button">
          <button
            className="btn btn-light"
            onClick={() => setConfirmModal(false)}
          >
            Cancel
          </button>
          <Button
            loading={isLoading}
            variant="primary"
            onClick={() => sendData('submit')}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      <Modal
        showDialog={showConfirmModal === 'approve'}
        showHeader={true}
        icon={<ThumbsUpIcon />}
        status=""
        title="Are you sure Approve this request?"
        activeID={false}
      >
        <div className="wrap-button">
          <button
            className="btn btn-light"
            onClick={() => setConfirmModal(false)}
          >
            Cancel
          </button>
          <Button
            loading={isLoading}
            variant="primary"
            onClick={() => sendData('approve')}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      <Modal
        showDialog={showConfirmModal === 'reject'}
        showHeader={true}
        icon={<ThumbsDownIcon />}
        status=""
        title="Are you sure Reject this request?"
        activeID={false}
      >
        <Form.Control
          control={control}
          name="notes"
          as="textarea"
          rows={5}
          required
          onChange={(e) => {
            setValue('notes', e.target.value);
            setValue('bibNotes', e.target.value);
          }}
        />
        <div className="wrap-button">
          <button
            className="btn btn-light"
            onClick={() => {
              setConfirmModal(false);
              setValue('notes', '');
            }}
          >
            Cancel
          </button>
          <Button
            loading={isLoading}
            variant="primary"
            type="submit"
            onClick={() => sendData('reject')}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export const shiftList = [
  {
    value: 'DAY',
    label: 'Day',
  },
  {
    value: 'NIGHT',
    label: 'Night',
  },
];

export const renderEditableDropdown = ({
  options = [],
  name,
  selectedValue,
  onChange = () => {},
  error,
}) => {
  return (
    <div>
      <Select
        onChange={onChange}
        value={selectedValue}
        name={name}
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
      <div className="ccm-error">{error}</div>
    </div>
  );
};

export const renderEditableCell = ({
  name,
  error = { type: '' },
  register,
  editableKeyToFocus = {},
  onChange = () => {},
  value = '',
}) => {
  let errorMessage = '';
  switch (error.type) {
    case 'maxValue':
      errorMessage = 'Max 12 hours';
      break;
    case 'minValue':
      errorMessage = 'Min 0 hours';
      break;
    case 'required':
      errorMessage = 'Required';
      break;
    default:
      errorMessage = '';
      break;
  }
  return (
    <div>
      <Form.Control
        name={name}
        type="number"
        autoFocus={name === editableKeyToFocus.current}
        onFocus={() => (editableKeyToFocus.current = name)}
        {...register(name, {
          setValueAs: (v) => parseInt(v) || 0,
          onChange: (e) => {
            if (parseInt(e.target.value) < 0 || parseInt(e.target.value) > 12) {
              e.target.value = 0;
            }
            onChange(e.target.value);
          },
          required: true,
        })}
      />
      <div className="ccm-error">{errorMessage}</div>
    </div>
  );
};

export default FleetDetail;
