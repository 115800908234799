export const versionOptionList = [
  {label: 'Ver 1', value: 1},
  {label: 'Ver 2', value: 2}
];

export const shiftOptionList = [
  {label: 'Day', value: 'DAY'},
  {label: 'Night', value: 'NIGHT'}
];

export const dummyData = [
  {
    id: 1,
    reportId: 'SURV-P-2022-04-005',
    createdAt: '2022-06-06T00:00:00',
    shift: 'Day',
    version: 2,
    dataStatus: 4,
  },
  {
    id: 2,
    reportId: 'SURV-P-2022-04-006',
    createdAt: '2022-06-07T00:00:00',
    shift: 'Day',
    version: 1,
    dataStatus: 3,
  },
  {
    id: 3,
    reportId: 'SURV-P-2022-04-006',
    createdAt: '2022-06-07T00:00:00',
    shift: 'Night',
    version: 1,
    dataStatus: 2,
  },
  {
    id: 4,
    reportId: 'SURV-P-2022-04-007',
    createdAt: '2022-06-07T00:00:00',
    shift: 'Night',
    version: 2,
    dataStatus: 1,
  },
  {
    id: 5,
    reportId: 'SURV-P-2022-04-007',
    createdAt: '2022-06-07T00:00:00',
    shift: 'Day',
    version: 2,
    dataStatus: 3,
  },
  {
    id: 6,
    reportId: 'SURV-P-2022-04-007',
    createdAt: '2022-06-07T00:00:00',
    shift: 'Night',
    version: 2,
    dataStatus: 4,
  },
  {
    id: 7,
    reportId: 'SURV-P-2022-04-007',
    createdAt: '2022-06-07T00:00:00',
    shift: 'Night',
    version: 2,
    dataStatus: 4,
  },
];

export const dataItems = [
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DT',
    quantity: '100/100',
    detail: [70, 5, 5]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DDT',
    quantity: '100/100',
    detail: [0, 0, 0]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DDT',
    quantity: '100/100',
    detail: [65, 8, 4]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DT',
    quantity: '100/100',
    detail: [70, 5, 5]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DDT',
    quantity: '100/100',
    detail: [0, 0, 0]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DDT',
    quantity: '100/100',
    detail: [65, 8, 4]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DT',
    quantity: '100/100',
    detail: [70, 5, 5]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DDT',
    quantity: '100/100',
    detail: [0, 0, 0]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DDT',
    quantity: '100/100',
    detail: [65, 8, 4]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DT',
    quantity: '100/100',
    detail: [70, 5, 5]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DDT',
    quantity: '100/100',
    detail: [0, 0, 0]
  },
  {
    romA1: '10',
    romB1: 125,
    romB2: '10',
    contractor: 'SLI',
    equipmentType: 'DDT',
    quantity: '100/100',
    detail: [65, 8, 4]
  },
];
