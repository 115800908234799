import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrls, get, getAPIEndpoint, post } from 'utils/request';

// initial state
export const initialState = {
  portCrusher: {
    loading: false,
    records: [],
    recordsTotal: 0,
    error: '',
  },
  listCategory: [],
  p2hDashboard: []
};

// fetch functions
export const fetchActualCrushing = createAsyncThunk('crushing/fetch', async (params) => {
  const options = {
    columns: [
      {
        data: 'id',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: params?.id || '',
        searchable: true,
      },
      {
        data: 'date',
        orderable: true,
        search: {
          regex: true,
          value: '',
        },
        searchValue: params?.date || '',
        searchable: true,
      },
      {
        data: 'shift',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: params?.shift || '',
        searchable: true,
      },
      {
        data: 'crushingPlant',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: params?.crushingPlant || '',
        searchable: true,
      },
      {
        data: 'createdBy',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: params?.createdBy || '',
        searchable: false,
      },
    ],
    draw: 1,
    length: params.dataLength || -1,
    order: [
    ],
    start: 0,
  };

  const response = await post(getAPIEndpoint('actual-port', 'v1', '/crushing/filter'), options);

  return response?.data;
});

export const exportActualToExcel = createAsyncThunk('crushing/exportExcel', async (params) => {
  const object = {
    crushingPlant: params.crushingPlant?.label,
    endDate: params.endDate,
    shift: params.shift?.value,
    startDate: params.startDate
  };

  const response = await post(getAPIEndpoint('actual-port', 'v1', '/crushing/export-excel-file'), object);
  return response?.data;
})

export const getp2hCategory = createAsyncThunk(
  "crushing/get-p2h-category",

  async () => {
    const response = await get(`${apiUrls.actualPort}/p2h/dashboard/category`);

    return response.data;
  }
);

export const getp2hDashboard = createAsyncThunk(
  "crushing/get-p2h-dashboard",

  async (params) => {
    const { dateStart, dateEnd } = params

    const response = await get(`${apiUrls.actualPort}/p2h/dashboard?dateStart=${dateStart}&dateEnd=${dateEnd}`);

    return response.data;
  }
);

// slicing
const actualCrusher = createSlice({
  name: 'ACTUAL_CRUSHER',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActualCrushing.fulfilled, (state, action) => {
      state.portCrusher.loading = false;
      state.portCrusher.records = action.payload?.list;
      state.portCrusher.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(fetchActualCrushing.pending, (state) => {
      state.portCrusher.loading = true;
    });

    builder.addCase(exportActualToExcel.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(exportActualToExcel.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(exportActualToExcel.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid export data';
    });

    builder.addCase(getp2hCategory.fulfilled, (state, action) => {
      state.listCategory = action.payload.list;
    });

    builder.addCase(getp2hDashboard.fulfilled, (state, action) => {
      state.p2hDashboard = action.payload;
    });
  },
});

export const portActualCrusher = (state) => state.actualCrusher.portCrusher;
export const p2hCategorySelector = (state) => state.actualCrusher.listCategory;
export const p2hDashboardSelector = (state) => state.actualCrusher.p2hDashboard;

export default actualCrusher.reducer;
