import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATASOURCE_VALUE } from 'utils/helper';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';

export const initialState = {
  romInventoryList: {
    record: [],
    recordsTotal: 0,
    loading: false,
    error: null,
  },
  romInventoryDetail: {
    data: {},
    loading: false,
    error: null,
  },
  versionList: [],
};

export const fetchROMInverntoryList = createAsyncThunk(
  'ROM-Inventory/list',
  async (params) => {
    const {
      query = '',
      reportId,
      dataStatus,
      surveyDate,
      startPeriod,
      endPeriod,
      version,
      draw = 1,
      length = -1,
    } = params;
    const filter = {
      columns: [
        {
          data: 'reportId',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: reportId || '',
          searchable: true,
        },
        {
          data: 'surveyDate',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: surveyDate || '',
          searchable: true,
        },
        {
          data: 'startPeriod',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: startPeriod || '',
          searchable: true,
        },
        {
          data: 'endPeriod',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: endPeriod || '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: dataStatus === DATASOURCE_VALUE.waitingApproval || false,
            value: '',
          },
          searchValue: dataStatus || '',
          searchable: true,
        },
        {
          data: 'version',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: version || '',
          searchable: true,
        },
      ],
      draw: draw,
      length: length ? length : -1,
      order: [],
      search: {
        regex: true,
        value: query || '',
      },
    };
    const response = await post(`${apiUrls.romInventory}/filter`, filter);
    return response?.data;
  }
);

export const fetchRomInventoryDetail = createAsyncThunk(
  'ROM-Inventory/detail',
  async (param) => {
    const { id } = param;
    const response = await get(`${apiUrls.romInventory}/${id}`);
    return response.data;
  }
);

export const getVersionList = createAsyncThunk(
  'ROM-Inventory/versionList',
  async (param) => {
    const { reportId } = param;
    const response = await get(`${apiUrls.romInventory}/${reportId}/versions`);
    return response.data;
  }
);

export const setActivatedVersion = createAsyncThunk(
  'ROM-Inventory/setActivatedVersion',
  async (param) => {
    const { reportId, version, userName } = param;
    const response = await patch(
      `${apiUrls.romInventory}/${reportId}/versions/${version}?userName=${userName}`
    );
    return response.data;
  }
);
export const getRomByVersion = createAsyncThunk(
  'ROM-Inventory/getRomByVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await get(
      `${apiUrls.romInventory}/${reportId}/versions/${version}`
    );
    return response.data;
  }
);
export const getRomInventoryByVersion = createAsyncThunk(
  'ROM-Inventory/getRomInventoryByVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await get(
      `${apiUrls.romInventory}/${reportId}/${version}`
    );
    return response.data;
  }
);

export const addRomInventory = createAsyncThunk(
  'ROM-Inventory/add',
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.romInventory}/add`, data);
    return response.data;
  }
);

export const updateRomInventory = createAsyncThunk(
  'ROM-Inventory/update',
  async (param) => {
    const { updateData } = param;
    const response = await post(`${apiUrls.romInventory}/update`, updateData);
    return response.data;
  }
);
export const deleteRomInventory = createAsyncThunk(
  'ROM-Inventory/delete',
  async (param) => {
    const { id } = param;
    const response = await deleteRequest(`${apiUrls.romInventory}/${id}`);
    return response.data;
  }
);
export const setRomInventoryStatus = createAsyncThunk(
  'ROM-Inventory/setStatus',
  async (param) => {
    const { data } = param;
    const response = await patch(`${apiUrls.romInventory}/set-status`, {
      ...data,
    });
    return response.data;
  }
);
export const saveRomInventory = createAsyncThunk(
  'ROM-Inventory/save',
  async (param) => {
    const { data } = param;
    const response = await put(`${apiUrls.romInventory}/save`, data);
    return response.data;
  }
);
export const submitRomInventory = createAsyncThunk(
  'ROM-Inventory/submit',
  async (param) => {
    const { data } = param;
    const response = await put(`${apiUrls.romInventory}/submit`, data);
    return response.data;
  }
);

export const RomInventorySubmit = createAsyncThunk(
  'ROM-Inventory/submit',
  async (param) => {
    const { id } = param;
    const response = await post(`${apiUrls.romInventory}/submit`, {
      id,
    });
    return response.data;
  }
);

export const adminUpdateRomInventory = createAsyncThunk(
  'ROM-Inventory/adminUpdate',
  async (param) => {
    const { id, adminAction, notes, userName } = param;
    const response = await put(
      `${apiUrls.romInventory}/${id}/${adminAction}?userName=${userName}`,
      {
        notes: adminAction === 'reject' ? notes : '',
      }
    );
    return response.data;
  }
);

export const deleteRomIntoryFile = createAsyncThunk(
  'ROM-Inventory/deleteFile',
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.romInventory}/${id}/files/${name}`
    );
    return response.data;
  }
);

export const uploadRomInventoryFile = createAsyncThunk(
  'ROM-Inventory/uploadFile',
  async (param) => {
    const { id, file } = param;
    console.log({ param });
    const response = await post(`${apiUrls.romInventory}/${id}/files`, file, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);
export const ROM_INVENTORY_REDUCER = 'ROM_INVENTORY_REDUCER';

const romInventoryReducer = createSlice({
  name: ROM_INVENTORY_REDUCER,
  initialState,
  reducers: {
    onResetRomInventoryDetail: (state) => {
      state.romInventoryDetail = {
        data: {},
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchROMInverntoryList.pending, (state, action) => {
      state.romInventoryList.loading = true;
    });
    builder.addCase(fetchROMInverntoryList.fulfilled, (state, action) => {
      const { list, recordsTotal } = action.payload;
      state.romInventoryList.record = list;
      state.romInventoryList.recordsTotal = recordsTotal;
      state.romInventoryList.loading = false;
    });
    builder.addCase(fetchROMInverntoryList.rejected, (state, action) => {
      state.romInventoryList.loading = false;
      state.romInventoryList.error = action.error;
    });
    builder.addCase(fetchRomInventoryDetail.pending, (state, action) => {
      state.romInventoryDetail.loading = true;
    });
    builder.addCase(fetchRomInventoryDetail.fulfilled, (state, action) => {
      state.romInventoryDetail.data = action.payload;
      state.romInventoryDetail.loading = false;
    });
    builder.addCase(fetchRomInventoryDetail.rejected, (state, action) => {
      state.romInventoryDetail.loading = false;
      state.romInventoryDetail.error = action.error;
    });

    builder.addCase(getVersionList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList = action.payload;
    });
    builder.addCase(getVersionList.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(getRomByVersion.pending, (state, action) => {
      state.romInventoryDetail.loading = true;
    });
    builder.addCase(getRomByVersion.fulfilled, (state, action) => {
      state.romInventoryDetail.data = action.payload;
      state.romInventoryDetail.loading = false;
    });
    builder.addCase(getRomByVersion.rejected, (state, action) => {
      state.romInventoryDetail.loading = false;
      state.romInventoryDetail.error = action.error;
    });
  },
});
export const romInventorySelector = (state) => state.romInventory;
export const romInventoryVersionListSelector = (state) =>
  state.romInventory?.versionList;
export const romInventoryDetailSelector = (state) =>
  state.romInventory?.romInventoryDetail;

export const { onResetRomInventoryDetail } = romInventoryReducer.actions;

export default romInventoryReducer.reducer;
