import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo/logo.png';
// import Modal from './Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import LogoCCM from 'assets/images/logo/logo-ccm.png';
import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNotificationList,
  notificationDataSelector,
} from 'containers/Notification/reducer';
import { DATASOURCE_VALUE } from 'utils/helper';
import moment from 'moment';
import { logoutAction } from 'utils/userSession';
import { notificationLink, appVersion } from 'utils/constants';
import { getUserStatus } from 'containers/App/reducer';
import { GiHamburgerMenu } from 'react-icons/gi';

const CMSHeader = (props) => {
  const { toggleSidebar } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [versionApp, setVersionApp] = useState("");
  const { list, recordsTotal, loading, error } = useSelector(
    notificationDataSelector
  );
  const userStatus = useSelector(getUserStatus);

  const token = localStorage.getItem('token');
  let name = 'User';
  if (token) {
    const parsedToken = JSON.parse(token);
    name = parsedToken?.data?.userName;
  }

  const getNotifTitle = (code) => {
    let title = '',
      className = '';
    switch (code) {
      case DATASOURCE_VALUE.waitingApproval:
        title = 'Approval Request';
        className = 'request';
        break;
      case DATASOURCE_VALUE.rejected:
        title = 'Request has been Rejected';
        className = 'rejected';
        break;
      case DATASOURCE_VALUE.done:
        title = 'Request has been Approved';
        className = 'approved';
        break;

      default:
        break;
    }
    return { title, className };
  };

  const getAppVersion = () => {
    return fetch("/services/__app_version", {
      method: "GET",
      keepalive: true
    }).then((res) => {
      res.json().then(data => {
        setVersionApp(data?.version)
        return
      });
    }).catch(err => {
      console.log("error get app version", err);
      return
    })
  }

  useEffect(() => {
    dispatch(
      fetchNotificationList({
        userName: name,
      })
    );
    getAppVersion();
  }, []);

  return (
    <div className="cms-header">
      <div className="container">
        <div className="flex" style={{ display: "flex" }}>
          <div className='flex gap-x-4'>
            <GiHamburgerMenu className="cursor-pointer" onClick={toggleSidebar} />
            <img src={LogoCCM} alt="Logo" onClick={() => navigate('/')} />
            <div className="flex items-center pl-10 fs-12" style={{ display: "flex", alignItems: "center" }}>
              <span className="d-none d-lg-inline">v.{versionApp} </span>
            </div>
          </div>
        </div>

        <div className="cms-header__account">
          <div
            className="cursor-pointer bg-gray-dark cms-header__account__wrapper"
            style={{ marginRight: "1rem", fontSize: "14px", color: "#6F6F77", paddingLeft: "1rem" }}
            onClick={() => setShowNotification(true)}
          >
            <span style={{
              "height": "8px",
              "width": "8px",
              "backgroundColor": userStatus ? "#36B577" : "#bbb",
              "borderRadius": "50%",
              "display": "inline-block",
              "marginRight": "0.5rem",
            }}></span>
            {userStatus ? "Online" : "Offline"}
          </div>
          <div
            className="cursor-pointer cms-header__account__notification"
            onClick={() => setShowNotification(true)}
          >
            <Bell />
          </div>
          <Dropdown align={{ sm: 'end' }}>
            <Dropdown.Toggle className="bg-gray-dark cms-header__account__wrapper">
              <div className="wrapper-user">
                <img src={Logo} alt="Logo" />
              </div>
              <span className="fs-12"> {name} </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setShowLogout(true)}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Modal
        show={showNotification}
        onHide={() => setShowNotification(false)}
        keyboard={false}
        dialogClassName="modal-25w"
      >
        <Modal.Header>
          <Modal.Title style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Notifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="notifications" style={{ padding: 0 }}>
          {list.length === 0
            ? 'No notifications'
            : list
              .slice(0, 3)
              .map(
                ({
                  docReferenceId,
                  docStatus,
                  docId,
                  actionBy,
                  action,
                  actionAt,
                  docModuleCode,
                }) => (
                  <div
                    className="container"
                    key={`notif-${docReferenceId}`}
                    onClick={() => {
                      navigate(
                        notificationLink(docModuleCode, docReferenceId)
                      );
                      setShowNotification(false);
                    }}
                  >
                    <p className={getNotifTitle(docStatus).className}>
                      {getNotifTitle(docStatus).title}
                    </p>
                    <p>
                      {actionBy} has{' '}
                      {docStatus === DATASOURCE_VALUE.waitingApproval
                        ? ' requested your Approval '
                        : action + ' your request '}{' '}
                      {docId} on{' '}
                      {moment(actionAt).format('dddd DD MMMM YYYY')}
                    </p>
                    <p className="note">{moment(actionAt).fromNow()}</p>
                  </div>
                )
              )}
          <div
            className="container last"
            onClick={() => {
              navigate('/notifications');
              setShowNotification(false);
            }}
          >
            See All Notifications
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showLogout}
        onHide={() => setShowLogout(false)}
        keyboard={false}
        dialogClassName="modal-25w"
      >
        <Modal.Header style={{ justifyContent: 'center' }}>
          <Modal.Title style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Logout
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="notifications" style={{ padding: "24px 40px", backgroundColor: '#F5F6F6' }}>
          <p style={{ fontSize: '16px', textAlign: 'center' }}>
            Are you sure you want to logout ?
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
            <button
              style={{ flex: '0.3 0.3 auto', margin: '0 4px', padding: '10px 0', border: '1px solid #cccccc', borderRadius: '5px' }}
              onClick={() => setShowLogout(false)}
            >
              Cancel
            </button>
            <button
              style={{
                flex: '0.3 0.3 auto', margin: '0 4px', padding: '13px 0', backgroundColor: '#FF0C2B',
                color: 'white', border: '1px solid #FF0C2B', borderRadius: '5px'
              }}
              onClick={() => logoutAction()}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
};

export default CMSHeader;
