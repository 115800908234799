import cx from 'classnames';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from 'react-router-dom';

export const Breadcrumbs = ({ breadcrumbsList, className }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb
      className={cx(
        'ccm-breadcrumbs cursor-pointer w-100 d-flex align-items-center bg-gray',
        className
      )}
    >
      {breadcrumbsList.map((item) => (
        <Breadcrumb.Item
          key={item.path || item.label}
          onClick={() => item?.isActive && navigate(item.path)}
          active={!item.isActive}
        >
          {item.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
