import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment-timezone"
import { permissionChecker } from 'utils/helper';
import { getUserLogged } from 'containers/App/reducer';
import * as ROLE from 'config/rolePtm';

const SidebarItem = ({ title, pathname, isActive, icon }) => {
  return (
    <a
      href={pathname}
      className={`menu-item ${isActive && "active"}`}
      onClick={(e) => {
        if (pathname === "/port/crushing-actuals" || pathname === "/port/jetty-actuals") {
          moment.tz.setDefault("Asia/Kuching");
        }
        else moment.tz.setDefault(moment.tz.guess());

        e.preventDefault();
        window.location.href = pathname
      }}
    >
      {icon && icon}
      {title}
    </a>
  );
};

const drawSidebar = (children, activeTab, USER, index) => {
  return children.map(e => {
    if (e.hidden) return null;

    let pagePermission = false
    if (e.permission && e.permission.length > 0) {
      e.permission.map(val => {
        if (permissionChecker(val, "view", false)) pagePermission = true;
      });
    }
    else pagePermission = true

    if (!pagePermission) return null

    if (e?.role) {
      let role = []
      switch (e.role) {
        case "ROLE.MODULE_MASTER_DATA_ROLE":
          role = ROLE.MODULE_MASTER_DATA_ROLE
          break;
        case "ROLE.MODULE_SHIPMENT_ROLE":
          role = ROLE.MODULE_SHIPMENT_ROLE
          break;
        default:
          break;
      }

      if (!role.includes(USER?.role)) return null
    }

    if (e.children && e?.children?.length > 0) {
      return (
        <Accordion.Item eventKey={e.key}>
          <Accordion.Header>{e.title}</Accordion.Header>
          <Accordion.Body>
            <Accordion style={{ marginLeft: '1rem' }}
              defaultActiveKey={activeTab[index]}>
              {drawSidebar(e.children, activeTab, USER, index + 1)}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      )
    }
    else {
      return (
        <SidebarItem
          title={e.title}
          pathname={e.url}
          index={e.key}
          isActive={activeTab.indexOf(e.key) >= 0}
        />
      )
    }
  })
};

const urlKeys = (arr, pref = "", result = {}) => {
  arr.forEach(e => {
    if (e.url == "#") return

    if (e.url) {
      result[e.url] = [...(result[e.url] || []), ...pref.split(";"), e.key].filter((e) => e && e.length)
    }

    if (e.activeUrls) {
      urlKeys(e.activeUrls.map(f => {
        return { key: e.key, url: f }
      }), pref, result)
    }

    if (e.children) {
      urlKeys(e.children, pref + e.key + ";", result)
    }
  })
  return result
};

const CMSSidebar = (props) => {
  const { showSidebar } = props;
  /*
  JSON Paramteter:
    - key : unique ID for each sidebar that have children
    - title
    - url
    - permission : permission checker for each sidebar
    - children : create sidebar that have another sidebar inside of it
    - role : role checker
    - hidden : show/hide sidebar
  */

  const [listSidebar, setListSidebar] = useState([]);
  const activeLocation = window.location.pathname
  const USER = useSelector(getUserLogged);

  useEffect(() => {
    // localhost
    // let url = "http://localhost:3000/listSidebar.json";
    let url = process.env.REACT_APP_SIDEBAR;

    fetch(url)
      .then(res => {
        return res.json()
      })
      .then(out => {
        setListSidebar(out?.listSidebar)
      })
      .catch(err => { throw err });
  }, [])


  const prefKey = urlKeys(listSidebar) || {};
  const currentKey = prefKey[activeLocation] || [];

  return (
    <>
      {listSidebar.length === 0 || !showSidebar ? null :
        <div className="admin-app__sidebar" >
          <div className="menu">
            <Accordion defaultActiveKey={currentKey[0]}>
              {drawSidebar(listSidebar, currentKey, USER, 1)}
            </Accordion>
          </div>
        </div >
      }
    </>
  );
};

export default CMSSidebar;
