import React from 'react';
import { backendUrl } from 'utils/constants';
import { get } from 'utils/request';
import { Outlet } from 'react-router-dom';
import { openDB } from 'idb';

export const redirectToLogin = () => {
  window.location.href = '/login'; // redirect to login
};

export const getToken = () => {
  return JSON.parse(localStorage.getItem('token')) || false;
};

export const logoutAction = () => {
  localStorage.removeItem('token');
  redirectToLogin();
};

export const expiredAction = async () => {
  // session expired
  alert('Session expired! Please re-login to continue.');
  localStorage.removeItem('token');
  localStorage.setItem('lastPage', window.location.href);

  const db = await openDB("token", 1);
  db.clear("tokenStore")
  db.close();

  redirectToLogin();
};

export const checkSession = async () => {
  const { token } = getToken();

  // has no token
  if (!token) {
    redirectToLogin();
  }

  // check session
  const session = await get(backendUrl + '/auth/me?token=' + token);
  if (session.status === 401) {
    expiredAction();
    return false;
  }

  return true;
};

export const ProtectedPage = () => {
  const [isAllowed, setIsAllowed] = React.useState(true);

  React.useEffect(() => {
    setIsAllowed(checkSession());
  }, []);

  return isAllowed ? <Outlet /> : null;
};
