import roles from "./roleList";

export const BIB_ROLE = [roles.ADMIN_BIB, roles.APPROVER_BIB];

export const CONTRACTOR_ROLE = [
  roles.CREATOR_CONTRACTOR,
  roles.CREATOR_BIB_OFFSHORE,
  roles.CREATOR_BIB_MARKETING,
];

export const APPROVER_BIB = [roles.APPROVER_BIB];

// Hide show on Sidebar per module
export const MODULE_SHIPMENT_ROLE = [
  roles.ADMIN_BIB,
  roles.CREATOR_BIB_OFFSHORE,
  roles.CREATOR_BIB_MARKETING,
];

export const MODULE_REPORT_SHIPMENT_ROLE = [
  roles.ADMIN_BIB,
  roles.CREATOR_BIB_MARKETING,
];

export const MODULE_REPORT_VESSEL_ROLE = [
  roles.ADMIN_BIB,
  roles.CREATOR_BIB_OFFSHORE,
  roles.CREATOR_BIB_MARKETING,
  roles.APPROVER_BIB,
  roles.CREATOR_CONTRACTOR
];

export const MODULE_SIMULATION_ROLE = [
  roles.ADMIN_BIB,
  roles.CREATOR_BIB_OFFSHORE,
];

export const MODULE_MASTER_DATA_ROLE = [
  roles.ADMIN_BIB,
  roles.APPROVER_BIB,
  roles.CREATOR_CONTRACTOR,
];
