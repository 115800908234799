import { openDB } from "idb";

export const migrate = () => {
  openDB("master-data", 1, {
    upgrade(db) {
      db.createObjectStore("locations", {
        autoIncrement: true,
      });
      db.createObjectStore("equipments", {
        autoIncrement: true,
      });
      db.createObjectStore("contractors", {
        autoIncrement: true,
      });
      db.createObjectStore("contractor-locations", {
        autoIncrement: true,
      });
      db.createObjectStore("sublocations", {
        autoIncrement: true,
      });
      db.createObjectStore("equipment-details", {
        autoIncrement: true,
      });
      db.createObjectStore("equipment-rom", {
        autoIncrement: true,
      });
      db.createObjectStore("equipment-haul", {
        autoIncrement: true,
      });
      db.createObjectStore("crushers", {
        autoIncrement: true,
      });
      db.createObjectStore("jetties", {
        autoIncrement: true,
      });
    },
  });
  openDB("data-fetch", 1, {
    upgrade(db) {
      db.createObjectStore("get", {
        autoIncrement: true,
      });
    },
  });
};
