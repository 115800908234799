import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, post, put } from 'utils/request';
import { ObjectWrapper, isNumeric, DATASOURCE_VALUE } from 'utils/helper';

var _ = require('lodash');
const serviceName = 'confEwh';

export const initialState = {
  ewhItems: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  ewhMaster: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  ewhDetail: {
    loading: false,
    records: { detail: [], statusLogList: [{ dataStatus: "DRAFT" }], documentList: [], updatedAt: "" },
    recordsTotal: '',
    error: '',
  },
}

export const getewhItems = createAsyncThunk('ewhItems/get', async (params) => {
  const response = await post(apiUrls.confEwh.concat(`filter`), {
    "columns": [
      {
        "data": "reportId",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "bibNotes",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "dataStatus",
        "orderable": true,
        "search": {
          "regex": params?.dataStatus === DATASOURCE_VALUE.waitingApproval || false,
          "value": ""
        },
        "searchValue": params.dataStatus,
        "searchable": true
      },
      {
        "data": "createdAt",
        "orderable": true,
        "search": {
          "regex": true,
          "value": ""
        },
        "searchValue": params.date,
        "searchable": true
      }
    ],
    "order": [],
    "search": {
      "regex": false,
      "value": params.query || ""
    },
    draw: params?.pageIndex ? params.pageIndex : 1,
    length: params?.dataLength ? params.dataLength : -1,
    "start": 0
  });
  return response?.data;
});

export const getDetailService = createAsyncThunk(
  `${serviceName}/detail`,
  async (params) => {
    const { id } = params;
    const response = await get(`${apiUrls.confEwh}/${id}`);
    response.data.detail = response.data.detail.map((e) => {
      const { ewh, equipmentType, delay } = e;
      const totalHours = 12;
      let totalDelay = parseFloat(totalHours - ewh).toFixed(2);
      let sumEWhWithoutBreakdown = 0, sumEwhBreakdown = 0;
      sumEwhBreakdown += delay.breakdown;
      sumEwhBreakdown += ewh;

      sumEWhWithoutBreakdown += delay.fuel;
      sumEWhWithoutBreakdown += delay.meal;
      sumEWhWithoutBreakdown += delay.p2h;
      sumEWhWithoutBreakdown += delay.p5m;

      let pa = parseFloat(sumEWhWithoutBreakdown / (ewh + totalDelay)).toFixed(2);
      let ma = parseFloat(ewh / sumEwhBreakdown).toFixed(2);
      let ua = parseFloat(ewh / sumEWhWithoutBreakdown).toFixed(2);
      let eu = parseFloat(ewh / (ewh + totalDelay)).toFixed(2);

      if (ewh === 0) {
        totalDelay = 0;
        pa = 0;
        ma = 0;
        ua = 0;
        eu = 0;
      }

      return {
        equipmentType: equipmentType,
        ewh: ewh,
        totalHours: totalHours,
        totalDelay: totalDelay,
        bibNotes: "",
        delay: {
          breakdown: delay.breakdown,
          fuel: delay.fuel,
          meal: delay.meal,
          p2h: delay.p2h,
          p5m: delay.p5m
        },
        shift: {
          pa: pa,
          ma: ma,
          ua: ua,
          eu: eu,
        }
      }
    })
    return response?.data;
  }
);

export const addService = createAsyncThunk(
  `${serviceName}/add`,
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.confEwh}/save`, data);
    return response.data;
  }
);

export const saveService = createAsyncThunk(
  `${serviceName}/save`,
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.confEwh}/save`, data);
    return response.data;
  }
);

export const submitService = createAsyncThunk(
  `${serviceName}/submit`,
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.confEwh}/submit`, data);
    return response.data;
  }
);

export const uploadFileService = createAsyncThunk(
  `${serviceName}/uploadFile`,
  async (param) => {
    const { id, file } = param;
    const response = await post(`${apiUrls.confEwh}/${id}/files`, file, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);

export const deleteFileService = createAsyncThunk(
  `${serviceName}/deleteFile`,
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.confEwh}/${id}/files/${name}`
    );
    return response.data;
  }
);

export const updateDataService = createAsyncThunk(
  `${serviceName}/adminUpdate`,
  async (param) => {
    const { id, action, username, data } = param;
    const response = await put(
      `${apiUrls.confEwh}/${id}/${action}?userName=${username}`, data
    );
    return response.data;
  }
);

export const getMasterData = createAsyncThunk(
  `${serviceName}/master`,
  async (params) => {
    const response = await post(`${apiUrls.masterData}/equipment-haul/filter`, {
      "columns": [
        {
          "data": "entityStatus",
          "orderable": true,
          "search": {
            "regex": false,
            "value": ""
          },
          "searchValue": 1,
          "searchable": true
        },
        {
          "data": "dataStatus",
          "orderable": true,
          "search": {
            "regex": true,
            "value": ""
          },
          "searchValue": 3,
          "searchable": true
        },
      ],
      "order": [],
      "draw": 1,
      "length": -1,
      "start": 0,
    });
    var filtered = response.data.list.map((v) => {
      return {
        equipmentType: v.equipment,
        ewh: 0,
        totalHours: 12,
        totalDelay: 0,
        bibNotes: v.bibNotes,
        delay: {
          breakdown: '0',
          fuel: '0',
          meal: '0',
          p2h: '0',
          p5m: '0'
        },
        shift: {
          pa: '0',
          ma: '0',
          ua: '0',
          eu: '0',
        }
      }
    })
    response.data.list = filtered
    return response?.data;
  }
);

// export const getEwhDetail = createAsyncThunk('ewhDetail/get', async (params) => {
//   const response = await get(apiUrls.confEwh.concat(`${params}`));
//   response.data.detail = response.data.detail.map((e) => {
//     return {
//       equipmentType: e.equipmentType,
//       ewh: e.ewh,
//       totalHours: 12,
//       totalDelay: 0,
//       bibNotes: "",
//       delay: {
//         breakdown: e.delay.breakdown,
//         fuel: e.delay.fuel,
//         meal: e.delay.meal,
//         p2h: e.delay.p2h,
//         p5m: e.delay.p5m
//       },
//       shift: {
//         pa: '0',
//         ma: '0',
//         ua: '0',
//         eu: '0',
//       }
//     }
//   })
//   return response?.data;
// });

const ConfEwh = createSlice({
  name: "CONF_EWH",
  initialState,
  reducers: {
    onChangeTableInput: (state, action) => {
      const payload = action.payload;
      if (!isNumeric(payload.value)) {
        payload.value = '0'
      }
      var arrStr = payload.value.toString().split('')
      if (arrStr.length >= 2) {
        if (arrStr[1] != "." && arrStr[0] == '0') {
          payload.value = payload.value.toString().split('').splice(1).join('')
        }
      }

      let totalDelay = 0;
      totalDelay += payload.tableRowName == 'delay.breakdown' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['breakdown'])
      totalDelay += payload.tableRowName == 'delay.fuel' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['fuel'])
      totalDelay += payload.tableRowName == 'delay.meal' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['meal'])
      totalDelay += payload.tableRowName == 'delay.p2h' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['p2h'])
      totalDelay += payload.tableRowName == 'delay.p5m' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['p5m'])

      var ewh = state.ewhMaster.records[payload.tableIndexRow]['totalHours'] - Number(totalDelay);
      var sumEWhWithoutBreakdown = 0;
      sumEWhWithoutBreakdown += payload.tableRowName == 'delay.fuel' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['fuel'])
      sumEWhWithoutBreakdown += payload.tableRowName == 'delay.meal' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['meal'])
      sumEWhWithoutBreakdown += payload.tableRowName == 'delay.p2h' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['p2h'])
      sumEWhWithoutBreakdown += payload.tableRowName == 'delay.p5m' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['p5m'])
      sumEWhWithoutBreakdown += ewh

      var sumEwhBreakdown = 0;
      sumEwhBreakdown += payload.tableRowName == 'delay.breakdown' ? Number(payload.value) : Number(state.ewhMaster.records[payload.tableIndexRow]['delay']['breakdown'])
      sumEwhBreakdown += ewh
      var pa = parseFloat(sumEWhWithoutBreakdown / (ewh + totalDelay)).toFixed(2)
      var ma = parseFloat(ewh / sumEwhBreakdown).toFixed(2)
      var ua = parseFloat(ewh / sumEWhWithoutBreakdown).toFixed(2)
      var eu = parseFloat(ewh / (ewh + totalDelay)).toFixed(2)
      if (totalDelay === 0) {
        pa = 0;
        ma = 0;
        ua = 0;
        eu = 0;
        ewh = 0;
      }

      if (totalDelay <= 12) {
        _.set(state.ewhMaster.records[payload.tableIndexRow], payload.tableRowName, payload.value)
        _.set(state.ewhMaster.records[payload.tableIndexRow], 'totalDelay', parseFloat(totalDelay).toFixed(2))
        _.set(state.ewhMaster.records[payload.tableIndexRow], 'ewh', ewh)
        _.set(state.ewhMaster.records[payload.tableIndexRow], 'shift.pa', pa)
        _.set(state.ewhMaster.records[payload.tableIndexRow], 'shift.ma', ma)
        _.set(state.ewhMaster.records[payload.tableIndexRow], 'shift.ua', ua)
        _.set(state.ewhMaster.records[payload.tableIndexRow], 'shift.eu', eu)
      }
    },
    onChangeTableInputDetail: (state, action) => {
      const payload = action.payload;
      if (!isNumeric(payload.value)) {
        payload.value = '0'
      }
      var arrStr = payload.value.toString().split('')
      if (arrStr.length >= 2) {
        if (arrStr[1] != "." && arrStr[0] == '0') {
          payload.value = payload.value.toString().split('').splice(1).join('')
        }
      }
      let totalDelay = 0;
      totalDelay += payload.tableRowName == 'delay.breakdown' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['breakdown'])
      totalDelay += payload.tableRowName == 'delay.fuel' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['fuel'])
      totalDelay += payload.tableRowName == 'delay.meal' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['meal'])
      totalDelay += payload.tableRowName == 'delay.p2h' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['p2h'])
      totalDelay += payload.tableRowName == 'delay.p5m' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['p5m'])

      var ewh = state.ewhDetail.records.detail[payload.tableIndexRow]['totalHours'] - Number(totalDelay);
      var sumEWhWithoutBreakdown = 0;
      sumEWhWithoutBreakdown += payload.tableRowName == 'delay.fuel' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['fuel'])
      sumEWhWithoutBreakdown += payload.tableRowName == 'delay.meal' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['meal'])
      sumEWhWithoutBreakdown += payload.tableRowName == 'delay.p2h' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['p2h'])
      sumEWhWithoutBreakdown += payload.tableRowName == 'delay.p5m' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['p5m'])
      sumEWhWithoutBreakdown += ewh

      var sumEwhBreakdown = 0;
      sumEwhBreakdown += payload.tableRowName == 'delay.breakdown' ? Number(payload.value) : Number(state.ewhDetail.records.detail[payload.tableIndexRow]['delay']['breakdown'])
      sumEwhBreakdown += ewh
      var pa = parseFloat(sumEWhWithoutBreakdown / (ewh + totalDelay)).toFixed(2)
      var ma = parseFloat(ewh / sumEwhBreakdown).toFixed(2)
      var ua = parseFloat(ewh / sumEWhWithoutBreakdown).toFixed(2)
      var eu = parseFloat(ewh / (ewh + totalDelay)).toFixed(2)
      if (totalDelay === 0) {
        pa = 0;
        ma = 0;
        ua = 0;
        eu = 0;
        ewh = 0;
      }

      if (totalDelay <= 12) {
        _.set(state.ewhDetail.records.detail[payload.tableIndexRow], payload.tableRowName, payload.value)
        _.set(state.ewhDetail.records.detail[payload.tableIndexRow], 'totalDelay', parseFloat(totalDelay).toFixed(2))
        _.set(state.ewhDetail.records.detail[payload.tableIndexRow], 'ewh', ewh)
        _.set(state.ewhDetail.records.detail[payload.tableIndexRow], 'shift.pa', pa)
        _.set(state.ewhDetail.records.detail[payload.tableIndexRow], 'shift.ma', ma)
        _.set(state.ewhDetail.records.detail[payload.tableIndexRow], 'shift.ua', ua)
        _.set(state.ewhDetail.records.detail[payload.tableIndexRow], 'shift.eu', eu)
      }
    },
    onReset: (state, action) => {
      state.ewhDetail = {
        loading: false,
        records: { detail: [], statusLogList: [{ dataStatus: "DRAFT" }], documentList: [], updatedAt: "" },
        recordsTotal: '',
        error: '',
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getewhItems.pending, (state, action) => {
      state.ewhItems.loading = true;
    });
    builder.addCase(getewhItems.fulfilled, (state, action) => {
      state.ewhItems.records = action.payload.list;
      state.ewhItems.recordsTotal = action.payload.recordsTotal;
      state.ewhItems.loading = false;
    });
    builder.addCase(getewhItems.rejected, (state, action) => {
      state.ewhItems.loading = false;
      state.ewhItems.error = 'Invalid get data';
    });

    builder.addCase(getDetailService.pending, (state, action) => {
      state.ewhDetail.loading = true;
    });
    builder.addCase(getDetailService.fulfilled, (state, action) => {
      state.ewhDetail.records = action.payload;
    });
    builder.addCase(getDetailService.rejected, (state, action) => {
      state.ewhDetail.loading = false;
      state.ewhDetail.error = 'Invalid get data';
    });

    builder.addCase(getMasterData.pending, (state, action) => {
      state.ewhMaster.loading = true;
    });
    builder.addCase(getMasterData.fulfilled, (state, action) => {
      state.ewhMaster.records = action.payload.list;
      state.ewhMaster.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterData.rejected, (state, action) => {
      state.ewhMaster.loading = false;
      state.ewhMaster.error = 'Invalid get data';
    });
  },
});
export const {
  onChangeTableInput,
  onChangeTableInputDetail,
  onReset
} = ConfEwh.actions;
export const ewhItemsSelector = (state) => state.ConfEwh.ewhItems;
export const ewhDetailSelector = (state) => state.ConfEwh.ewhDetail;
export const ewhMasterSelector = (state) => state.ConfEwh.ewhMaster;

export default ConfEwh.reducer;
