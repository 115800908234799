import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUsername } from 'utils/helper';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';

export const initialState = {
  baDistanceHaulingList: {
    records: [],
    loading: false,
    error: null,
    loading: false,
    error: '',
    recordsTotal: '',
  },
  baDistanceHaulingDetail: {
    records: [],
    response: {},
    loading: false,
    error: null,
    statusLogList: [],
    documentList: [],
  },
  versionList: [],
};

export const BA_DISTANCE_HAULING_REDUCER = 'BA_DISTANCE_HAULING_REDUCER';

export const fetchBaDistanceHaulingList = createAsyncThunk(
  'ba-distance-hauling/list',
  async (params) => {

    const response = await post(`${apiUrls.distanceHauling}/filter`, {
      columns: [
        {
          data: 'id',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: false,
        },
        {
          data: 'reportId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params.dataStatus === 2 || false,
            value: '',
          },
          searchValue: params.dataStatus || '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: params.date ? true : false,
            value: '',
          },
          searchValue: params.date || '',
          searchable: params.search ? false : true,
        },
        {
          data: 'distancePeriod',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.distancePeriod || '',
          searchable: true,
        },
        {
          data: 'version',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.version || '',
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      order: [
      ],
      search: {
        regex: false,
        value: params.query || '',
      },
      start: 0,
    });
    return response?.data;
  }
);

export const fetchBaDistanceDetail = createAsyncThunk(
  'ba-distance-hauling/detail',
  async (param) => {
    const { id } = param;
    const response = await get(`${apiUrls.distanceHauling}/${id}`);
    return response.data;
  }
);

export const getVersionList = createAsyncThunk(
  'ba-distance-hauling/versions',
  async (param) => {
    const { reportId } = param;
    const response = await get(`${apiUrls.distanceHauling}/${reportId}/versions`);
    return response.data;
  }
);

export const setActivatedVersion = createAsyncThunk(
  'ba-distance-hauling/setActivatedVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await patch(
      `${apiUrls.distanceHauling}/${reportId}/versions/${version}?userName=${getUsername()}`
    );
    return response.data;
  }
);

export const handleCreateBaDistance = createAsyncThunk(
  'ba-distance-hauling/create-new',
  async (param) => {
    const { action, object, userName } = param;
    const response = await post(
      `${apiUrls.distanceHauling}/${action}?userName=${getUsername()}`,
      object
    );
    return response.data;
  }
);

export const getReportByVersion = createAsyncThunk('ba-distance-hauling/getByVersion', async (params) => {
  const { reportId, version } = params;
  const response = await get(`${apiUrls.distanceHauling}/${reportId}/versions/${version}`);
  return response?.data;
})

export const handleEditBaDistance = createAsyncThunk(
  'ba-distance-hauling/edit',
  async (param) => {
    const { action, object, id, userName } = param;
    const response = await put(
      `${apiUrls.distanceHauling}/${id}/${action}?userName=${getUsername()}`,
      object
    );
    return response.data;
  }
);

export const savePitProgress = createAsyncThunk(
  'ba-distance-hauling/save',
  async (param) => {
    const { data } = param;
    const response = await put(`${apiUrls.distanceHauling}/save`, data);
    return response.data;
  }
);

export const handleDeleteBaDistance = createAsyncThunk(
  'ba-distance-hauling/delete',
  async (param) => {
    const { id, userName } = param;
    const response = await deleteRequest(
      `${apiUrls.distanceHauling}/${id}?userName=${getUsername()}`
    );
    return response.data;
  }
);

export const handleApproveBaDistance = createAsyncThunk(
  'ba-distance-hauling/approve',
  async (param) => {
    const { id, userName, action, notes } = param;
    const response = await put(
      `${apiUrls.distanceHauling}/${id}/${action}?userName=${getUsername()}`,
      { notes: action === 'reject' ? notes : '' }
    );
    return response.data;
  }
);

export const uploadFile = createAsyncThunk(
  'ba-distance-hauling/uploadFile',
  async ({ id, file }) => {
    let formData = new FormData();
    let newFile = new File([file], file.name, { type: file.type });
    formData.append('file', newFile);

    const response = await post(`${apiUrls.distanceHauling}/${id}/files`, formData, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);

export const deleteFile = createAsyncThunk(
  'ba-distance-hauling/deleteFile',
  async ({ id, file }) => {
    const response = await deleteRequest(`${apiUrls.distanceHauling}/${id}/files/${file.name}`, {});
    return response.data;
  }
)

const baDistanceHaulingSlice = createSlice({
  name: BA_DISTANCE_HAULING_REDUCER,
  initialState,
  reducers: {
    clearBADetail: (state, action) => {
      state.baDistanceHaulingDetail = {
        records: [],
        response: {},
        loading: false,
        error: null,
        statusLogList: [],
        documentList: [],
      };
    },

    addDataTableBA: (state, action) => {
      state.baDistanceHaulingDetail?.records.push(action.payload);
    },

    onEditBA: (state, action) => {
      const payload = action.payload;

      state.baDistanceHaulingDetail.records[payload.tableIndexRow][
        payload.tableRowName
      ] = payload.value;
    },
    onRemoveDataTableBA: (state, action) => {
      const payload = action.payload;

      state.baDistanceHaulingDetail.records.splice(payload.tableIndexRow, 1);
    },

    onAddFileBA: (state, action) => {
      const payload = action.payload;
      if (state.baDistanceHaulingDetail.documentList) {
        state.baDistanceHaulingDetail.documentList.unshift(payload);
      } else {
        var arr = [payload];
        state.baDistanceHaulingDetail.documentList = arr;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBaDistanceHaulingList.pending, (state, action) => {
      state.baDistanceHaulingList.loading = true;
    });
    builder.addCase(fetchBaDistanceHaulingList.fulfilled, (state, action) => {
      state.baDistanceHaulingList.records = action.payload.list;
      state.baDistanceHaulingList.recordsTotal = action.payload.recordsTotal;
      state.baDistanceHaulingList.loading = false;
    });
    builder.addCase(fetchBaDistanceHaulingList.rejected, (state, action) => {
      state.baDistanceHaulingList.loading = false;
      state.baDistanceHaulingList.error = action.error;
    });

    builder.addCase(fetchBaDistanceDetail.pending, (state, action) => {
      state.baDistanceHaulingDetail.loading = true;
    });
    builder.addCase(fetchBaDistanceDetail.fulfilled, (state, action) => {
      state.baDistanceHaulingDetail.records = action.payload.distanceList;
      state.baDistanceHaulingDetail.response = action.payload;
      state.baDistanceHaulingDetail.loading = false;
    });
    builder.addCase(fetchBaDistanceDetail.rejected, (state, action) => {
      state.baDistanceHaulingDetail.loading = false;
      state.baDistanceHaulingList.error = action.error;
    });

    builder.addCase(getVersionList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList = action.payload;
    });
    builder.addCase(getVersionList.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });
  },
});
export const baDistanceHaulingSelector = (state) =>
  state.baDistanceHauling.baDistanceHaulingList;
export const baDistanceHaulingDetailSelector = (state) =>
  state.baDistanceHauling.baDistanceHaulingDetail;
export const baDistanceHaulingVersionSelector = (state) =>
  state.baDistanceHauling?.versionList;

export const {
  clearBADetail,
  onEditBA,
  addDataTableBA,
  onRemoveDataTableBA,
  onAddFileBA,
  onRemoveFileBA,
} = baDistanceHaulingSlice.actions;

export default baDistanceHaulingSlice.reducer;
