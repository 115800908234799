import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAPIEndpoint, get, post } from 'utils/request';
import { genReportId } from "utils/helper";
import { dummyData, dataItems } from './constant.js'; // will be removed after integrations

const reportId = "HP-DSR";
const defaultValue = {
  id: null,
  reportId: genReportId(reportId),
  shift: null,
  detail: [],
  version: 1,
  activeVersionFlag: true,
  dataStatus: 1,
  statusLogList: [],
  documentList: [],
  createdAt: new Date().toISOString(),
};
export const initialState = {
  tableData: {
    list: [],
    recordsTotal: 0,
  },
  detailData: defaultValue,
  locationList: [],
};

export const FLEET_ACTUAL_HAULING_REDUCER = 'FLEET_ACTUAL_HAULING_REDUCER';

// data fetch functions
export const fetchFilteredList = createAsyncThunk(
  'fleet-actual-haul/list',
  async (params) => {
    const filter = {
      "columns": [
        {
          "data": "id",
          "orderable": true,
          "search": {
            "regex": false,
            "value": ""
          },
          "searchValue": "",
          "searchable": false
        },
        {
          "data": "reportId",
          "orderable": true,
          "search": {
            "regex": false,
            "value": ""
          },
          "searchValue": "",
          "searchable": true
        },
        {
          "data": "dataStatus",
          "orderable": true,
          "search": {
            "regex": params.dataStatus === 2 || false,
            "value": ""
          },
          "searchValue": params.dataStatus || "",
          "searchable": true
        },
        {
          "data": "createdAt",
          "orderable": true,
          "search": {
            "regex": true,
            "value": ""
          },
          "searchValue": params.date || "",
          "searchable": true
        },
        {
          "data": "version",
          "orderable": true,
          "search": {
            "regex": false,
            "value": ""
          },
          "searchValue": params.version || "",
          "searchable": true
        },
        {
          "data": "shift",
          "orderable": true,
          "search": {
            "regex": false,
            "value": ""
          },
          "searchValue": params.shift || "",
          "searchable": true
        }
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      "search": {
        "regex": false,
        "value": params.query || ""
      },
      "start": 0
    };
    const response = await post(
      getAPIEndpoint("fleet-actual-haul", "v1", "/filter"),
      filter
    )
    return response.data;
  }
);

export const fetchDetail = createAsyncThunk(
  'fleet-actual-haul/detail',
  async (id) => {
    const response = await get(getAPIEndpoint("fleet-actual-haul", "v1", "/" + id));
    return response.data;
  }
)

const fleetActualHaulingSlice = createSlice({
  name: FLEET_ACTUAL_HAULING_REDUCER,
  initialState,
  reducers: {
    clearFleetDetail: (state, action) => {
      state.fleetActualHaulingDetail = {
        records: [],
        response: {},
        loading: false,
        error: null,
        statusLogList: [],
        documentList: [],
      }
    },

    addDataTableFleet: (state, action) => {
      state.fleetActualHaulingDetail?.records.unshift(action.payload);
    },

    onEditFleet: (state, action) => {
      const payload = action.payload;

      state.fleetActualHaulingDetail.records[payload.tableIndexRow][payload.tableRowName] = payload.value;
    },
    onRemoveDataTableFleet: (state, action) => {
      const payload = action.payload;

      state.fleetActualHaulingDetail.records.splice(payload.tableIndexRow, 1);
    },

    onAddFileFleet: (state, action) => {
      const payload = action.payload;
      if (state.fleetActualHaulingDetail.documentList) {
        state.fleetActualHaulingDetail.documentList.unshift(payload);
      }
      else {
        var arr = [payload]
        state.fleetActualHaulingDetail.documentList = arr
      }
    },

    onRemoveFileFleet: (state, action) => {
      state.fleetActualHaulingDetail.documentList.splice(0, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFilteredList.pending, (state, action) => {
      state.tableData.loading = true;
    });
    builder.addCase(fetchFilteredList.fulfilled, (state, action) => {
      state.tableData = action.payload
      state.tableData.loading = false;
    });
    builder.addCase(fetchFilteredList.rejected, (state, action) => {
      state.tableData.loading = false;
    });
    builder.addCase(fetchDetail.fulfilled, (state, action) => {
      let dataPayload = action.payload

      // get data location from detail
      let locations = [];
      action.payload.detail.forEach((item) => {
        item.location.forEach((loc) => {
          if (locations.findIndex((x) => loc.locationId === x.locationId) === -1) {
            // add format to list option
            locations.push({
              ...loc,
              label: loc.locationCode,
              value: loc.locationId,
              code: loc.locationCode,
            });
          }
        })
      });

      locations = [...locations].sort((a, b) =>
        a.label > b.label ? 1 : -1,
      );

      dataPayload.detail.map((val, index) => {
        locations.map((i, num) => {
          if (val.location.findIndex((x) => i.locationId === x.locationId) === -1) {
            val.location.push({
              ...i,
              label: i.locationCode,
              value: i.locationId,
              code: i.locationCode,
              "unitBIB": 0,
              "unitBIR": 0,
              "unitTIA": 0,
              "totalBibBirTia": 0,
              "cycleTimeBIB": 5,
              "cycleTimeBIR": 5,
              "cycleTimeTIA": 10,
              "totalDT": 0,
              "totalSDT": 0,
              "totalDDT": 0,
              "totalProduction": 0,
            })
          }
        })

        val.location = [...val.location].sort((a, b) =>
          a.locationCode > b.locationCode ? 1 : -1,
        );
      })


      state.detailData = dataPayload

      // set location to reducer state
      state.locationList = [...new Set(locations)];
    });
  },
});
export const fleetActualHaulingSelector = (state) => state.fleetActualHauling.tableData;
export const fleetActualHaulingDetailSelector = (state) => state.fleetActualHauling.detailData;
export const fleetActualHaulLocations = (state) => state.fleetActualHauling.locationList;

export const {
  clearFleetDetail,
  onEditFleet,
  addDataTableFleet,
  onRemoveDataTableFleet,
  onAddFileFleet,
  onRemoveFileFleet
} = fleetActualHaulingSlice.actions;

export default fleetActualHaulingSlice.reducer;
