import React, { lazy } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { fetchUserSession, changeUserStatus } from "./reducer";
import { migrate } from "database/idb";

/*
    You can import and use layout inside route modules
    to adjust UI based their path or categorized
*/

// import route modules
const MainRoutes = lazy(() => import("./MainRoutes"));
const DashboardRoutes = lazy(() => import("./DashboardRoutes"));

function App() {
  const dispatch = useDispatch();

  const checkConnection = () => {
    return fetch("/services/connection-check", {
      method: "GET",
      keepalive: true
    }).then((res) => {
      res.json().then(data => {
        if (data.isOnline) {
          dispatch(changeUserStatus(true))
          return
        }
        dispatch(changeUserStatus(false))
        return
      });
    }).catch(err => {
      dispatch(changeUserStatus(false))
      return
    })
  }

  React.useEffect(() => {
    const timer = setInterval(() => {
      checkConnection();
    }, 1000 * 5);

    return () => {
      clearInterval(timer);
    };
  }, [])

  React.useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) {
      const token = JSON.parse(localToken);
      dispatch(fetchUserSession(token?.token));
    }
  }, []);

  React.useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      const token = JSON.parse(localToken);
      dispatch(fetchUserSession(token?.token));
    }

    migrate();
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/*" element={<MainRoutes />} />
          <Route exact path="/dashboard/*" element={<DashboardRoutes />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
