export const defaultMonthlyData = [
    {
      header: 'OB Inpit',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Soil Inpit',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Mud Original Inpit',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Total Waste Inpit',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: true,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'OB Outpit',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Soil Outpit',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Mud Original Outpit',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Total Waste Outpit',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: true,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'OB Project',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Soil Project',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Mud Original Project',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Total Waste Project',
      unit: 'BCM',
      code: 'waste',
      isHeader: false,
      isSubHeader: true,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Total Waste',
      unit: 'BCM',
      code: 'waste',
      isHeader: true,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Opening Inventory',
      unit: 'tonnes',
      code: 'coal',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Coal Expose',
      unit: 'tonnes',
      code: 'coal',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Coal to Mine',
      unit: 'tonnes',
      code: 'coal',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Cap Unit Coal',
      unit: 'tonnes',
      code: 'coal',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Coal Getting',
      unit: 'tonnes',
      code: 'coal',
      isHeader: true,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Closing Inventory',
      unit: 'tonnes',
      code: 'sr',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'SR Expose',
      unit: 'BCM/ton',
      code: 'sr',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'SR Inpit(CG)',
      unit: 'BCM/ton',
      code: 'sr',
      isHeader: true,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'SR All',
      unit: 'BCM/ton',
      code: 'sr',
      isHeader: true,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Coal',
      unit: 'meter',
      code: 'distance',
      isHeader: true,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance OB Inpit',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Soil Inpit',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Mud Original Inpit',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Waste Inpit',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: true,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance OB Outpit',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Soil Outpit',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Mud Original Outpit',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Waste Outpit',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: true,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance OB Project',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Soil Project',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Mud Original Project',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Waste Project',
      unit: 'meter',
      code: 'distance',
      isHeader: false,
      isSubHeader: true,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      header: 'Distance Waste All',
      unit: 'meter',
      code: 'distance',
      isHeader: true,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'ASH',
      unit: '%',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'CV_ADB',
      unit: 'kcal/kg',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'CV_GAR',
      unit: 'kcal/kg',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'FC',
      unit: '%',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'IM',
      unit: '%',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'RD_IS',
      unit: 'ton/bcm',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'TM',
      unit: '%',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'TS',
      unit: '%',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'VM',
      unit: '%',
      isHeader: false,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    },
    {
      code: 'quality',
      header: 'Quality',
      unit: '',
      isHeader: true,
      isSubHeader: false,
      disabledRow: false,
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      year: 0
    }
  ];

export const mapDataMonthly = (data) => {
  if (!data) return defaultMonthlyData;

  return defaultMonthlyData.map(item => {
    const matchingDataItem = data.find(dataItem =>
      dataItem.header === item.header &&
      dataItem.unit === item.unit &&
      dataItem.code === item.code
    );

    if (matchingDataItem) {
      return {
        ...item,
        Jan: matchingDataItem.Jan,
        Feb: matchingDataItem.Feb,
        Mar: matchingDataItem.Mar,
        Apr: matchingDataItem.Apr,
        May: matchingDataItem.May,
        Jun: matchingDataItem.Jun,
        Jul: matchingDataItem.Jul,
        Aug: matchingDataItem.Aug,
        Sep: matchingDataItem.Sep,
        Oct: matchingDataItem.Oct,
        Nov: matchingDataItem.Nov,
        Dec: matchingDataItem.Dec,
        year: matchingDataItem.year
      };
    } else {
      return item;
    }
  });
};
