import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAPIEndpoint, post, get } from 'utils/request';
import { backendUrl } from 'utils/constants';

export const initialState = {
  notificationList: {
    list: [],
    recordsTotal: 0,
    loading: false,
    error: false,
  },
};

export const fetchNotificationList = createAsyncThunk(
  'users/notifications',
  async (params) => {
    const { userName, dataStatus } = params;
    const response = await post(
      getAPIEndpoint('user', 'v1', 'notifications/filter'),
      {
        columns: [
          {
            data: 'id.userName',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: userName || '',
            searchable: true,
          },
          {
            data: 'actionAt',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: '',
            searchable: true,
          },
          {
            data: 'docStatus',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchValue: dataStatus || '',
            searchable: true,
          },
        ],
        draw: 1,
        length: -1,
        search: {
          regex: true,
          value: '',
        },
      }
    );

    const filterResponse = response?.data?.list?.map((e, i) => {
      if (e.action == "Approved" || e.action == "Rejected" || e.action == "Request Approval") return { ...e }
    }) || [];
    const filtered = filterResponse.filter((el) => { return el != undefined });

    const obj = {
      list: filtered,
      recordsTotal: filtered.length
    }

    return obj;
  }
);

const notificationSlicer = createSlice({
  name: 'NOTIFICATIONS',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationList.pending, (state, action) => {
      state.notificationList.loading = true;
    });
    builder.addCase(fetchNotificationList.fulfilled, (state, action) => {
      const { list, recordsTotal } = action.payload;
      state.notificationList.list = list;
      state.notificationList.recordsTotal = recordsTotal;
      state.notificationList.loading = false;
    });
    builder.addCase(fetchNotificationList.rejected, (state, action) => {
      state.notificationList.loading = false;
      state.notificationList.error = action.error;
    });
  },
});

export const notificationDataSelector = (state) =>
  state.notifications.notificationList;

export default notificationSlicer.reducer;
