import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { genUUID, genReportId, slicingRequestBody } from 'utils/helper';
import {
  getAPIEndpoint,
  apiUrls,
  deleteRequest,
  get,
  patch,
  post,
  put,
} from 'utils/request';

const reportId = 'FL-ROM-XXX';
const defaultValue = {
  id: null,
  reportId: genReportId(reportId),
  shift: null,
  detail: [],
  version: 1,
  activeVersionFlag: true,
  dataStatus: 1,
  statusLogList: [],
  documentList: [],
  createdAt: new Date().toISOString(),
};
const defaultFilter = {
  createdAt: null,
  shift: null,
  version: null,
  dataStatus: null,
  searchQuery: null,
};

export const initialState = {
  tableFilters: {
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filter: defaultFilter,
  },
  tableData: {
    loading: false,
    recordsTotal: 0,
    list: [],
    request: {
      length: 10,
      start: 0,
    },
  },
  shiftList: [
    {
      value: 'DAY',
      label: 'Day',
    },
    {
      value: 'NIGHT',
      label: 'Night',
    },
  ],
  versionList: [],
  detailData: defaultValue,
  detailVersionList: [],
  detailMasterData: {
    rom: [],
    contractor: [],
    unit: [],
    equipment: [],
  },
};

export const FLEET_PLAN_ROM_REDUCER = 'FLEET_PLAN_ROM_REDUCER';

export const fetchVersionList = createAsyncThunk(
  'fleet-plan-rom/versions',
  async () => {
    const response = await get(
      getAPIEndpoint('fleet-plan-rom', 'v1', '/versions')
    );
    return response.data;
  }
);

export const fetchFilteredList = createAsyncThunk(
  'fleet-plan-rom/filtered-list',
  async (filter) => {
    const response = await post(
      getAPIEndpoint('fleet-plan-rom', 'v1', '/filter'),
      filter
    );
    return response.data;
  }
);

export const fetchDetail = createAsyncThunk(
  'fleet-plan-rom/detail',
  async (id) => {
    const details = await get(getAPIEndpoint('fleet-plan-rom', 'v1', `/${id}`));
    const versionList = await get(
      getAPIEndpoint(
        'fleet-plan-rom',
        'v1',
        `/${details.data.reportId}/versions`
      )
    );
    return {
      details: details.data,
      versionList: versionList.data,
    };
  }
);

export const fetchDetailTableMasterData = createAsyncThunk(
  'fleet-plan-rom/DetailTableMasterData',
  async () => {
    let filterHeader = {
      columns: [
        {
          data: 'dataStatus',
          name: 'dataStatus',
          orderable: true,
          search: {
            regex: false,
            value: '3',
          },
          searchable: true,
        },
        {
          data: 'area.name',
          name: 'area.name',
          orderable: true,
          search: {
            regex: true,
            value: 'rom',
          },
          searchable: true,
        },
        {
          data: 'area.dataStatus',
          name: 'area.dataStatus',
          orderable: true,
          search: {
            regex: true,
            value: '3',
          },
          searchable: true,
        },
      ],
      length: -1,
      start: 0,
    };
    let filterRow = {
      columns: [
        {
          data: 'dataStatus',
          name: 'dataStatus',
          orderable: true,
          search: {
            regex: true,
            value: '3',
          },
          searchable: true,
        },
        {
          data: 'equipment.type',
          name: 'equipment.type',
          orderable: true,
          search: {
            regex: true,
            value: 'excavator',
          },
          searchable: true,
        },
      ],
      length: -1,
      start: 0,
    };
    const responseRowData = await post(
      getAPIEndpoint('master-data', 'v1', '/equipment-rom/filter'),
      filterRow
    );
    const responseHeaderData = await post(
      getAPIEndpoint('master-data', 'v1', '/locations/filter'),
      filterHeader
    );

    let data = {};
    data.rom = responseHeaderData.data.list.map((e) => {
      return {
        label: e.name,
        value: e.id,
        name: e.name,
        code: e.code,
      };
    });
    data.contractor = responseRowData.data.list
      .map((e) => e.contractor)
      .filter((e, i, arr) => arr.indexOf(e) == i)
      .map((e) => {
        return {
          label: e,
          value: e,
        };
      });
    data.unit = responseRowData.data.list
      .map((e) => {
        return JSON.stringify({
          contractor: e.contractor,
          equipmentClass: e.equipmentClass,
          label: e.equipmentClass,
          value: e.equipmentClass,
        });
      })
      .filter((e, i, arr) => arr.indexOf(e) == i)
      .map((e) => JSON.parse(e));
    data.equipment = responseRowData.data.list
      .map((e) => {
        return JSON.stringify({
          contractor: e.conctractor,
          unit: e.equipmentClass,
          id: e.equipmentId,
          equipmentId: e.equipmentRomId,
          productivity: e.productivity,
          label: e.equipmentRomId,
          value: e.equipmentId,
        });
      })
      .filter((e, i, arr) => arr.indexOf(e) == i)
      .map((e) => JSON.parse(e));
    return data;
  }
);

export const addData = createAsyncThunk(
  'fleet-plan-rom/add',
  async (params) => {
    const details = await post(
      getAPIEndpoint(
        'fleet-plan-rom',
        'v1',
        `/add?userName=${params.userName}`
      ),
      params
    );
    const versionList = await get(
      getAPIEndpoint(
        'fleet-plan-rom',
        'v1',
        `/${details.data.reportId}/versions`
      )
    );
    return {
      details: details.data,
      versionList: versionList.data,
    };
  }
);

export const saveData = createAsyncThunk(
  'fleet-plan-rom/save',
  async (params) => {
    const details = await post(
      getAPIEndpoint(
        'fleet-plan-rom',
        'v1',
        `/save?userName=${params.userName}`
      ),
      params
    );
    const versionList = await get(
      getAPIEndpoint(
        'fleet-plan-rom',
        'v1',
        `/${details.data.reportId}/versions`
      )
    );
    return {
      details: details.data,
      versionList: versionList.data,
    };
  }
);

export const submitData = createAsyncThunk(
  'fleet-plan-rom/submit',
  async (params) => {
    const details = await post(
      getAPIEndpoint('fleet-plan-rom', 'v1', `/submit`),
      params
    );
    const versionList = await get(
      getAPIEndpoint(
        'fleet-plan-rom',
        'v1',
        `/${details.data.reportId}/versions`
      )
    );
    return {
      details: details.data,
      versionList: versionList.data,
    };
  }
);

export const approveData = createAsyncThunk(
  'fleet-plan-rom/approve',
  async (params) => {
    const details = await post(
      getAPIEndpoint('fleet-plan-rom', 'v1', `/approve`),
      params
    );
    return {
      details: details.data,
    };
  }
);
export const rejectData = createAsyncThunk(
  'fleet-plan-rom/reject',
  async (params) => {
    const details = await post(
      getAPIEndpoint('fleet-plan-rom', 'v1', `/reject`),
      params
    );
    return {
      details: details.data,
    };
  }
);

export const activateVersion = createAsyncThunk(
  'fleet-plan-rom/activate-version',
  async ({ reportId, version, username }) => {
    const details = await patch(
      getAPIEndpoint(
        'fleet-plan-rom',
        'v1',
        `/${reportId}/versions/${version}?userName=${username}`
      )
    );
    return {
      details: details.data,
    };
  }
);

export const deleteData = createAsyncThunk(
  'fleet-plan-rom/delete',
  async (id) => {
    const details = await deleteRequest(
      getAPIEndpoint('fleet-plan-rom', 'v1', `/${id}`),
      {}
    );
    return details.data;
  }
);

export const uploadFile = createAsyncThunk(
  'fleet-plan-rom/uploadFile',
  async ({ id, file }) => {
    let formData = new FormData();
    let newFile = new File([file], file.name, { type: file.type });
    formData.append('file', newFile);

    const response = await post(
      getAPIEndpoint('fleet-plan-rom', 'v1', `/${id}/files`),
      formData,
      {
        headers: { 'Content-Type': undefined },
      }
    );
    return response.data;
  }
);

export const deleteFile = createAsyncThunk(
  'fleet-plan-rom/deleteFile',
  async ({ id, file }) => {
    const response = await deleteRequest(
      getAPIEndpoint('fleet-plan-rom', 'v1', `/${id}/files/${file.name}`),
      {}
    );
    return response.data;
  }
);

export const handleCreateData = createAsyncThunk(
  'fleet-plan-rom/create',
  async ({ action, object }) => {
    const { data } = JSON.parse(localStorage.getItem('token'));
    const { bodyData } = slicingRequestBody(object);
    let response

    try {
      response = await post(
        getAPIEndpoint(
          'fleet-plan-rom',
          'v1',
          `${action}?userName=${data.userName}`
        ),
        bodyData
      );
    }
    catch (e) {
      return e
    }

    return response.data;
  }
);

export const handleEditData = createAsyncThunk(
  'fleet-plan-rom/update',
  async ({ action, object }) => {
    const { data } = JSON.parse(localStorage.getItem('token'));
    const { id, bodyData } = slicingRequestBody(object);

    const response = await put(
      getAPIEndpoint(
        'fleet-plan-rom',
        'v1',
        `${id}/${action}?userName=${data.userName}`
      ),
      bodyData
    );
    return response.data;
  }
);

export const setActivatedVersion = createAsyncThunk(
  'fleet-plan-rom/setActivatedVersion',
  async (param) => {
    const { reportId, version, userName } = param;
    const response = await patch(
      `${apiUrls.fleetPlanRom}/${reportId}/versions/${version}?userName=${userName}`
    );
    return response.data;
  }
);

export const getByVersion = createAsyncThunk(
  'fleet-plan-rom/getByVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await get(
      `${apiUrls.fleetPlanRom}/${reportId}/versions/${version}`
    );
    return response.data;
  }
);

const fleetPlanRomSlice = createSlice({
  name: FLEET_PLAN_ROM_REDUCER,
  initialState,
  reducers: {
    onTableChange: (state, action) => {
      state.tableFilters = { ...state.tableFilters, ...action.payload };
    },
    onFilterChange: (state, action) => {
      state.tableFilters.filter = {
        ...state.tableFilters.filter,
        ...action.payload,
      };
    },
    resetDetailData: (state, action) => {
      state.detailData = defaultValue;
    },
    resetTableFilter: (state, action) => {
      state.tableFilters.filter = defaultFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVersionList.fulfilled, (state, action) => {
      state.versionList = action.payload.map((e) => {
        return {
          value: e,
          label: 'Ver ' + e,
        };
      });
    });
    builder.addCase(fetchFilteredList.pending, (state, action) => {
      state.tableData.loading = true;
    });
    builder.addCase(fetchFilteredList.fulfilled, (state, action) => {
      state.tableData = action.payload;
      state.tableData.loading = false;
    });
    builder.addCase(fetchFilteredList.rejected, (state, action) => {
      state.tableData.loading = false;
    });
    builder.addCase(fetchDetail.fulfilled, (state, action) => {
      state.detailData = action.payload.details;
      state.detailVersionList = action.payload.versionList;
    });
    builder.addCase(addData.fulfilled, (state, action) => {
      state.detailData = action.payload.details;
      state.detailVersionList = action.payload.versionList;
    });
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.detailData = action.payload.details;
      state.detailVersionList = action.payload.versionList;
    });
    builder.addCase(submitData.fulfilled, (state, action) => {
      state.detailData = action.payload.details;
      state.detailVersionList = action.payload.versionList;
    });
    builder.addCase(fetchDetailTableMasterData.fulfilled, (state, action) => {
      state.detailMasterData = action.payload;
      // if (!state.detailData.detail.headerData.length) {
      //   state.detailData.detail.headerData = JSON.parse(JSON.stringify(action.payload.rom))
      // }
    });
  },
});

export const fleetPlanRomList = (state) => state.fleetPlanRom.tableData.list;
export const fleetPlanRomTableData = (state) => state.fleetPlanRom.tableData;
export const fleetPlanRomTableFilter = (state) =>
  state.fleetPlanRom.tableFilters;
export const fleetPlanRomVersionList = (state) =>
  state.fleetPlanRom.versionList;
export const fleetPlanRomShiftList = (state) => state.fleetPlanRom.shiftList;
export const fleetPlanRomDetail = (state) => state.fleetPlanRom.detailData;
export const fleetPlanRomDetailVersionList = (state) =>
  state.fleetPlanRom.detailVersionList;
export const fleetPlanRomDetailMasterData = (state) =>
  state.fleetPlanRom.detailMasterData;

export const {
  onFilterChange,
  onTableChange,
  resetDetailData,
  resetTableFilter,
} = fleetPlanRomSlice.actions;

export default fleetPlanRomSlice.reducer;
