import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';

const serviceName = 'payload-coal-getting';
export const initialState = {
  payloadCoalGettingList: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: '',
  },
  payloadCoalGettingDetail: {
    data: [],
    records: [],
    response: [],
    loading: false,
    error: null,
    statusLogList: [],
  },
  payloadCoalGettingTemplate: {
    data: [],
    records: [],
    loading: false,
    error: null,
  },
};

export const PAYLOAD_COAL_GETTING_REDUCER = 'PAYLOAD_COAL_GETTING_REDUCER';

export const fetchPayloadCoalGettingList = createAsyncThunk(
  'payload-coal-getting/list',
  async (params) => {
    const response = await post(`${apiUrls.payloadCoalGetting}/filter`, {
      columns: [
        {
          data: 'payloadId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params?.dataStatus === DATASOURCE_VALUE.waitingApproval || false,
            value: '',
          },
          searchValue: params.dataStatus || '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.date || '',
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.dataLength ? params.dataLength : -1,
      order: [
      ],
      search: {
        regex: false,
        value: params.query || '',
      },
      start: 0,
    });
    return response?.data;
  }
);

export const fetchPayloadCoalGettingDetail = createAsyncThunk(
  'payload-coal-getting/detail',
  async (param) => {
    const { id } = param;
    const response = await get(`${apiUrls.payloadCoalGetting}/${id}`);
    return response.data;
  }
);
export const addPayloadCoalGetting = createAsyncThunk(
  'payload-coal-getting/add',
  async (param) => {
    const { data } = param;
    const response = await post(`${apiUrls.payloadCoalGetting}/add`, data);
    return response.data;
  }
);
export const updatePayloadCoalGetting = createAsyncThunk(
  'payload-coal-getting/update',
  async (param) => {
    const { updateData } = param;
    const response = await post(
      `${apiUrls.payloadCoalGetting}/update`,
      updateData
    );
    return response.data;
  }
);
export const deletePayloadCoalGetting = createAsyncThunk(
  'payload-coal-getting/delete',
  async (param) => {
    const { id } = param;
    const response = await deleteRequest(
      `${apiUrls.payloadCoalGetting}/delete/${id}`
    );
    return response.data;
  }
);
export const setPayloadCoalGettingStatus = createAsyncThunk(
  'payload-coal-getting/setStatus',
  async (param) => {
    const { data } = param;
    const response = await patch(`${apiUrls.payloadCoalGetting}/set-status`, {
      ...data,
    });
    return response.data;
  }
);

export const payloadCoalGettingSubmit = createAsyncThunk(
  'payload-coal-getting/submit',
  async (param) => {
    const { id } = param;
    const response = await post(`${apiUrls.payloadCoalGetting}/submit`, {
      id,
    });
    return response.data;
  }
);

export const payloadCoalGettingDetailTemplate = createAsyncThunk(
  'payload-coal-getting/template',
  async (param) => {
    const response = await get(`${apiUrls.payloadCoalGetting}/detail-template`);
    return response.data;
  }
);

export const handleApproveReject = createAsyncThunk(
  'payload-coal-getting/approval',
  async (param) => {
    const { id, notes, userName, action } = param;
    const response = await put(
      `${apiUrls.payloadCoalGetting}/${id}/admin/${action}?userName=${userName}`,
      { notes: action === 'reject' ? notes : '' }
    );
    return response.data;
  }
);

export const getDetailService = createAsyncThunk(
  `${serviceName}/detail`,
  async (params) => {
    const { id } = params;
    const response = await get(`${apiUrls.payloadWasteRemoval}/${id}`);
    return response?.data;
  }
);

export const addService = createAsyncThunk(
  `${serviceName}/add`,
  async (param) => {
    const { data, username } = param;
    const response = await post(`${apiUrls.payloadWasteRemoval}/save?userName=${username}`, data);
    return response.data;
  }
);

export const saveService = createAsyncThunk(
  `${serviceName}/save`,
  async (param) => {
    const { data, username } = param;
    const response = await post(`${apiUrls.payloadWasteRemoval}/save?userName=${username}`, data);
    return response.data;
  }
);

export const submitService = createAsyncThunk(
  `${serviceName}/submit`,
  async (param) => {
    const { data, username } = param;
    const response = await post(`${apiUrls.payloadWasteRemoval}/submit?userName=${username}`, data);
    return response.data;
  }
);

export const uploadFileService = createAsyncThunk(
  `${serviceName}/uploadFile`,
  async (param) => {
    const { id, file } = param;
    const response = await post(`${apiUrls.payloadWasteRemoval}/${id}/files`, file, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);

export const deleteFileService = createAsyncThunk(
  `${serviceName}/deleteFile`,
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.payloadWasteRemoval}/${id}/files/${name}`
    );
    return response.data;
  }
);

export const updateDataService = createAsyncThunk(
  `${serviceName}/adminUpdate`,
  async (param) => {
    const { id, action, username, data } = param;
    const response = await put(
      `${apiUrls.payloadWasteRemoval}/${id}/admin/${action}?userName=${username}`, data
    );
    return response.data;
  }
);

const payloadCoalGettinglSlice = createSlice({
  name: PAYLOAD_COAL_GETTING_REDUCER,
  initialState,
  reducers: {
    clearPayloadCoalDetail: (state, action) => {
      state.payloadCoalGettingDetail = {
        data: {},
        loading: false,
        error: null,
      };
    },

    onChangeCoal: (state, action) => {
      const payload = action.payload;

      let index = payload.tableRowName.split('value');
      index = parseInt(index[1]) - 1;

      if (!state.payloadCoalGettingDetail.data[payload.tableIndexRow]) {
        state.payloadCoalGettingDetail.data =
          state.payloadCoalGettingTemplate.records;
      }

      state.payloadCoalGettingDetail.data[payload.tableIndexRow]['equipment'][
        index
      ]['value'] = payload.value;
      state.payloadCoalGettingTemplate.records[payload.tableIndexRow][
        'equipment'
      ][index]['value'] = payload.value;
    },

    onEditCoal: (state, action) => {
      const payload = action.payload;

      let index = payload.tableRowName.split('value');
      index = parseInt(index[1]) - 1;

      state.payloadCoalGettingDetail.records[payload.tableIndexRow][
        'equipment'
      ][index]['value'] = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayloadCoalGettingList.pending, (state, action) => {
      state.payloadCoalGettingList.loading = true;
    });
    builder.addCase(fetchPayloadCoalGettingList.fulfilled, (state, action) => {
      state.payloadCoalGettingList.records = action.payload.list;
      state.payloadCoalGettingList.recordsTotal = action.payload.recordsTotal;
      state.payloadCoalGettingList.loading = false;
    });
    builder.addCase(fetchPayloadCoalGettingList.rejected, (state, action) => {
      state.payloadCoalGettingList.loading = false;
      state.payloadCoalGettingList.error = action.error;
    });
    builder.addCase(fetchPayloadCoalGettingDetail.pending, (state, action) => {
      state.payloadCoalGettingDetail.loading = true;
    });
    builder.addCase(
      fetchPayloadCoalGettingDetail.fulfilled,
      (state, action) => {
        state.payloadCoalGettingDetail.records = action.payload.detail;
        state.payloadCoalGettingDetail.data = action.payload.detail;
        state.payloadCoalGettingDetail.response = action.payload;
        state.payloadCoalGettingDetail.statusLogList =
          action.payload.statusLogList;
        state.payloadCoalGettingDetail.loading = false;
      }
    );
    builder.addCase(fetchPayloadCoalGettingDetail.rejected, (state, action) => {
      state.payloadCoalGettingDetail.loading = false;
      state.payloadCoalGettingDetail.error = action.error;
    });
    builder.addCase(
      payloadCoalGettingDetailTemplate.pending,
      (state, action) => {
        state.payloadCoalGettingTemplate.loading = true;
      }
    );
    builder.addCase(
      payloadCoalGettingDetailTemplate.fulfilled,
      (state, action) => {
        state.payloadCoalGettingTemplate.data = action.payload;
        state.payloadCoalGettingTemplate.records = action.payload;
        state.payloadCoalGettingTemplate.loading = false;
      }
    );
    builder.addCase(
      payloadCoalGettingDetailTemplate.rejected,
      (state, action) => {
        state.payloadCoalGettingTemplate.loading = false;
        state.payloadCoalGettingTemplate.error = action.error;
      }
    );
  },
});
export const payloadCoalGettinglSelector = (state) =>
  state.payloadCoalGetting.payloadCoalGettingList;
export const payloadCoalGettingDetaillSelector = (state) =>
  state.payloadCoalGetting.payloadCoalGettingDetail;
export const payloadCoalGettingTemplateSelector = (state) =>
  state.payloadCoalGetting.payloadCoalGettingTemplate;

export const { clearPayloadCoalDetail, onChangeCoal, onEditCoal } =
  payloadCoalGettinglSlice.actions;

export default payloadCoalGettinglSlice.reducer;
